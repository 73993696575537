import styled from 'styled-components';
import { Color } from '../../../common/styles/themes';

export const TerminalsPage = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

  font-family: 'Montserrat';
`;

export const TerminalHeader = styled.div`
  width: 100%;
  margin-top: 2.4rem;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > h1 {
    font-size: 24px;
  }

  & > button {
    border: 0;
    width: 260px;
    height: 38px;
    color: white;
    font-size: 15px;
    background-color: ${Color.secondaryColor};
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-weight: bold;
    font-family: 'Montserrat';
    &:hover {
      transition: 0.5s;
      background-color: ${Color.secondaryColorhover};
    }
  }
`;

export const TerminalList = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.border};
  margin-top: 24px;
  border-radius: 8px;
`;

export const TerminalListRowHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.title};
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

export const TerminalListRow = styled.div`
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.title};
  &:nth-child(2n) {
    background-color: ${(props) => props.theme.treeHoverBackground};
    color: ${(props) => props.theme.title};
  }
  background-color: ${(props) => props.theme.secondaryBackground};
`;

export const TerminalListRowCell = styled.div`
  padding: 0.8rem;
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.border};
  display: flex;
  align-items: center;
  gap: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  &:last-of-type {
    border: 0;
  }
  &:nth-child(n) {
    border-color: ${(props) => props.theme.inputBorderColor};
  }
  h3 {
    font-size: 15px;
  }
`;

export const TerminalListLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

export const TerminalListErrorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  color: red;
`;

export const TerminalListNoDataContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  color: ${(props) => props.theme.subTitle};
`;
