import { ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { useMoneyFormatter } from '../../../../common/utils/formatters/use-money-formatter';

@ValidatorConstraint({ name: 'customDefaultPrice', async: false })
export class CustomDefaultPrice implements ValidatorConstraintInterface {
  validate(text: string) {
    return useMoneyFormatter(text) > 0;
  }

  defaultMessage() {
    return 'Preço inválido!';
  }
}
