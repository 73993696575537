import React from 'react';
import { Circle, Slider, SwitchButtonProps, SwitchButtonWrapper } from './switch-theme-styled';

const SwitchButton: React.FC<SwitchButtonProps> = ({ isOn, onToggle }) => {
  return (
    <SwitchButtonWrapper onClick={onToggle}>
      <Slider isOn={isOn}>
        <Circle isOn={isOn} />
      </Slider>
    </SwitchButtonWrapper>
  );
};

export default SwitchButton;
