import styled from 'styled-components';
import { Color } from '../../styles/themes';
interface ContainerProps {
  marginTop: string;
  InputSize: 'big' | 'medium';
  isloading?: boolean;
}

export const Container = styled.input<ContainerProps>`
  width: 100%;
  height: ${(props) => (props.InputSize == 'big' ? '5.6vh' : '30px')};
  border-radius: ${(props) => (props.InputSize == 'big' ? '11px' : '8px')};
  color: ${(props) => props.theme.title};
  font-size: 13px;
  margin-top: ${(props) => props.marginTop};
  font-family: 'Montserrat-Regular';
  padding: 10px ${(props) => (props.isloading ? '30px' : '10px')} 10px 10px;
  background-color: transparent;
  box-sizing: border-box;
  outline: 0;
  min-height: ${(props) => (props.InputSize == 'big' ? '45px' : '30px')};

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &[data-autocompleted] {
    background-color: transparent !important;
  }
`;

export const ErrorMessageDiv = styled.span`
  font-size: 12px;
  font-family: 'Montserrat';
  margin-left: 3px;
  align-self: flex-start;
  color: ${Color.red};

  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

interface InputWrapperProps {
  ErrorMessage?: string;
  InputSize: 'big' | 'medium';
  floatingLabel?: boolean;
  labelBackgroundColor?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > input {
    border: 1px solid ${(props) => (props.ErrorMessage ? Color.red : props.theme.inputBorderColor)};
  }

  & > input::placeholder {
    ${(props) => (props.floatingLabel ? { color: 'transparent' } : null)}
  }

  & > span {
    pointer-events: none;
    position: absolute;
    left: 0;
    padding-left: 8px;
    padding-top: 0px;
    padding-right: 5px;
    padding-bottom: 0px;
    font-family: 'Montserrat-Regular';
    font-size: 11px;

    color: ${(props) => props.theme.subTitle};
    letter-spacing: 1px;
  }

  & > input:focus ~ span {
    color: ${(props) => (props.ErrorMessage ? Color.red : props.theme.inputLabelColor)};

    font-size: 11px;
    transition: 0.3s;
    background-color: ${(props) => props.theme.background};
    transform: translateX(10px) translateY(${(props) => (props.InputSize == 'big' ? '-25px' : '-18px')});
  }

  & > input:not(:placeholder-shown) ~ span {
    color: ${(props) => (props.ErrorMessage ? Color.red : props.theme.inputLabelColor)};

    font-size: 11px;
    background-color: ${(props) => props.theme.background};
    transform: translateX(10px) translateY(${(props) => (props.InputSize == 'big' ? '-25px' : '-18px')});
  }
`;
