import { Outlet, useLocation, useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDispatch } from 'react-redux';
import { Login } from './redux/AuthSlice';
import { GetUserBasicData } from './get-user-basic-data';
import { getUserUnauthorized } from './api/global-unauthorized-user';
import { ErrorToast } from './common/alerts/custom-alert-error';
import { Color } from './common/styles/themes';

const ProtectedRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [IsLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [IsLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    async function Validate() {
      try {
        const resp = await GetUserBasicData();
        dispatch(Login({ nome: resp.data.name, email: resp.data.email, cpfCnpj: resp.data.cpfCnpj, logo: resp.data.logo }));
        setIsLoggedIn(true);
        setIsLoaded(true);
      } catch (e) {
        setIsLoggedIn(false);
        setIsLoaded(true);
        if (!getUserUnauthorized()) {
          ErrorToast.fire({
            text: 'Erro ao buscar os dados da sua empresa!'
          });
        }
        navigate('/');
      }
    }

    Validate();
  }, [location]);

  if (IsLoaded) {
    return IsLoggedIn ? <Outlet></Outlet> : null;
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <ClipLoader color={Color.primaryColorLigth} loading={!IsLoaded} size={70} />
      </div>
    );
  }
};

export default ProtectedRoutes;
