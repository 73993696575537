import api from '../../../api/api';
import { AxiosError } from 'axios';

export const GetTermsContent = async (identier: string): Promise<{ data: { content: string; title: string } }> => {
  return api
    .get(`/terms-of-use/${identier}`)
    .then((response) => response.data)
    .catch((err: AxiosError) => {
      throw err.response?.data.cause;
    });
};
