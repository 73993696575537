import styled from 'styled-components';

//fw = full width = 100%
//fh full height = 100%

export const FlexCenteredDivFhFwRelative = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const FlexCenteredDivFhFw = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${(props) => props.theme.subTitle};
  background-color: ${(props) => props.theme.backgroundColor};
`;
