import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import {
  FooterCol,
  FooterRow,
  FooterStyled,
  FooterWrapper,
  SocialIcons,
  SocialMidiaList,
  SocialMidiaWrapper
} from '../styled-components_adquirentes';
import LogoBranco from '../../../../images/logo-branco.png';

export const FooterAdquirentes = () => {
  return (
    <FooterStyled>
      <FooterWrapper>
        <FooterCol>
          <a href='https://serverinfo.com.br/' target='_blank'>
            <img
              src={LogoBranco}
              alt='Server Softwares para Varejo'
              title='Server Softwares para Varejo'
              style={{ width: '150px', marginBottom: '10px' }}
            />
          </a>
          <FooterRow>
            <h3>Telefone</h3>
            <SocialIcons href='tel:5135942957'>(51) 3594.2957</SocialIcons>
            <small>Horário de atendimento: segunda a sexta, das 8h às 12h e das 13h às 18h.</small>
          </FooterRow>
        </FooterCol>
        <FooterCol>
          <FooterRow>
            <h3>Endereço</h3>
            <p>CEP 93336010 - Novo Hamburgo/RS</p>
            <SocialIcons href='https://goo.gl/maps/nwMBAznm78K2' target='_blank' className='texto-sublinhado'>
              Ver mapa
            </SocialIcons>
          </FooterRow>
        </FooterCol>
        <FooterCol>
          <SocialMidiaWrapper>
            <strong>Siga-nos nas redes sociais</strong>
            <SocialMidiaList>
              <SocialIcons href='https://www.instagram.com/bshoplite' target='_blank'>
                <FaInstagram size={30} />
              </SocialIcons>
              <SocialIcons href='https://www.youtube.com/@BshopLite' target='_blank'>
                <FaYoutube size={30} />
              </SocialIcons>
              <SocialIcons href='https://www.facebook.com/profile.php?id=61556979938136' target='_blank'>
                <FaFacebook size={30} />
              </SocialIcons>
              <SocialIcons href='https://www.tiktok.com/@bshop.lite' target='_blank'>
                <FaTiktok size={30} />
              </SocialIcons>
            </SocialMidiaList>
          </SocialMidiaWrapper>
        </FooterCol>
      </FooterWrapper>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <p>© 2024 Server Softwares para Varejo. Todos os direitos reservados.</p>
      </div>
    </FooterStyled>
  );
};
