import { useState } from 'react';
import { createPortal } from 'react-dom';
import { AiOutlineBarChart, AiOutlineForm } from 'react-icons/ai';
import { BiDevices, BiExport } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router';
import ExportXml from '../export-xml/export-xml';
import Popup from '../popup/popup';
import { PagesContainer, SidebarMenuCategoryLabel, SidebarMenuOption, SidebarMenuOptionLabel, SidebarTile } from './pages-icons-styled';
import { RiTShirt2Line } from 'react-icons/ri';

interface pagesProps {
  checkCurrentPage: boolean;
}

function Pages(props: pagesProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showExportXmlPopup, setShowExportXmlPopup] = useState(false);

  return (
    <PagesContainer id='pagesContainer' title='pages'>
      <SidebarMenuCategoryLabel>PRINCIPAL</SidebarMenuCategoryLabel>

      <SidebarTile
        isOptionActive={props.checkCurrentPage && location.pathname === '/dashboard'}
        className='page'
        title='Dashboard'
        onClick={() => navigate('/dashboard')}
      >
        <SidebarMenuOption className='pageIcon'>
          <AiOutlineBarChart className='menu-icon' size={22}></AiOutlineBarChart>
        </SidebarMenuOption>
        <SidebarMenuOptionLabel id='pageName'>Dashboard</SidebarMenuOptionLabel>
      </SidebarTile>

      <SidebarTile
        isOptionActive={props.checkCurrentPage && location.pathname === '/cadastro'}
        className='page'
        title='Cadastro'
        onClick={() => navigate('/cadastro')}
      >
        <SidebarMenuOption className='pageIcon'>
          <AiOutlineForm className='menu-icon' size={30}></AiOutlineForm>
        </SidebarMenuOption>
        <SidebarMenuOptionLabel id='pageName'>Dados da Conta</SidebarMenuOptionLabel>
      </SidebarTile>

      <SidebarMenuCategoryLabel>DOCUMENTOS</SidebarMenuCategoryLabel>

      <SidebarTile isOptionActive={false} className='page' title='Exportar XMLs' onClick={() => setShowExportXmlPopup(true)}>
        <SidebarMenuOption className='pageIcon'>
          <BiExport className='menu-icon' size={30}></BiExport>
        </SidebarMenuOption>
        <SidebarMenuOptionLabel id='pageName'>Exportar XMLs</SidebarMenuOptionLabel>
      </SidebarTile>

      {showExportXmlPopup &&
        createPortal(
          <Popup width='500px' height='500px' setTrigger={setShowExportXmlPopup} trigger={showExportXmlPopup}>
            <ExportXml />
          </Popup>,
          document.body
        )}

      <SidebarMenuCategoryLabel>CONFIGURAÇÕES</SidebarMenuCategoryLabel>

      <SidebarTile
        isOptionActive={props.checkCurrentPage && location.pathname === '/products'}
        className='page'
        title='Produtos'
        onClick={() => navigate('/products')}
      >
        <SidebarMenuOption className='pageIcon'>
          <RiTShirt2Line className='menu-icon' size={22}></RiTShirt2Line>
        </SidebarMenuOption>
        <SidebarMenuOptionLabel id='pageName'>Produtos</SidebarMenuOptionLabel>
      </SidebarTile>

      <SidebarTile
        isOptionActive={props.checkCurrentPage && location.pathname === '/terminals'}
        className='page'
        title='Ativação de Terminal'
        onClick={() => navigate('/terminals')}
      >
        <SidebarMenuOption className='pageIcon'>
          <BiDevices className='menu-icon' size={22}></BiDevices>
        </SidebarMenuOption>
        <SidebarMenuOptionLabel id='pageName'>Terminais</SidebarMenuOptionLabel>
      </SidebarTile>
    </PagesContainer>
  );
}

export default Pages;
