import React, { ButtonHTMLAttributes } from 'react';
import { ButtonComponent } from './button-style';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  width: string;
  color: string;
  fontSize: string;
  marginLeft: string;
  backgroundColor?: string;
  backgroundHoverColor?: string;
}

const Button: React.FC<Props> = ({
  width,
  color,
  fontSize,
  marginLeft,
  children,
  backgroundColor = '#004b90',
  backgroundHoverColor = '#012b5e',
  ...rest
}) => (
  <ButtonComponent
    backgroundColor={backgroundColor}
    backgroundHoverColor={backgroundHoverColor}
    width={width}
    color={color}
    fontSize={fontSize}
    marginLeft={marginLeft}
    {...rest}
  >
    {children}
  </ButtonComponent>
);

export default Button;
