import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

export const ComponentContainer = styled.div<{ isStepsComplete: boolean }>`
  position: absolute;
  width: 400px;
  bottom: 0;
  display: ${({ isStepsComplete }) => (isStepsComplete ? 'none' : 'flex')};
  right: 0;
  @media (max-width: 600px) {
    height: 300px;
    width: 100%;
    bottom: -20px;
  }
`;

export const Component = styled(motion.div)<{ isOpen: boolean }>`
  position: relative;
  height: 700px;
  width: 100%;
  box-shadow: ${(props) => props.theme.stepsPopUpBoxShadow};
  background-color: ${(props) => props.theme.stepsPopUpBackground};
  backdrop-filter: blur(6px);
  padding: 15px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: ${(props) => props.theme.stepsPopUpSubtitle};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  & > p {
    font-size: 18px;
    text-indent: 10px;
    font-weight: 900;
    word-break: keep-all;
    color: ${(props) => props.theme.stepsPopUpTitle};
    text-align: center;
  }
  @media (max-width: 600px) {
    padding-left: 10px;
    & > p {
      font-size: 16px;
      text-align: left;
    }
  }
`;

export const ComponentMinimized = styled(motion.div)<{ isOpen: boolean }>`
  position: relative;
  height: 140px;
  box-shadow: ${(props) => props.theme.stepsPopUpBoxShadow};
  backdrop-filter: blur(6px);
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: ${(props) => props.theme.stepsPopUpBackground};
  padding: 10px;
  color: ${(props) => props.theme.stepsPopUpTitle};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: ${({ isOpen }) => (isOpen ? 'none' : 'flex')};
  align-self: flex-end;
  letter-spacing: 0.05em;
  @media (max-width: 600px) {
    height: 45%;
  }
`;

export const StepContainer = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 80%;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: ${(props) => props.theme.stepsPopUpIcon};
    height: 90%;
    width: 4px;
    left: 20px;
    top: 0;
    transform: translateX(-50%);
    @media (max-width: 400px) {
      left: 20px;
      height: 385px;
    }
    @media (max-width: 600px) and (min-width: 401px) {
      left: 20px;
      height: 410px;
    }
  }
  :after {
    content: '';
    position: absolute;
    background-color: ${(props) => props.theme.stepsPopUpAfter};
    width: 4px;
    height: ${({ width }) => width * 0.9}%;
    left: 20px;
    transition: 0.4s ease;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    @media (max-width: 600px) and (min-width: 401px) {
      left: 20px;
      height: ${({ width }) => width * 1.7}%;
    }
    @media (max-width: 400px) {
      left: 20px;
      height: ${({ width }) => width * 2}%;
    }
  }
  @media (max-width: 600px) {
    overflow-y: auto;
    gap: 30px;
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: fit-content;
  gap: 12px;
  @media (max-width: 600px) {
    display: flex;
    gap: 10px;
  }
`;

export const StepStyle = styled.div<{ step: string }>`
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.stepsPopUpSubtitle};
  border: 3px solid ${({ step }) => (step === 'completed' ? '#008AFA' : '#004b90')};
  transition: 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: '10';
  @media (max-width: 600px) {
    max-height: 30px;
    max-width: 30px;
  }
`;

export const StepCount = styled.span`
  font-size: 19px;
  font-weight: 600;
  color: ${(props) => props.theme.stepsPopUpTitle};
  @media (max-width: 600px) {
    font-size: 16px;
    margin-top: 2px;
    margin-right: 1px;
  }
`;

export const StepsLabelContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  word-break: keep-all;
  display: flex;
  height: 100%;
  width: 100%;
  margin-top: 5px;
  flex-direction: column;
  @media (max-width: 600px) {
    display: flex;
    position: static;
    width: 100%;
    text-indent: 0px;
  }
  & > strong {
    font-size: 19px;
    color: ${(props) => props.theme.stepsPopUpTitle};
    font-weight: 600;
    width: 100%;
    margin-right: 10px;
  }
  & > p {
    width: 100%;
    text-indent: 10px;
  }
`;
export const StepLabel = styled.span`
  font-size: 19px;
  color: ${(props) => props.theme.stepsPopUpTitle};
  font-weight: 600;
  width: 100%;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
export const IconStyle = styled(motion.div)`
  transition: 1s ease;
  display: flex;
  color: ${(props) => props.theme.stepsPopUpTitle};
  @media (max-width: 600px) {
    font-size: 16px;
    margin-top: 2px;
    margin-right: 1px;
  }
`;
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(-10%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
`;
export const BouncingDiv = styled.div`
  animation: ${bounceAnimation} 1s infinite;
  margin-right: 32px;
  cursor: pointer;
  margin-top: -25px;
`;
