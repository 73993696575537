import { IsEmail, IsNotEmpty, Length } from 'class-validator';
import { CompanyDataState } from '../../auxiliares/company-data';

export class ValidatorSignup implements CompanyDataState {
  constructor(params: CompanyDataState) {
    this.email = params.email;
    this.name = params.name;
    this.phoneNumber = params.phoneNumber;
    this.cpfCnpj = params.cpfCnpj;
    this.password1 = params.password1;
    this.password2 = params.password2;
  }

  @IsEmail(
    {},
    {
      message: 'Por favor, insira um E-mail válido!'
    }
  )
  @IsNotEmpty({ message: 'O campo "E-mail" deve ser preenchido!' })
  email!: string;

  @IsNotEmpty({ message: 'O campo "Telefone" deve ser preenchido!' })
  @Length(10, 12, { message: 'O campo "Telefone" não foi totalmente digitado!' })
  phoneNumber!: string;

  @IsNotEmpty({ message: 'O campo "Nome/Razão Social" deve ser preenchido!' })
  name!: string;

  @IsNotEmpty({ message: 'O campo "CPF/CNPJ" deve ser preenchido!' })
  @Length(11, 14, {
    message: 'O campo "CPF/CNPJ" deve possuir entre 11 e 14 caracteres!'
  })
  cpfCnpj!: string;

  @IsNotEmpty({ message: 'O campo "Senha" deve ser preenchido!' })
  password1!: string;

  @IsNotEmpty({ message: 'O campo "Senha" deve ser preenchido!' })
  password2!: string;
}
