import { useEffect, useState } from 'react';
import ChartContainer from './chart-container';
import api from '../../../../../api/api';
import { screenBreakPoint } from '../../../../../common/screen-break-points';
import { ResponsiveLine } from '@nivo/line';
import { LinheChartCustomTooltip } from '../line-chart-custom-tooltip';
import { linearGradientDef } from '@nivo/core';
import { SalesHourCoordinatesProps } from '../../../common/sales-hour-array-object';
import { GetResumedValue } from '../../../../../common/utils/get-resumed-value';

interface SalesByHourProps {
  period: string;
  windowWidth: number;
  windowWHeight: number;
}

function SalesByHour(props: SalesByHourProps) {
  const [chartData, setChartData] = useState<SalesHourCoordinatesProps>({ id: 'Vendas por Hora', data: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [showLabel, setShowLabel] = useState<boolean>(false);

  function GetData() {
    setIsLoading(true);
    api
      .get('/dashboard/sales/hour', {
        params: {
          period: props.period
        }
      })
      .then((resp) => {
        if (resp.data.data.length == 0) {
          setIsEmpty(true);
          setIsError(false);
          return;
        }

        const currChartData: SalesHourCoordinatesProps = { id: chartData.id, data: [] };

        resp.data.data.map((horario: { hour: number; amount: number }) => {
          currChartData.data.push({
            x: horario.hour.toString() + 'h',
            y: horario.amount
          });
        });

        setChartData(currChartData);
        setIsError(false);
        setIsEmpty(false);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    GetData();
  }, [props.period]);

  return (
    <ChartContainer
      title='Vendas por hora'
      gridColumnStart={1}
      gridColumnEnd={8}
      gridRowStart={1}
      gridRowEnd={3}
      isScrollable={chartData.data.length > 9}
      isError={isError}
      isLoading={isLoading}
      isEmpty={isEmpty}
      retry={() => GetData()}
      showShowLabelOption={true}
      showLabel={showLabel}
      setShowLabel={setShowLabel}
    >
      <ResponsiveLine
        defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 }
          ])
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
        tooltip={
          props.windowWidth < screenBreakPoint.mobileWidth || props.windowWHeight < screenBreakPoint.mobileHeight
            ? (data) => {
                return (
                  <div
                    title='custom tooltip'
                    style={{
                      fontSize: '11.5px',
                      position: 'absolute',
                      padding: '3px',
                      borderRadius: '5px',
                      backgroundColor: '#ebebeb',
                      whiteSpace: 'nowrap',
                      bottom: '10px',
                      left: '-30px',
                      fontWeight: '600'
                    }}
                  >
                    <span>{data.point.data.yFormatted}</span>
                  </div>
                );
              }
            : (data) => {
                return <LinheChartCustomTooltip x={data.point.data.xFormatted} y={data.point.data.yFormatted}></LinheChartCustomTooltip>;
              }
        }
        data={[chartData]}
        enableGridX={true}
        enableGridY={true}
        margin={
          props.windowWidth > screenBreakPoint.mobileWidth
            ? { top: 22, right: 10, bottom: 62, left: 70 }
            : { top: 32, right: 10, bottom: 62, left: 10 }
        }
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        yFormat={(value: number | string | Date) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0'}
        curve='linear'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={
          props.windowWidth > screenBreakPoint.mobileWidth
            ? {
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: 'middle'
              }
            : null
        }
        colors={['#3487d5']}
        pointSize={6}
        enablePoints={true}
        pointColor={'#fff'}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.2}
        enablePointLabel={showLabel}
        pointLabel={(value) => GetResumedValue(value.y as number)}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 55,
            itemsSpacing: 30,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </ChartContainer>
  );
}

export default SalesByHour;
