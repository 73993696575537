import styled from 'styled-components';

export const NotFoundPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.background};

  @media (max-width: 1000px) {
    display: block;
  }
`;

export const ColPage = styled.div`
  width: 40%;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 10px;
  }
`;

export const ErrorCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const ErrorText = styled.span`
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
  padding: 20px;
  color: ${(props) => props.theme.title};
`;
