import { ChangeEvent } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { ConfirmToast } from '../../../../../common/alerts/custom-alert-confirm';
import { AttForm, FormType } from '../../../../../redux/FormSlice';
import {
  ContainerIcons,
  ContainerImg,
  ContainerReactIcon,
  ContainterTrashIcon,
  IconsErrorMessageWrapper,
  IconSubTitle,
  IconTitle,
  StyledImg,
  StyledImgSelected,
  TrashIcon
} from '../../styled-components_cadastro';
import imageLogoSVGIcon from './../../../../../images/company-logo.svg';
import { Theme } from '../../../../../common/theme/theme-context';

interface Props {
  Attempts: number;
  setAttempts: (value: number) => void;
  TrySVG: boolean;
  setTrySVG: (value: boolean) => void;
  handleFiles: (e: ChangeEvent<HTMLInputElement>, key: string, setSubtitulo: (value: string) => void) => void;
  theme: Theme;
  setSubtituloIconLogo: (value: string) => void;
  subtituloIconLogo: string;
  logo: string | null;
  ErrorMessageInputs: FormType;
}

function LogoIconInput(props: Props) {
  const dispatch = useDispatch();
  return (
    <ContainerIcons>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <IconTitle>Logo da empresa</IconTitle>
        <ContainerReactIcon htmlFor='inputLogo'>
          {props.logo && props.Attempts < 2 ? (
            props.TrySVG ? (
              <StyledImg
                src={'data:image/svg+xml;base64,' + props.logo}
                id='logo'
                alt='company logo svg'
                onError={() => {
                  props.setAttempts(props.Attempts + 1);
                  props.setTrySVG(false);
                }}
              ></StyledImg>
            ) : (
              <StyledImgSelected
                src={'data:image/*;base64,' + props.logo}
                id='logo'
                alt='company logo'
                onError={() => {
                  props.setAttempts(props.Attempts + 1);
                  props.setTrySVG(true);
                }}
              ></StyledImgSelected>
            )
          ) : (
            <ContainerImg>
              <StyledImg src={imageLogoSVGIcon}></StyledImg>
            </ContainerImg>
          )}
          <input
            type='file'
            id='inputLogo'
            placeholder='inputLogo'
            style={{ display: 'none' }}
            onChange={(e) => {
              props.setAttempts(0);
              props.handleFiles(e, 'logo', props.setSubtituloIconLogo);
            }}
            accept='image/*'
          ></input>
          <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <IconSubTitle>{props.subtituloIconLogo}</IconSubTitle>
            {props.ErrorMessageInputs.logo ? <IconsErrorMessageWrapper>{props.ErrorMessageInputs.logo}</IconsErrorMessageWrapper> : null}
          </div>
        </ContainerReactIcon>
      </div>
      <ContainterTrashIcon
        title='Deletar logo'
        style={{ alignSelf: 'end' }}
        onClick={() => {
          if (!props.logo) return;

          ConfirmToast.fire({
            text: 'Você realmente deseja excluir o seu logo?',
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar',
            color: props.theme === 'dark' ? '#EDF5FD' : '#343239',
            background: props.theme === 'dark' ? '#0A1623' : '#fff'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(AttForm({ key: 'logo', value: null }));
              props.setSubtituloIconLogo('Selecionar Logo...');
            }
          });
        }}
      >
        <TrashIcon>
          <FaTrashAlt size={15}></FaTrashAlt>
        </TrashIcon>
      </ContainterTrashIcon>
    </ContainerIcons>
  );
}

export default LogoIconInput;
