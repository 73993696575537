import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BsImages } from 'react-icons/bs';
import { deepCopy } from '../../../../../../common/utils/deep-copy';
import { ImageType } from '../../../../dto';
import { NoImgProduct } from '../../../product-details/products-details-styled';
import ProductImage from './components/product-image';
import UploadImage from './components/upload-image';
import { DefaultImageCard, ImageSelectorContainer, SecondaryImageContainer } from './image-selector-styled';

type ImageSelectorProps = {
  images: Array<ImageType>;
  setImages?: Dispatch<SetStateAction<Array<ImageType>>>;
  isEditingOrRegistering: boolean;
  productId?: number;
  children?: JSX.Element | boolean;
};

function ImageSelector({ images, setImages, isEditingOrRegistering, productId, children }: ImageSelectorProps) {
  const [defaultImageIndex, setDefaultImageIndex] = useState<number>(images.findIndex((image) => image.default === true));

  useEffect(() => {
    if (defaultImageIndex === -1) {
      setDefaultImageIndex(images.findIndex((image) => image.default === true));
    }
  }, [images]);

  useEffect(() => {
    if (defaultImageIndex === -1 && images.length > 0) {
      const currImages = deepCopy(images);
      currImages[0].default = true;
      setImages?.(currImages);

      setDefaultImageIndex(0);
    }
  }, []);

  const setDefaultImage = (index: number) => {
    const currImages = deepCopy(images);
    currImages.forEach((image) => {
      image.default = false;
    });
    currImages[index].default = true;

    setImages?.(currImages);
    setDefaultImageIndex(index);
  };

  const deleteImage = (index: number) => {
    if (!isEditingOrRegistering) return;

    const currImages = deepCopy(images);
    const imageToDelete = currImages[index];
    currImages.splice(index, 1);

    if (imageToDelete.default && currImages.length > 0) {
      currImages[0].default = true;
      setDefaultImageIndex(0);
    }

    if (currImages.length === 0) {
      setDefaultImageIndex(-1);
    }

    setImages?.(currImages);
  };

  return (
    <ImageSelectorContainer>
      <DefaultImageCard>
        {defaultImageIndex > -1 ? (
          <ProductImage
            isEditingOrRegistering={isEditingOrRegistering}
            image={images[defaultImageIndex]}
            isDefaultImage={true}
            onDeleteImage={() => deleteImage(defaultImageIndex)}
            productId={productId}
          />
        ) : isEditingOrRegistering ? (
          <UploadImage setDefaultImageIndex={setDefaultImageIndex} isDefaultImage={true} setImages={setImages} images={images} />
        ) : (
          <NoImgProduct>
            <BsImages size={80} />
          </NoImgProduct>
        )}
      </DefaultImageCard>
      {children}
      <SecondaryImageContainer>
        {images.length > 0 && (
          <>
            {images.map((image, index) => {
              if (index === defaultImageIndex) return null;

              return (
                <ProductImage
                  key={image.id ?? index}
                  isEditingOrRegistering={isEditingOrRegistering}
                  image={image}
                  isDefaultImage={false}
                  onDeleteImage={() => deleteImage(index)}
                  onClick={() => setDefaultImage(index)}
                />
              );
            })}
            {isEditingOrRegistering && images.length < 4 && (
              <UploadImage setDefaultImageIndex={setDefaultImageIndex} isDefaultImage={false} setImages={setImages} images={images} />
            )}
          </>
        )}
      </SecondaryImageContainer>
    </ImageSelectorContainer>
  );
}

export default ImageSelector;
