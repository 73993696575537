import { AxiosResponse } from 'axios';
import api from './../../../../api/api';

type DownloadXmlsProps = {
  year: string;
  month: string;
};

export const DownloadXmls = async ({ month, year }: DownloadXmlsProps): Promise<AxiosResponse<Blob>> => {
  const resp = await api.get<Blob>(`/documents/xmls/download?year=${year}&month=${month}`, {
    responseType: 'blob'
  });
  return resp;
};
