export const hasLetterRegExp = /[a-zA-Z]/;
export const hasNumberRegExp = /\d/;
export const hasSpaceRegEXp = /\s/;

export const ValidatePassword = (password: string): boolean => {
  const isInvalid =
    !hasLetterRegExp.test(password) ||
    !hasNumberRegExp.test(password) ||
    hasSpaceRegEXp.test(password) ||
    password === '' ||
    password.length < 8;

  return !isInvalid;
};
