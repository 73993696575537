import { DateUtils } from '@bshop6/bshop-utility';
import axios, { AxiosResponse } from 'axios';
import { ErrorToast } from '../common/alerts/custom-alert-error';
import { GenerateBshopSecurity } from './generate-bshop-security';
import { setUserUnauthorized } from './global-unauthorized-user';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

let userRedirected = false;

api.interceptors.request.use(
  (req) => {
    req.headers ??= {};

    req.headers['Content-Type'] = 'application/json';
    req.headers['bshop-security'] = GenerateBshopSecurity();
    req.headers['timezone'] = (new Date().getTimezoneOffset() / 60) * -1;
    req.headers['request-date'] = DateUtils.now().toISOString();

    if (!req.headers['flavor']) {
      req.headers['flavor'] = 'browser';
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let refreshPromise: Promise<AxiosResponse> | null = null;

const refreshToken = async () => {
  if (!refreshPromise) {
    refreshPromise = api.get('/auth/refresh');
    try {
      await refreshPromise;
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao atualizar o token.'
      });
    }
    refreshPromise = null;
  }
  return refreshPromise;
};

api.interceptors.response.use(
  (resp) => {
    const url = resp.config.url;
    if (url?.includes('auth/login')) {
      userRedirected = false;
      setUserUnauthorized(false);
    }
    return resp;
  },
  async (err) => {
    const config = err.config;

    if (config.url !== '/auth/login' && err.response) {
      if (err.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          await refreshToken();

          return api(config);
        } catch (error) {
          if (!userRedirected) {
            userRedirected = true;
            setUserUnauthorized(true);
            ErrorToast.fire({
              text: 'Sua sessão expirou, faça login novamente!'
            });
            window.location.href = '/';
          }
          return Promise.reject(error);
        }
      }
    }
    if (err.response.status === 403) {
      ErrorToast.fire({
        text: err.response.data.cause
      });
    }
    return Promise.reject(err);
  }
);

export default api;
