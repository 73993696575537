import styled from 'styled-components';

interface ContainerProps {
  width: string;
  color: string;
  fontSize: string;
  marginLeft: string;
  disabled?: boolean;
  backgroundColor: string;
  backgroundHoverColor: string;
}

export const ButtonComponent = styled.button<ContainerProps>`
  width: ${(props) => props.width};
  min-height: 40px;
  height: 4vh;
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  border-style: none;
  border-radius: 10px;
  color: ${(props) => props.color};
  cursor: pointer;
  font-size: ${(props) => props.fontSize};
  margin-bottom: 20px;
  margin-left: ${(props) => props.marginLeft};
  margin-top: 2px;
  font-family: 'Montserrat';
  transition: 0.3s;

  &:hover {
    background-color: ${(props) => (props.disabled ? 'none' : props.backgroundHoverColor)};
  }

  @media (max-width: 1500px) {
    font-size: 12px;
  }

  @media (max-height: 900px) {
    margin-top: 10px;
  }
`;
