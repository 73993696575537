import styled from 'styled-components';
import { screenBreakPoint } from '../../screen-break-points';
import { Color } from '../../styles/themes';

interface SelectWrapperProps {
  ErrorMessage?: string;
  labelBackgroundColor?: string;
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: ${(props) => props.theme.title};
  border: 1px solid ${(props) => (props.ErrorMessage ? Color.red : props.theme.inputBorderColor)};
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.background};

  & > span {
    pointer-events: none;
    position: absolute;
    left: 0px;
    transform: translateX(10px) translateY(-8px);
    padding-left: 10px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    font-family: 'Montserrat-Regular';
    font-size: 11px;
    background-color: ${(props) => props.theme.background};
    color: ${(props) => (props.ErrorMessage ? Color.red : props.theme.inputLabelColor)};
    letter-spacing: 1px;
  }
`;

export const Container = styled.select`
  width: 100%;
  height: 30px;
  border: 0px;
  outline: none;
  font-size: 13px;
  font-family: 'Montserrat-Regular';
  padding: 5px;
  background-color: transparent;
  box-sizing: border-box;
  min-height: '40px';
  color: ${(props) => props.theme.title};

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 11px;
  }
`;
