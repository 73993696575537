import { useNumbermask } from './use-number-mask';

export const useStateRegisterMask = (value: string) => {
  value = useNumbermask(value);

  if (value.length > 14) {
    return value.slice(0, 14);
  }

  return value;
};
