import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetTermsContent } from '../utils/get-terms-content';
import { ContainerConteudoTermosDeUso, ContainerTermosDeUso, ContainerTituloTermosDeUso } from './styled-components_terms_of_use';
import DOMPurify from 'dompurify';

// Css da biblioteca React-Quill => Rich Text Editor
import './../quill-snow.css';

function TermsOfUse() {
  const [searchParams] = useSearchParams();
  const identifier = searchParams.get('identifier');
  const isDarkTheme = searchParams.get('isDarkTheme') == 'true';

  const [content, setContent] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (identifier) {
      (async function () {
        const {
          data: { content, title }
        } = await GetTermsContent(identifier);

        setContent(content);
        setTitle(title);
      })();
    }
  }, []);

  return (
    <ContainerTermosDeUso>
      <ContainerConteudoTermosDeUso>
        <ContainerTituloTermosDeUso isDarkTheme={isDarkTheme}>{title}</ContainerTituloTermosDeUso>
        <div
          className='term-of-use-content'
          style={{ fontFamily: 'Montserrat', color: isDarkTheme ? '#fafafa' : undefined }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        ></div>
      </ContainerConteudoTermosDeUso>
    </ContainerTermosDeUso>
  );
}

export default TermsOfUse;
