import { ReactElement, useContext } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import Header from '../common/components/header/header';
import { Content, MainSideBarContainer, MainSideBarPagesContainer } from '../common/components/header/header-styled';
import Pages from '../common/components/header/pages-icons';
import { ScrollableTargetContext } from '../common/scrollable-target-provider';
import { ScrollableTargetComponentId } from '../common/utils/constraints';
import { BshopLiteContainer, BshopLiteContent, BshopLiteContentLoading } from './template-styled';
import { Color } from '../common/styles/themes';
import UserFirstSteps from '../common/components/user-first-steps/user-first-steps';

interface TemplateProps {
  children: ReactElement;
  loading?: boolean;
  updateTrigger?: boolean;
}

function TemplateBshopLite(props: TemplateProps) {
  const { ref: bshopliteContentRef } = useContext(ScrollableTargetContext);
  return (
    <BshopLiteContainer>
      {props.loading ? (
        <BshopLiteContentLoading>
          <ClipLoader color={Color.primaryColorLigth} size={45}></ClipLoader>
        </BshopLiteContentLoading>
      ) : null}
      <Header />
      <Content>
        <MainSideBarContainer>
          <MainSideBarPagesContainer>
            <Pages checkCurrentPage={true}></Pages>
          </MainSideBarPagesContainer>
        </MainSideBarContainer>
        <BshopLiteContent ref={bshopliteContentRef} id={ScrollableTargetComponentId}>
          {props.children}
        </BshopLiteContent>
        <UserFirstSteps trigger={props.updateTrigger} />
      </Content>
    </BshopLiteContainer>
  );
}

export default TemplateBshopLite;
