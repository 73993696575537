import styled from 'styled-components';
import { screenBreakPoint } from '../../../common/screen-break-points';
import { Color } from '../../../common/styles/themes';

export const DashboardPage = styled.div`
  min-height: 150vh;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 64px auto;
`;

export const DashboardComponentsContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  justify-content: center;
`;

export const DashboardCenteredComponentsContainer = styled.div`
  width: 74vw;
  height: auto;

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    width: 95vw;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    width: 94vw;
  }
`;

export const IndicatorsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex: 1;

  @media (max-width: 1200px) {
    gap: 8px;
  }

  @media (max-width: 1000px) {
    display: block;
    overflow: auto;
    white-space: nowrap;
  }

  @media (max-width: ${screenBreakPoint.mobileWidthSmall}px) {
    display: block;
    overflow: auto;
    white-space: nowrap;
  }

  @media (max-height: ${screenBreakPoint.mobileHeightSmall}px) {
    display: block;
    overflow: auto;
    white-space: nowrap;
  }
`;

export const CardIndicator = styled.div`
  background: ${(props) => props.theme.secondaryBackground};
  width: 100%;
  height: 100px;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  color: '#333131eb';
  -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    width: 30%;
    padding: 10px;
    display: inline-block;
    margin-bottom: 13px;
    margin-right: 10px;
    margin-left: 10px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    width: 45%;
    padding: 0px;
    display: inline-block;
    margin-bottom: 13px;
    margin-top: 13px;

    margin-right: 10px;
  }

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    width: 50%;
    padding: 5px;
    display: inline-block;
    margin-bottom: 13px;
    margin-right: 10px;
  }
`;

export const ContainerChartsComponents = styled.div`
  width: 100%;
  height: 115vh;
  display: grid;

  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-top: 20px;

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    height: 205vh;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    height: 117vh;
    & > :nth-child(4) {
      height: 600px;
    }
  }
`;

interface ChartProps {
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number;
}

export const ChartBaseContainer = styled.div<ChartProps>`
  padding: 5px 12px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
  grid-column-start: ${(props) => props.gridColumnStart};
  grid-column-end: ${(props) => props.gridColumnEnd};
  grid-row-start: ${(props) => props.gridRowStart};
  grid-row-end: ${(props) => props.gridRowEnd};
  background: #ffffff;

  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.secondaryBackground};

  @media (max-width: 1100px) {
    font-size: 11px;
    height: 45vh;
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: unset;
    grid-row-end: unset;
    padding: 20px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 11px;
    height: 40vh;
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: unset;
    grid-row-end: unset;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) and (max-height: ${screenBreakPoint.mobileHeight}px) {
    font-size: 11px;
    height: 360px;
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: unset;
    grid-row-end: unset;
  }
`;

export const ChartScrollableContainter = styled.div`
  width: 90%;
  height: 100%;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    width: 700px;
  }
`;

export const DashboardSelectPeriod = styled.select`
  background-color: transparent;
  padding: 8px;
  border: none;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
  font-size: 16px;
  background-color: ${(props) => props.theme.secondaryBackground};
  margin-top: 5px;
  color: ${(props) => props.theme.title};
`;

export const SelectOption = styled.option`
  margin: 0 0;
  padding: 0 0;
  border: none;
  border: 1px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.title};

  li {
    padding: 0 6px;
    margin: 0 0;
  }

  li:hover {
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
  }
`;

export const IndicatorTitle = styled.div`
  font-size: 15px;
  font-weight: 600;

  @media (max-width: 1600px) {
    font-size: 13px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 5px;
  }
`;

export const InfoContainer = styled.div`
  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    margin-right: 5px;
    margin-top: 5px;
  }
`;

export const ProductPositionContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 9%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    margin-bottom: 7.5px;
  }
`;

interface ProductProgressBarProps {
  Progress: string;
}

export const ProductProgressBar = styled.div<ProductProgressBarProps>`
  height: 100%;
  width: ${(props) => props.Progress};
  background-color: #78bdda;
  border-radius: 14px;
`;

export const ProductImageWrapper = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.primaryColor};
  display: flex;
  justify-content: center;

  @media (max-height: 850px) {
    height: 25px;
    width: 25px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    height: 30px;
    width: 30px;
  }

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    height: 28px;
    width: 28px;
  }
`;

export const ProductNameAndValueWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  gap: 20px;
  @media (max-width: 1200px) {
    font-size: 12px;
  }

  @media (max-height: 850px) {
    font-size: 11px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 11px;
  }

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    font-size: 11px;
  }
`;

export const ProductProgressBarWrapper = styled.div`
  width: 100%;
  min-height: 13px;
  background-color: #ebe9e9;
  border-radius: 14px;
  position: relative;
  font-size: 12px;

  @media (max-width: 1200px) {
    min-height: 11px;
    font-size: 13px;
  }

  @media (max-height: 850px) {
    min-height: 10px;
    font-size: 11px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    min-height: 10px;
    font-size: 10px;
  }
  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    min-height: 8px;
    font-size: 8px;
  }
`;

export const ProducsContainer = styled.div`
  height: 95%;
  margin-top: 25px;
  width: 85%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 7px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    margin-bottom: 65px;
  }
`;

export const ChartHeader = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ChartTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 600;
  color: ${(props) => props.theme.title};

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    font-size: 14px;
  }
`;

export const ChartShowLabelInput = styled.div`
  cursor: pointer;
  color: #3487d5;
  transition: 0.3s;

  &:hover {
    color: #0e5ca5;
  }

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    font-size: 14px;
  }
`;

export const ChartShowLabelDescription = styled.span`
  font-family: 'Montserrat';
  font-size: 14px;
  cursor: pointer;

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    font-size: 10px;
  }
`;

interface ChartScrollableInterface {
  isScrollable: boolean;
}

export const ChartWrapper = styled.div<ChartScrollableInterface>`
  flex: 9;
  min-height: 0;
  font-family: 'Montserrat';
  font-size: 14px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    overflow: ${(props) => (props.isScrollable ? 'auto' : 'none')};
  }
`;

export const ContainerCustomTooltip = styled.div`
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
`;

interface PercentageProps {
  isPositive?: boolean;
  isNegative?: boolean;
}

export const Percentage = styled.div<PercentageProps>`
  padding: 6px;
  background-color: ${(props) => (props.isPositive ? '#EAFFDB' : props.isNegative ? '#FFE3E3' : Color.greyOpacity)};
  color: ${(props) => (props.isPositive ? 'green' : props.isNegative ? Color.red : 'black')};
  border-radius: 10px;
  margin-left: 12px;
  margin-right: 3px;
  font-size: 11px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 9px;
    padding: 4px;
  }
`;

export const PercentageContainter = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  margin-top: 7px;
`;

export const IndicatorPopUp = styled.div`
  position: absolute;
  font-family: 'Montserrat-regular';
  background: ${(props) => props.theme.background};
  font-size: 13.5px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  width: 145px;
  justify-content: space-between;
  gap: 5px;
  -webkit-box-shadow: ${(props) =>
    props.theme.background === '#03101b' ? '0px 1px 4px 2px rgba(255, 255, 255, 0.2);' : '0px 1px 4px 2px rgba(0, 0, 0, 0.2)'};
  -moz-box-shadow: ${(props) =>
    props.theme.background === '#03101b' ? '0px 1px 4px 2px rgba(255, 255, 255, 0.2);' : '0px 1px 4px 2px rgba(0, 0, 0, 0.2)'};
  box-shadow: ${(props) =>
    props.theme.background === '#03101b' ? '0px 1px 4px 2px rgba(255, 255, 255, 0.2);' : '0px 1px 4px 2px rgba(0, 0, 0, 0.2)'};
  border-radius: 6px;
  & :before {
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    border-bottom: none;
    -webkit-box-shadow: ${(props) =>
      props.theme.background === '#03101b' ? '0px 1px 4px 2px rgba(255, 255, 255, 0.1);' : '0px 1px 4px 2px rgba(0, 0, 0, 0.1)'};
    -moz-box-shadow: ${(props) =>
      props.theme.background === '#03101b' ? '0px 1px 4px 2px rgba(255, 255, 255, 0.1);' : '0px 1px 4px 2px rgba(0, 0, 0, 0.1)'};
    box-shadow: ${(props) =>
      props.theme.background === '#03101b' ? '0px 1px 4px 2px rgba(255, 255, 255, 0.1);' : '0px 1px 4px 2px rgba(0, 0, 0, 0.1)'};
    background: ${(props) => props.theme.background};
    position: absolute;
    left: 25px;
    top: 0px;
    z-index: -1;
  }
  /*   & :after {
    content: '';
    width: 8px;
    height: 8px;
    background: ${(props) => props.theme.background};
    position: absolute;
    left: 10px;
    top: -0.2px;
    transform: rotate(90deg);
    z-index: 0;
  } */
`;

export const IndicatorPopUpContainer = styled.div`
  position: relative;
  right: 30px;
  z-index: 0;
  bottom: -6px;
`;

export const IndicatorPopUpName = styled.p`
  font-size: 15px;
  font-weight: 900;
  padding-bottom: 5px;
`;
export const IndicatorPopUpPeriodDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & span {
    flex: 0 1 auto;
  }
  & p {
    font-weight: 900;
    text-overflow: ellipsis;
    flex: 1 1 auto;
    text-align: end;
    overflow: hidden;
  }
`;

interface MoneySymbolProps {
  isMoney: boolean;
}

export const MoneySymbol = styled.div<MoneySymbolProps>`
  height: 100%;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  flex: 0.5;
  display: ${(props) => (props.isMoney ? 'flex' : 'none')};
  align-items: center;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;

interface IndicatorValueProps {
  isMoney: boolean;
  valueLength: number;
}

export const IndicatorValue = styled.div<IndicatorValueProps>`
  height: 100%;
  width: 100%;
  flex: 3;
  font-family: 'Montserrat-regular';
  padding-right: 5px;
  font-weight: 600;
  font-size: ${(props) => (props.isMoney ? '33px' : props.valueLength > 7 ? '29px' : '33px')};
  display: flex;
  align-items: center;

  @media (max-width: 1600px) {
    font-size: ${(props) => (props.isMoney ? '22px' : props.valueLength > 7 ? '17px' : '24px')};
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: ${(props) => (props.isMoney ? '15px' : props.valueLength > 7 ? '13px' : '23px')};
    padding-right: 2px;
  }
`;

export const IndicatorContentContainter = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.title};
`;

export const IndicatorHeader = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  font-family: 'Montserrat';
  display: flex;
  justify-content: space-between;
`;

export const IndicatorStatus = styled.div`
  height: 100%;
  width: 100%;
  flex: 1.25;
  display: flex;
  font-family: 'Montserrat-regular';
  font-size: 12px;
  justify-content: center;
  padding-top: 5px;
`;
