import { validate } from 'class-validator';
import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdOutlineError } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConfirmToast } from '../../../../common/alerts/custom-alert-confirm';
import { ErrorToast } from '../../../../common/alerts/custom-alert-error';
import { InfoToast } from '../../../../common/alerts/custom-alert-info';
import { SuccessToast } from '../../../../common/alerts/custom-alert-success';
import ButtonOutline from '../../../../common/components/button-outline/button-outline';
import Button from '../../../../common/components/button/button';
import { Input } from '../../../../common/components/input/input';
import Popup from '../../../../common/components/popup/popup';
import { Select } from '../../../../common/components/select/select';
import { deepCopy } from '../../../../common/utils/deep-copy';
import { usePorcentageFormatter } from '../../../../common/utils/formatters/use-porcentage-formatter';
import { NumberStringToDecimalString } from '../../../../common/utils/number-to-decimal';
import { SanitizeData } from '../../../../common/utils/sanitize-data';
import TemplateBshopLite from '../../../../template/template';
import { useMoneyMask } from '../../../tela_cadastro/utils/masks/use-money-mask';
import { usePorcentageMask } from '../../../tela_cadastro/utils/masks/use-porcentage-mask';
import { ProductFormTabs } from '../../common/enum/product-form-tabs';
import { CreateProductMapper } from '../../common/mapper/create-product.mapper';
import { ImageMapper } from '../../common/mapper/image.mapper';
import { UpdateProductMapper } from '../../common/mapper/update-product.mapper';
import { VariationResponseMapper } from '../../common/mapper/variation.mapper';
import { InitialStateVariations } from '../../common/state';
import { ProductDataValidator } from '../../common/validations/product-data-validator';
import generateVariationGrid from '../../common/variation-grid-type';
import { ImageType, TagsType, VariationsGridAge, VariationsGridType, VariationsType } from '../../dto';
import { InitialProductDataState } from '../../dto/initial-product-data';
import { ProductDataActions, ProductDataState } from '../../dto/product-data';
import { ProductFiscalOption } from '../../dto/product-fiscal-option-type';
import { GetProduct } from '../../utils/get-product';
import { GetProductsFiscalOptions } from '../../utils/get-products-fiscal-options';
import { PatchProduct } from '../../utils/patch-product';
import { PostProduct } from '../../utils/post-product';
import { Col, FixedCol, Grid, ScrollableCol, InfoTitle, Variation, VariationRounded } from '../product-details/products-details-styled';
import { Header, LinkPlus, TitleAndOption } from '../products/products-styled';
import ImageSelector from './components/image-selector/image-selector';
import SearchNCM from './components/search-ncm/search-ncm';
import TagInput from './components/tag-input/tag-input';
import VariationGridGenerator from './components/variation-grid-generator/variation-grid-generator';
import { VariationRegister } from './components/variation-register/variation-register';
import {
  FormInputTwoColumnsContainer,
  TabLabel,
  TabsContentContainer,
  TabsLabelContainer,
  WrapperGenerateAIButton
} from './register-or-update-products-styled';
import { useTheme } from '../../../../common/theme/theme-context';
import { Title } from '../../../../common/styles/global';
import { Color } from '../../../../common/styles/themes';
import { SelectOption } from '../../../dashboard/view/dashboard-styled-components';
import { generateDescriptionByImageId } from './components/image-selector/utils/generate-description-by-image-id';
import LoaderModal from './components/image-selector/components/loading-modal';
import { ImMagicWand } from 'react-icons/im';
function RegisterOrUpdateProduct() {
  const DefaultCstIdentifier = '49';
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('id');
  const isEditing = Boolean(productId);

  const [selectedFormTab, setSelectedFormTab] = useState<ProductFormTabs>(ProductFormTabs.detailsTab);
  const [loading, setLoading] = useState(false);
  const [ProductData, setProductData] = useReducer(
    (state: ProductDataState, action: ProductDataActions) => {
      if (action.type === 'productData' && typeof action.payload !== 'string') {
        return {
          ...state,
          ...action.payload,
          defaultPrice: useMoneyMask(action.payload.defaultPrice),
          aliquotaIcms: usePorcentageMask(NumberStringToDecimalString(action.payload.aliquotaIcms)),
          aliquotaPis: usePorcentageMask(NumberStringToDecimalString(action.payload.aliquotaPis)),
          aliquotaCofins: usePorcentageMask(NumberStringToDecimalString(action.payload.aliquotaCofins)),
          aliquotaIpi: usePorcentageMask(NumberStringToDecimalString(action.payload.aliquotaIpi)),
          idxReducaoBaseCalculo: usePorcentageMask(NumberStringToDecimalString(action.payload.idxReducaoBaseCalculo)),
          margemValorAgregado: usePorcentageMask(NumberStringToDecimalString(action.payload.margemValorAgregado))
        };
      }

      if (typeof action.payload !== 'string') {
        return { ...state };
      }

      switch (action.type) {
        case 'defaultPrice':
          return { ...state, defaultPrice: useMoneyMask(action.payload) };
        case 'description':
          return { ...state, description: action.payload };
        case 'ncm':
          return { ...state, ncm: action.payload };
        case 'reference':
          return { ...state, reference: action.payload };
        case 'unit':
          return { ...state, unit: action.payload };
        case 'aliquotaIcms':
          return { ...state, aliquotaIcms: usePorcentageMask(action.payload) };
        case 'aliquotaPis':
          return { ...state, aliquotaPis: usePorcentageMask(action.payload) };
        case 'aliquotaCofins':
          return { ...state, aliquotaCofins: usePorcentageMask(action.payload) };
        case 'aliquotaIpi':
          return { ...state, aliquotaIpi: usePorcentageMask(action.payload) };
        case 'cstIcms':
          return { ...state, cstIcms: action.payload };
        case 'cstPis':
          return { ...state, cstPis: action.payload };
        case 'cstCofins':
          return { ...state, cstCofins: action.payload };
        case 'origem':
          return { ...state, origem: action.payload };
        case 'idxReducaoBaseCalculo':
          return { ...state, idxReducaoBaseCalculo: usePorcentageMask(action.payload) };
        case 'codBeneficioFiscal':
          return { ...state, codBeneficioFiscal: action.payload };
        case 'codEspecificadorSubstTribCest':
          return { ...state, codEspecificadorSubstTribCest: action.payload };
        case 'cfop':
          return { ...state, cfop: action.payload };
        case 'margemValorAgregado':
          return { ...state, margemValorAgregado: usePorcentageMask(action.payload) };
        default:
          return state;
      }
    },
    {
      ...InitialProductDataState,
      defaultPrice: useMoneyMask('0'),
      aliquotaIcms: usePorcentageMask('0'),
      aliquotaCofins: usePorcentageMask('0'),
      aliquotaIpi: usePorcentageMask('0'),
      aliquotaPis: usePorcentageMask('0'),
      idxReducaoBaseCalculo: usePorcentageMask('0'),
      margemValorAgregado: usePorcentageMask('0')
    }
  );
  const [ProductDataErrorsMessages, setProductDataErrorsMessages] = useReducer(
    (state: ProductDataState, action: ProductDataActions) => {
      if (action.type === 'productData') {
        return { ...state, ...(action.payload as ProductDataState) };
      }

      if (typeof action.payload !== 'string') {
        return { ...state };
      }

      switch (action.type) {
        case 'defaultPrice':
          return { ...state, defaultPrice: action.payload };
        case 'description':
          return { ...state, description: action.payload };
        case 'ncm':
          return { ...state, ncm: action.payload };
        case 'reference':
          return { ...state, reference: action.payload };
        case 'unit':
          return { ...state, unit: action.payload };
        case 'aliquotaIcms':
          return { ...state, aliquotaIcms: action.payload };
        case 'aliquotaPis':
          return { ...state, aliquotaPis: action.payload };
        case 'aliquotaCofins':
          return { ...state, aliquotaCofins: action.payload };
        case 'aliquotaIpi':
          return { ...state, aliquotaIpi: action.payload };
        case 'cstIcms':
          return { ...state, cstIcms: action.payload };
        case 'cstPis':
          return { ...state, cstPis: action.payload };
        case 'cstCofins':
          return { ...state, cstCofins: action.payload };
        case 'origem':
          return { ...state, origem: action.payload };
        case 'idxReducaoBaseCalculo':
          return { ...state, idxReducaoBaseCalculo: action.payload };
        case 'codBeneficioFiscal':
          return { ...state, codBeneficioFiscal: action.payload };
        case 'codEspecificadorSubstTribCest':
          return { ...state, codEspecificadorSubstTribCest: action.payload };
        case 'cfop':
          return { ...state, cfop: action.payload };
        case 'margemValorAgregado':
          return { ...state, margemValorAgregado: action.payload };
        default:
          return state;
      }
    },
    { ...InitialProductDataState }
  );

  const [DetailsTabValidationError, setDetailsTabValidationError] = useState(false);
  const [FiscalTabValidationError, setFiscalTabValidationError] = useState(false);

  const [Variations, setVariations] = useState<VariationsType[]>([]);
  const [Images, setImages] = useState<ImageType[]>([]);
  const [Tags, setTags] = useState<TagsType[]>([]);

  const [showGenerateGridPopup, setShowGenerateGridPopup] = useState(false);
  const [showCreateVariationPopup, setShowCreateVariationPopup] = useState(false);
  const [showUpdateOrRemoveVariationPopup, setShowUpdateOrRemoveVariationPopup] = useState(false);

  const [selectedVariation, setSelectedVariation] = useState<VariationsType>(InitialStateVariations);
  const [selectedVariationIndex, setSelectedVariationIndex] = useState<number>(-1);

  const [situacaoTributariaOptions, setSituacaoTributariaOptions] = useState<Array<ProductFiscalOption>>([]);
  const [codigoSituacaoTributariaOptions, setCodigoSituacaoTributariaOptions] = useState<Array<ProductFiscalOption>>([]);
  const [origemOptions, setOrigemOptions] = useState<Array<ProductFiscalOption>>([]);
  const [cfopOptions, setCfopOptions] = useState<Array<ProductFiscalOption>>([]);
  const [unidadeOptions, setUnidadeOptions] = useState<Array<ProductFiscalOption>>([]);

  const [initialVariations, setInitialVariations] = useState<VariationsType[]>([]);
  const [initialTags, setInitialTags] = useState<TagsType[]>([]);
  const [initialDefaultImage, setInitialDefaultImage] = useState<ImageType | null>(null);

  const [inputDefaultPrice, setInputDefaultPrice] = useState('');
  const { theme } = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    (async (): Promise<void> => {
      const fiscalOptions = await GetProductsFiscalOptions();

      setSituacaoTributariaOptions(fiscalOptions.situacaoTributaria);
      setCodigoSituacaoTributariaOptions(fiscalOptions.codigoSituacaoTributaria);
      setOrigemOptions(fiscalOptions.origem);
      setCfopOptions(fiscalOptions.cfop);
      setUnidadeOptions(fiscalOptions.unidade);

      const initialCst = fiscalOptions.situacaoTributaria.find((option) => option.identifier === DefaultCstIdentifier);
      if (initialCst) {
        // Sincronizar com GetProduct
        setProductData({ type: 'cstCofins', payload: initialCst.identifier });
        setProductData({ type: 'cstPis', payload: initialCst.identifier });
      }

      if (isEditing && productId) {
        const data = await GetProduct(productId);

        const { Images, ProductVariation, tags, ...rest } = data;

        const convertedVariations = ProductVariation.map(VariationResponseMapper);
        setVariations(convertedVariations);
        setInitialVariations(deepCopy(convertedVariations));

        const convertedImages = Images.map(ImageMapper);
        setImages(convertedImages);
        setInitialDefaultImage(convertedImages.find((image) => image.default) ?? null);

        setTags(tags);
        setInitialTags(deepCopy(tags));

        setProductData({
          type: 'productData',
          payload: {
            ...rest,
            description: rest.name,
            unit: rest.unit ?? '',
            aliquotaIcms: rest.fiscalProductData?.aliquotaIcms ?? '',
            aliquotaPis: rest.fiscalProductData?.aliquotaPis ?? '',
            aliquotaCofins: rest.fiscalProductData?.aliquotaCofins ?? '',
            aliquotaIpi: rest.fiscalProductData?.aliquotaIpi ?? '',
            cstIcms: rest.fiscalProductData?.cstIcms ?? '',
            cstPis: rest.fiscalProductData?.cstPis ?? '',
            cstCofins: rest.fiscalProductData?.cstCofins ?? '',
            origem: rest.fiscalProductData?.origem ?? '',
            idxReducaoBaseCalculo: rest.fiscalProductData?.idxReducaoBaseCalculo ?? '',
            codBeneficioFiscal: rest.fiscalProductData?.codBeneficioFiscal ?? '',
            codEspecificadorSubstTribCest: rest.fiscalProductData?.codEspecificadorSubstTribCest ?? '',
            cfop: rest.fiscalProductData?.cfop ?? '',
            margemValorAgregado: rest.fiscalProductData?.margemValorAgregado ?? ''
          }
        });
      }
    })();
  }, []);

  const handleTagsChange = (newTags: TagsType[]) => {
    setTags(newTags);
  };

  const validadeVariationDescription = (variation: VariationsType, indexKey = -1): boolean => {
    if (Variations.find((element, index) => element.description === variation.description && index != indexKey)) {
      return false;
    }
    return true;
  };

  const removeVariation = (index: number): void => {
    const currentVariations = [...Variations];
    currentVariations.splice(index, 1);
    setVariations(currentVariations);

    setShowUpdateOrRemoveVariationPopup(false);
  };

  const createVariation = (variation: VariationsType) => {
    if (validadeVariationDescription(variation)) {
      const currentVariations = [...Variations];
      currentVariations.push(variation);
      setVariations(currentVariations);

      setShowCreateVariationPopup(false);
    } else {
      InfoToast.fire({
        text: 'Já existe variação com a mesma descrição!'
      });
    }
  };

  const updateVariation = (variation: VariationsType, index: number) => {
    const currentVariations = [...Variations];

    if (validadeVariationDescription(variation, index)) {
      currentVariations[index] = { ...variation };
      setVariations(currentVariations);

      setShowUpdateOrRemoveVariationPopup(false);
    } else {
      InfoToast.fire({
        text: 'Erro ao atualizar variação. Por favor, tente novamente!'
      });
    }
  };

  const generateVariation = (props: {
    variationsGridType: VariationsGridType;
    variationsGridAge: VariationsGridAge;
    variationPrice: string;
  }) => {
    const variations = generateVariationGrid(props.variationsGridType, props.variationsGridAge, props.variationPrice);

    variations.forEach((element) => {
      if (validadeVariationDescription(element)) {
        setVariations((prev) => [...prev, element]);

        setShowGenerateGridPopup(false);
      } else {
        InfoToast.fire({
          text: 'Já existe variação com a mesma descrição!'
        });
      }
    });
  };

  const onSubmit = async (): Promise<void> => {
    const isValidProduct = await validateProduct();
    if (!isValidProduct) return;

    if (isEditing) {
      await updateProduct();
    } else {
      await createProduct();
    }
  };

  const validateProduct = async (): Promise<boolean> => {
    setDetailsTabValidationError(false);
    setFiscalTabValidationError(false);

    setProductDataErrorsMessages({ type: 'productData', payload: { ...InitialProductDataState } });
    let isValidProduct = true;

    const sanitizedProductData = SanitizeData(ProductData);

    const errors = await validate(new ProductDataValidator({ ...sanitizedProductData }));

    if (errors.length > 0) {
      for (let x = 0; x < errors.length; x++) {
        const Messages = Object.values(errors[x].constraints || {});

        setProductDataErrorsMessages({
          type: errors[x].property as ProductDataActions['type'],
          payload: Messages[0] || 'Campo não preenchido corretamente'
        });
      }

      setDetailsTabValidationError(true);
      isValidProduct = false;
    }

    if (ProductData.ncm) {
      if (usePorcentageFormatter(ProductData.aliquotaIcms) <= 0) {
        isValidProduct = false;
        setFiscalTabValidationError(true);
        setProductDataErrorsMessages({
          type: 'aliquotaIcms',
          payload: 'O campo "Alíquota ICSM" precisa ser preenchido!'
        });
      }

      if (!ProductData.cstIcms) {
        isValidProduct = false;
        setFiscalTabValidationError(true);
        setProductDataErrorsMessages({
          type: 'cstIcms',
          payload: 'O campo "CST ICSM" precisa ser preenchido!'
        });
      }

      if (!ProductData.origem) {
        isValidProduct = false;
        setFiscalTabValidationError(true);
        setProductDataErrorsMessages({
          type: 'origem',
          payload: 'O campo "Origem" precisa ser preenchido!'
        });
      }

      if (!ProductData.cfop) {
        isValidProduct = false;
        setFiscalTabValidationError(true);
        setProductDataErrorsMessages({
          type: 'cfop',
          payload: 'O campo "CFOP" precisa ser preenchido!'
        });
      }
    }

    return isValidProduct;
  };

  const ConfirmActionUpdatePriceVariation = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (Variations.length == 0) return;

    if (value == inputDefaultPrice) return;

    if (Variations.every((item) => item.price == value)) return;

    const result = await ConfirmToast.fire({
      text: 'Você deseja atualizar o preço de todas as variações?',
      confirmButtonText: 'Atualizar',
      cancelButtonText: 'Cancelar',
      color: theme === 'dark' ? '#EDF5FD' : '#343239',
      background: theme === 'dark' ? '#0A1623' : '#fff'
    });

    if (result.isConfirmed) {
      const updatedVariations = Variations.map((item) => ({
        ...item,
        price: ProductData.defaultPrice
      }));
      setVariations(updatedVariations);
    }
  };

  const updateProduct = async (): Promise<void> => {
    try {
      if (Variations.length === 0) {
        ErrorToast.fire({
          text: 'Produto necessita ao menos 1 variação!'
        });
        return;
      }

      const { createdVariations, deletedVariations, updatedVariations } = setVariationsArrays();

      const { deletedTags } = setTagsArrays();

      const updateProductDto = UpdateProductMapper({
        productData: { ...ProductData },
        images: Images,
        tags: Tags,
        variations: Variations,
        createdVariations,
        deletedVariations,
        updatedVariations,
        deletedTags,
        oldDefaultImageId: initialDefaultImage?.id
      });

      await PatchProduct(productId, updateProductDto);

      SuccessToast.fire({
        text: 'Produto atualizado com sucesso!'
      });
      navigate(`/product-details/${productId}`);
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao atualizar Produtos. Por favor, tente novamente!'
      });
    }
  };

  const createProduct = async (): Promise<void> => {
    try {
      const createProductDto = CreateProductMapper({
        productData: { ...ProductData },
        images: Images,
        tags: Tags,
        variations: Variations
      });

      await PostProduct(createProductDto);
      SuccessToast.fire({
        text: 'Produto criado com sucesso!'
      });
      navigate('/products');
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao criar produto. Por favor, tente novamente!'
      });
    }
  };

  const setVariationsArrays = (): {
    createdVariations: Array<VariationsType>;
    updatedVariations: Array<VariationsType>;
    deletedVariations: Array<number>;
  } => {
    const createdVariations: Array<VariationsType> = [];
    const updatedVariations: Array<VariationsType> = [];
    const deletedVariations: Array<number> = [];

    for (const variation of Variations) {
      if (variation.id === null) {
        createdVariations.push(variation);
      }
    }

    for (const initialVariation of initialVariations) {
      const variation = Variations.find((variation) => variation.id === initialVariation.id);

      if (variation) {
        const updatedVariation = Variations.find((variation) => variation.id === initialVariation.id);

        if (updatedVariation && JSON.stringify(updatedVariation) !== JSON.stringify(initialVariation)) {
          updatedVariations.push(updatedVariation);
        }
      } else {
        if (initialVariation.id !== null) {
          deletedVariations.push(initialVariation.id);
        }
      }
    }

    return {
      createdVariations,
      deletedVariations,
      updatedVariations
    };
  };

  const setTagsArrays = (): {
    deletedTags: Array<number>;
  } => {
    const deletedTags: Array<number> = [];

    for (const initialTag of initialTags) {
      const tag = Tags.find((item) => item.id === initialTag.id);
      if (!tag) {
        if (initialTag.id !== null) {
          deletedTags.push(Number(initialTag.id));
        }
      }
    }

    return {
      deletedTags
    };
  };

  useEffect(() => {
    if (DetailsTabValidationError) {
      setSelectedFormTab(ProductFormTabs.detailsTab);
    } else if (FiscalTabValidationError) {
      setSelectedFormTab(ProductFormTabs.fiscalTab);
    }
  }, [DetailsTabValidationError, FiscalTabValidationError]);

  const generateDescription = async () => {
    setLoading(true);
    try {
      const generatedData = await generateDescriptionByImageId(Images[0].id);
      setProductData({
        type: 'productData',
        payload: {
          ...ProductData,
          description: generatedData.name,
          defaultPrice: useMoneyMask(String(generatedData.defaultPrice)) ?? ''
        }
      });
      setTags(generatedData.tags.map((tag) => ({ id: null, name: tag.name })));
      setVariations(
        generatedData.variations.map((variation) => ({
          id: null,
          name: variation.name,
          description: variation.name,
          auxCode: '',
          gtin: '',
          price: useMoneyMask(variation.price)
        }))
      );
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao gerar descricão. Por favor, tente novamente!'
      });
    }

    setLoading(false);
  };
  return (
    <TemplateBshopLite>
      <div>
        <LoaderModal loading={loading} />
        <Grid>
          <Header>
            <Title>{isEditing ? 'Editar Produto' : 'Cadastrar produto'}</Title>
          </Header>
        </Grid>
        <Grid>
          <Col>
            <FixedCol>
              <ImageSelector images={Images} setImages={setImages} isEditingOrRegistering={true}>
                {Images.length > 0 && !isEditing && (
                  <WrapperGenerateAIButton>
                    <Button
                      style={{ height: 'fit-content', background: Color.primaryColor, marginBottom: '0', marginTop: '0' }}
                      width={'220px'}
                      color={'#fff'}
                      fontSize={'16px'}
                      marginLeft={''}
                      type='button'
                      disabled={loading}
                      onClick={generateDescription}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          padding: '6px 4px'
                        }}
                      >
                        <ImMagicWand size={20} color={'#fff'} />
                        <p style={{ width: '80%' }}> Sugestões com Inteligência Artificial</p>
                      </div>
                    </Button>
                  </WrapperGenerateAIButton>
                )}
              </ImageSelector>
            </FixedCol>
          </Col>
          <Col>
            <ScrollableCol>
              <TabsLabelContainer>
                <TabLabel
                  isActive={selectedFormTab === ProductFormTabs.detailsTab}
                  isError={DetailsTabValidationError === true}
                  onClick={() => setSelectedFormTab(ProductFormTabs.detailsTab)}
                >
                  {DetailsTabValidationError && <MdOutlineError size={20} color={Color.red} />}
                  Detalhes
                </TabLabel>
                <TabLabel
                  isActive={selectedFormTab === ProductFormTabs.fiscalTab}
                  isError={FiscalTabValidationError === true}
                  onClick={() => setSelectedFormTab(ProductFormTabs.fiscalTab)}
                >
                  {FiscalTabValidationError && <MdOutlineError size={20} color={Color.red} />}
                  Informações fiscais
                </TabLabel>
              </TabsLabelContainer>

              <form>
                {selectedFormTab === ProductFormTabs.detailsTab ? (
                  <TabsContentContainer>
                    <Input
                      InputSize='medium'
                      value={ProductData.description}
                      placeholder='Descrição do produto:'
                      type='text'
                      name='description'
                      onChange={(e) => setProductData({ type: 'description', payload: e.target.value })}
                      floatingLabel={true}
                      ErrorMessage={ProductDataErrorsMessages.description}
                    ></Input>

                    <Input
                      InputSize='medium'
                      value={ProductData.defaultPrice}
                      placeholder='Preço padrão:'
                      type='text'
                      name='defaultPrice'
                      onFocus={(e) => setInputDefaultPrice(e.target.value)}
                      onBlur={ConfirmActionUpdatePriceVariation}
                      onChange={(e) => setProductData({ type: 'defaultPrice', payload: e.target.value })}
                      floatingLabel={true}
                      ErrorMessage={ProductDataErrorsMessages.defaultPrice}
                    ></Input>

                    <Select
                      value={ProductData.unit}
                      label='Unidade:'
                      onChange={(e) => setProductData({ type: 'unit', payload: e.target.value })}
                      ErrorMessage={ProductDataErrorsMessages.unit}
                    >
                      <SelectOption value=''>Selecione uma opção</SelectOption>

                      {unidadeOptions.map((option) => {
                        return (
                          <SelectOption key={option.description} value={option.description}>
                            {option.description}
                          </SelectOption>
                        );
                      })}
                    </Select>

                    <Input
                      InputSize='medium'
                      value={ProductData.reference}
                      placeholder='Referência'
                      type='text'
                      name='reference'
                      onChange={(e) => setProductData({ type: 'reference', payload: e.target.value })}
                      floatingLabel={true}
                      ErrorMessage={ProductDataErrorsMessages.reference}
                    ></Input>

                    <SearchNCM
                      NCMData={ProductData.ncm}
                      setNCMData={(ncmIdentifier: string) => setProductData({ type: 'ncm', payload: ncmIdentifier })}
                    />

                    <TagInput tags={Tags} setTags={setTags} onTagsChange={handleTagsChange} />

                    <TitleAndOption>
                      <InfoTitle>Variações</InfoTitle>
                      <LinkPlus
                        onClick={() => {
                          setShowGenerateGridPopup(true);
                        }}
                      >
                        <AiOutlinePlus color='#fffff' size={14} />
                        Gerar grade
                      </LinkPlus>
                    </TitleAndOption>
                    <Variation>
                      {Variations.length > 0 &&
                        Variations.map((variation, index) => (
                          <a
                            key={index}
                            onClick={() => {
                              setSelectedVariation({ ...variation, price: useMoneyMask(variation.price) });
                              setSelectedVariationIndex(index);
                              setShowUpdateOrRemoveVariationPopup(true);
                            }}
                          >
                            <VariationRounded>{variation.description}</VariationRounded>
                          </a>
                        ))}
                    </Variation>

                    <ButtonOutline
                      onClick={() => {
                        setShowCreateVariationPopup(true);
                      }}
                      style={{ marginBottom: '0', marginTop: '0' }}
                      width={'100%'}
                      type='button'
                      fontSize={''}
                      marginLeft={''}
                    >
                      Adicionar variação
                    </ButtonOutline>
                  </TabsContentContainer>
                ) : (
                  <TabsContentContainer>
                    <FormInputTwoColumnsContainer>
                      <Input
                        InputSize='medium'
                        value={ProductData.aliquotaIcms}
                        placeholder='Alíquota ICSM (%):'
                        type='text'
                        name='name'
                        onChange={(e) => setProductData({ type: 'aliquotaIcms', payload: e.target.value })}
                        floatingLabel={true}
                        ErrorMessage={ProductDataErrorsMessages.aliquotaIcms}
                      />

                      <Input
                        InputSize='medium'
                        value={ProductData.aliquotaPis}
                        placeholder='Alíquota PIS (%):'
                        type='text'
                        name='name'
                        onChange={(e) => setProductData({ type: 'aliquotaPis', payload: e.target.value })}
                        floatingLabel={true}
                        ErrorMessage={ProductDataErrorsMessages.aliquotaPis}
                      />
                    </FormInputTwoColumnsContainer>

                    <FormInputTwoColumnsContainer>
                      <Input
                        InputSize='medium'
                        value={ProductData.aliquotaCofins}
                        placeholder='Alíquota COFINS (%):'
                        type='text'
                        name='name'
                        onChange={(e) => setProductData({ type: 'aliquotaCofins', payload: e.target.value })}
                        floatingLabel={true}
                        ErrorMessage={ProductDataErrorsMessages.aliquotaCofins}
                      />

                      <Input
                        InputSize='medium'
                        value={ProductData.aliquotaIpi}
                        placeholder='Alíquota IPI (%):'
                        type='text'
                        name='name'
                        onChange={(e) => setProductData({ type: 'aliquotaIpi', payload: e.target.value })}
                        floatingLabel={true}
                        ErrorMessage={ProductDataErrorsMessages.aliquotaIpi}
                      />
                    </FormInputTwoColumnsContainer>

                    <FormInputTwoColumnsContainer>
                      <Select
                        value={ProductData.cstIcms}
                        label='CST ICSM:'
                        onChange={(e) => setProductData({ type: 'cstIcms', payload: e.target.value })}
                        ErrorMessage={ProductDataErrorsMessages.cstIcms}
                      >
                        <SelectOption value=''>Selecione uma opção</SelectOption>

                        {situacaoTributariaOptions.map((option) => {
                          return (
                            <SelectOption
                              key={option.identifier}
                              value={option.identifier}
                            >{`${option.identifier} - ${option.description}`}</SelectOption>
                          );
                        })}
                      </Select>

                      <Select
                        value={ProductData.origem}
                        label='Origem:'
                        onChange={(e) => setProductData({ type: 'origem', payload: e.target.value })}
                        ErrorMessage={ProductDataErrorsMessages.origem}
                      >
                        <SelectOption value=''>Selecione uma opção</SelectOption>

                        {origemOptions.map((option) => {
                          return (
                            <SelectOption
                              key={option.identifier}
                              value={option.identifier}
                            >{`${option.identifier} - ${option.description}`}</SelectOption>
                          );
                        })}
                      </Select>
                    </FormInputTwoColumnsContainer>

                    <FormInputTwoColumnsContainer>
                      <Select
                        value={ProductData.cstPis}
                        label='CST PIS:'
                        onChange={(e) => setProductData({ type: 'cstPis', payload: e.target.value })}
                        ErrorMessage={ProductDataErrorsMessages.cstPis}
                      >
                        <SelectOption value=''>Selecione uma opção</SelectOption>

                        {codigoSituacaoTributariaOptions.map((option) => {
                          return (
                            <SelectOption
                              key={option.identifier}
                              value={option.identifier}
                            >{`${option.identifier} - ${option.description}`}</SelectOption>
                          );
                        })}
                      </Select>
                      <Select
                        value={ProductData.cstCofins}
                        label='CST COFINS:'
                        onChange={(e) => setProductData({ type: 'cstCofins', payload: e.target.value })}
                        ErrorMessage={ProductDataErrorsMessages.cstCofins}
                      >
                        <SelectOption value=''>Selecione uma opção</SelectOption>

                        {codigoSituacaoTributariaOptions.map((option) => {
                          return (
                            <SelectOption
                              key={option.identifier}
                              value={option.identifier}
                            >{`${option.identifier} - ${option.description}`}</SelectOption>
                          );
                        })}
                      </Select>
                    </FormInputTwoColumnsContainer>

                    <Select
                      value={ProductData.cfop}
                      label='CFOP:'
                      onChange={(e) => setProductData({ type: 'cfop', payload: e.target.value })}
                      ErrorMessage={ProductDataErrorsMessages.cfop}
                    >
                      <SelectOption value=''>Selecione uma opção</SelectOption>

                      {cfopOptions.map((option) => {
                        return (
                          <SelectOption
                            key={option.identifier}
                            value={option.identifier}
                          >{`${option.identifier} - ${option.description}`}</SelectOption>
                        );
                      })}
                    </Select>

                    <Input
                      InputSize='medium'
                      value={ProductData.idxReducaoBaseCalculo}
                      placeholder='Índice Redução Base de Cálculo (%):'
                      type='text'
                      name='name'
                      onChange={(e) => setProductData({ type: 'idxReducaoBaseCalculo', payload: e.target.value })}
                      floatingLabel={true}
                      ErrorMessage={ProductDataErrorsMessages.idxReducaoBaseCalculo}
                    />

                    <Input
                      InputSize='medium'
                      value={ProductData.codBeneficioFiscal}
                      placeholder='Código Benefício Fiscal:'
                      type='text'
                      name='name'
                      onChange={(e) => setProductData({ type: 'codBeneficioFiscal', payload: e.target.value })}
                      floatingLabel={true}
                      ErrorMessage={ProductDataErrorsMessages.codBeneficioFiscal}
                    />

                    <Input
                      InputSize='medium'
                      value={ProductData.codEspecificadorSubstTribCest}
                      placeholder='Código Especificador Subst. Trib. CEST:'
                      type='text'
                      name='name'
                      onChange={(e) => setProductData({ type: 'codEspecificadorSubstTribCest', payload: e.target.value })}
                      floatingLabel={true}
                      ErrorMessage={ProductDataErrorsMessages.codEspecificadorSubstTribCest}
                    />

                    <Input
                      InputSize='medium'
                      value={ProductData.margemValorAgregado}
                      placeholder='Margem Valor Agregado (%):'
                      type='text'
                      name='name'
                      onChange={(e) => setProductData({ type: 'margemValorAgregado', payload: e.target.value })}
                      floatingLabel={true}
                      ErrorMessage={ProductDataErrorsMessages.margemValorAgregado}
                    />
                  </TabsContentContainer>
                )}
              </form>

              <TabsContentContainer>
                <Button
                  onClick={() => onSubmit()}
                  style={{ background: Color.secondaryColor, width: '100%', marginBottom: '0', marginTop: '0' }}
                  width={'150px'}
                  color={'#fff'}
                  fontSize={''}
                  marginLeft={''}
                  type='button'
                >
                  {isEditing ? 'Atualizar produto' : 'Cadastrar produto'}
                </Button>
              </TabsContentContainer>
            </ScrollableCol>
          </Col>
        </Grid>

        <Popup width={'30%'} height={'65%'} setTrigger={setShowUpdateOrRemoveVariationPopup} trigger={showUpdateOrRemoveVariationPopup}>
          <VariationRegister
            isEditing={true}
            onUpdateVariation={updateVariation}
            onDeleteVariation={removeVariation}
            initialVariationState={selectedVariation}
            variationIndex={selectedVariationIndex}
          />
        </Popup>

        <Popup width={'30%'} height={'50%'} setTrigger={setShowGenerateGridPopup} trigger={showGenerateGridPopup}>
          <VariationGridGenerator generateVariation={generateVariation} defaultPrice={ProductData.defaultPrice} />
        </Popup>

        <Popup width={'30%'} height={'60%'} setTrigger={setShowCreateVariationPopup} trigger={showCreateVariationPopup}>
          <VariationRegister
            isEditing={false}
            onCreateVariation={createVariation}
            initialVariationState={{ ...InitialStateVariations, price: ProductData.defaultPrice }}
          />
        </Popup>
      </div>
    </TemplateBshopLite>
  );
}

export default RegisterOrUpdateProduct;
