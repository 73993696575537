export const UseMoneyMask = (value: string): string => {
  const newValue = Number.parseFloat(value);
  const atual = newValue;

  const mask = atual.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });

  const result = String(mask);

  return result ? `${result}` : '';
};

export const UseMoney = (value: number): string => {
  const atual = value;

  const mask = atual.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });

  return mask ? `${mask}` : '';
};

export const moneyMask = (value: string): string => {
  if (value.length > 10) {
    return value;
  }
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  value = value.padStart(4, '0,00');
  value = value ? `R$ ${value}` : '';
  return value;
};

export function currencyToNumberFormatter(value: string): string {
  value = value.replace('R$', '').trim();
  if (value) {
    let newValue = moneyMask(value);
    newValue = newValue.replace('R$', '').trim();
    newValue = newValue.replace('.', '');
    newValue = newValue.replace(',', '.');

    newValue = newValue.padStart(4, '0,00');
    return Number(newValue).toFixed(2);
  }

  return '';
}
