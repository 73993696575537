import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { ErrorToast } from '../../../common/alerts/custom-alert-error';
import Button from '../../../common/components/button/button';
import { Input } from '../../../common/components/input/input';
import { useLoginExecute } from '../utils/use-login-execute';
import LogoBshopLite from './../../../images/logo-bshop-lite.svg';
import {
  ConainterLogo,
  ContainerButtons,
  ContainerComponentsLogin,
  ContainerComponentsPainel2,
  ContainerConteudo,
  ContainerFooter,
  ContainerInputsLogin,
  ContainerPainel,
  ContainerTelaLogin,
  ContainerTitlePainel,
  Footer,
  ImageWrapper,
  LoginButton,
  Painel2,
  SeePasswordIcon,
  SubTitulo,
  Titulo
} from './components_login';
import ForgotPasswordComponent from './forgot-password/components/forgot-password-component';
import SignUpComponent from './signup/components/signup-component';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

type LoginProps = {
  goToSignUpComponent: boolean;
};

function Login({ goToSignUpComponent }: LoginProps) {
  const navigate = useNavigate();
  const [Email, setEmail] = useState<string>('');
  const [Password, setPassword] = useState<string>('');
  const [Loading, setLoading] = useState<boolean>(false);
  const [ShowForgotPasswordComponent, setShowForgotPasswordComponent] = useState<boolean>(false);
  const [ShowSignUpComponent, setShowSignUpComponent] = useState<boolean>(goToSignUpComponent);
  const [ShowPassword, setShowPassword] = useState(false);

  async function envio() {
    setLoading(true);
    try {
      const resp = await useLoginExecute(Email, Password, '/auth/login');

      setLoading(false);
      localStorage.removeItem('popupClosed');
      if (resp.validCertificate) {
        navigate('/dashboard');
      } else {
        navigate('/cadastro');
      }
    } catch (error) {
      setLoading(false);
      if ((error as AxiosError).response?.status == 401) {
        ErrorToast.fire({
          text: 'Email ou senha incorretos!'
        });
      } else {
        ErrorToast.fire({
          text: 'Ocorreu um erro no servidor. Por favor, tente novamente.'
        });
      }
    }
  }

  return (
    <ContainerTelaLogin>
      <ContainerComponentsLogin>
        <ContainerPainel>
          <ContainerConteudo>
            <ContainerTitlePainel>
              <Titulo>Painel administrativo</Titulo>
              <SubTitulo>Business Shop Lite</SubTitulo>
            </ContainerTitlePainel>
          </ContainerConteudo>
          <ContainerFooter>
            <Footer>© 2024 Server Softwares para Varejo. Todos os direitos reservados.</Footer>
          </ContainerFooter>
        </ContainerPainel>

        <Painel2>
          <ContainerComponentsPainel2>
            <ConainterLogo>
              <ImageWrapper>
                <img src={LogoBshopLite} style={{ height: '100%', width: '100%' }}></img>
              </ImageWrapper>
            </ConainterLogo>
            <ContainerInputsLogin>
              {ShowSignUpComponent ? (
                <SignUpComponent onSignUpOperationFinished={() => setShowSignUpComponent(false)} />
              ) : ShowForgotPasswordComponent ? (
                <ForgotPasswordComponent onForgotPasswordOperationFinished={() => setShowForgotPasswordComponent(false)} />
              ) : (
                <form>
                  <Input
                    InputSize='medium'
                    value={Email}
                    placeholder='Email'
                    id='emailLogin'
                    type='text'
                    name='username'
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  ></Input>
                  <Input
                    InputSize='medium'
                    placeholder='Senha'
                    type={ShowPassword ? 'text' : 'password'}
                    name='password'
                    onChange={(e) => setPassword(e.target.value)}
                    marginTop='8px'
                    passwordInputElement={
                      <SeePasswordIcon onClick={() => setShowPassword(!ShowPassword)}>
                        {ShowPassword ? <AiFillEye fontSize={20} /> : <AiFillEyeInvisible fontSize={20} />}
                      </SeePasswordIcon>
                    }
                  ></Input>
                  <div style={{ marginTop: '10%' }}>
                    <Button
                      type='submit'
                      disabled={Loading}
                      width='100%'
                      color='white'
                      fontSize='16px'
                      marginLeft='0px'
                      onClick={() => envio()}
                    >
                      {Loading ? (
                        <span aria-label='LoadingContainer'>
                          <ClipLoader color={'#ffffff'} loading={Loading} size={20} />
                        </span>
                      ) : (
                        'Entrar'
                      )}
                    </Button>
                  </div>
                </form>
              )}

              <ContainerButtons>
                <LoginButton title='Recuperar senha' onClick={() => setShowForgotPasswordComponent(true)}>
                  {ShowForgotPasswordComponent || ShowSignUpComponent ? null : 'Esqueceu sua senha?'}
                </LoginButton>
              </ContainerButtons>
            </ContainerInputsLogin>
          </ContainerComponentsPainel2>
        </Painel2>
      </ContainerComponentsLogin>
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          right: '0',
          fontSize: '10px',
          color: '#fff',
          backgroundColor: '#000',
          padding: '5px',
          opacity: '0.5',
          fontFamily: 'Montserrat',
          borderRadius: '3px 0px 0px 0px'
        }}
      >
        {process.env.REACT_APP_VERSION}
      </div>
    </ContainerTelaLogin>
  );
}

export default Login;
