import { validate } from 'class-validator';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { ErrorToast } from '../../../../../common/alerts/custom-alert-error';
import { SuccessToast } from '../../../../../common/alerts/custom-alert-success';
import { ValidatePassword } from '../../../../../common/components/verify-password/utils/validate-password';
import { ClearString } from '../../../../../common/utils/clear-string';
import { useLoginExecute } from '../../../utils/use-login-execute';
import { VerifyCode } from '../../components/verify-code/utils/verify-code';
import VerifyCodeComponent from '../../components/verify-code/verify-code-component';
import { CompanyDataActions, CompanyDataState } from '../auxiliares/company-data';
import { InitialCompanyDataState } from '../auxiliares/initial-company-data';
import { SignupSteps } from '../auxiliares/signup-steps';
import { CreateCompany } from '../utils/create-company';
import { GenerateCodeAndSendEmailForSignup } from '../utils/generate-code-and-send-email-for-signup';
import { ValidatorSignup } from '../utils/validations/validator-signup';
import { VerifyAppTermOfUseExistence } from '../utils/verify-term-of-use-existence';
import SignUpFormComponent from './signup-form-component';
import { Color } from '../../../../../common/styles/themes';

interface Props {
  onSignUpOperationFinished: () => void;
}

function SignUpComponent(props: Props) {
  const navigate = useNavigate();
  const params = useParams();

  const currentSignupFlavor = params.flavor;

  if (!currentSignupFlavor) {
    navigate('/pagina-nao-encontrada');
  }

  const [CompanyData, setCompanyData] = useReducer(
    (state: CompanyDataState, action: CompanyDataActions) => {
      switch (action.type) {
        case 'email':
          return { ...state, email: action.payload };
        case 'cpfCnpj':
          return { ...state, cpfCnpj: action.payload };
        case 'name':
          return { ...state, name: action.payload };
        case 'phoneNumber':
          return { ...state, phoneNumber: action.payload };
        case 'password1':
          return { ...state, password1: action.payload };
        case 'password2':
          return { ...state, password2: action.payload };
        default:
          return state;
      }
    },
    { ...InitialCompanyDataState }
  );

  const [CompanyDataErrors, setCompanyDataErrors] = useState<CompanyDataState>({ ...InitialCompanyDataState });
  const [HiddenEmail, setHiddenEmail] = useState<string>('');
  const [expireTime, setExpireTime] = useState<number>(0);

  const [creatingCompanyLoading, setCreatingCompanyLoading] = useState<boolean>(false);
  const [SendingEmail, setSendingEmail] = useState<boolean>(false);
  const [verifyingTermOfUseLoading, setVerifiyngTermOfUseLoading] = useState<boolean>(false);

  const [signupStep, setSignupStep] = useState<SignupSteps>(SignupSteps.informRegisterData);

  useEffect(() => {
    getAppRegisterTermOfUse();
    const scriptText = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-16495794388');
  `;

    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16495794388';
    document.head.appendChild(scriptTag);

    const scriptTagInline = document.createElement('script');
    scriptTagInline.innerHTML = scriptText;
    document.head.appendChild(scriptTagInline);

    return () => {
      document.head.removeChild(scriptTag);
      document.head.removeChild(scriptTagInline);
    };
  }, []);

  async function getAppRegisterTermOfUse() {
    try {
      setVerifiyngTermOfUseLoading(true);

      const { termExists } = await VerifyAppTermOfUseExistence();

      if (!termExists) {
        throw new Error();
      }

      setVerifiyngTermOfUseLoading(false);
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao buscar termo de uso do BshopLite. Por favor, tente novamente!'
      });

      setVerifiyngTermOfUseLoading(false);

      props.onSignUpOperationFinished();
    }
  }

  async function validateSignupData(termsOfUseAccepted: boolean): Promise<boolean> {
    const validator = new ValidatorSignup({
      ...CompanyData,
      cpfCnpj: ClearString(CompanyData.cpfCnpj),
      phoneNumber: ClearString(CompanyData.phoneNumber)
    });

    const validationErrors = await validate(validator);

    const ErrorMessages = { ...InitialCompanyDataState };
    let hasErrors = false;

    if (validationErrors.length > 0) {
      for (let x = 0; x < validationErrors.length; x++) {
        const Messages = Object.values(validationErrors[x].constraints || {});
        ErrorMessages[validationErrors[x].property as keyof typeof ErrorMessages] = Messages[0] || 'Campo não preenchido corretamente';
      }
      hasErrors = true;
    }

    if (!ValidatePassword(CompanyData.password1)) {
      ErrorMessages.password1 = 'A senha não possui as condições necessárias!';
      hasErrors = true;
    }

    if (CompanyData.password1 != CompanyData.password2) {
      ErrorMessages.password2 = 'As senhas não coincidem!';
      hasErrors = true;
    }

    if (!termsOfUseAccepted) {
      hasErrors = true;
    }

    if (hasErrors) {
      setCompanyDataErrors({ ...ErrorMessages });
    }

    return !hasErrors;
  }

  async function ValidateDataAndVerifyUserEmail(termsOfUseAccepted: boolean) {
    setSendingEmail(true);

    try {
      const isCompanyDataValid = await validateSignupData(termsOfUseAccepted);

      if (!isCompanyDataValid) {
        return;
      }

      const { hiddenEmail, expirationTime } = await GenerateCodeAndSendEmailForSignup(CompanyData.email);

      setHiddenEmail(hiddenEmail);
      setExpireTime(expirationTime);
      setSignupStep(SignupSteps.verifyEmail);
    } catch (e) {
      ErrorToast.fire({
        text: 'Erro ao enviar e-mail! Por favor, tente novamente.'
      });
    } finally {
      setSendingEmail(false);
    }
  }

  async function verifyCode(code: string) {
    try {
      const resp = await VerifyCode({ code, email: CompanyData.email });
      const { token: newToken } = resp.data.data;

      await registerCompany(newToken);

      props.onSignUpOperationFinished();
    } catch (e) {
      ErrorToast.fire({
        text: 'Erro ao verificar código. Por favor, tente novamente!'
      });
    }
  }

  async function registerCompany(newToken: string) {
    try {
      setCreatingCompanyLoading(true);

      await CreateCompany({
        companyData: { ...CompanyData },
        token: newToken,
        flavor: currentSignupFlavor
      });

      SuccessToast.fire({
        text: 'Empresa cadastrada com sucesso!'
      });

      // Logar usuário
      await loginUserAutomatically();
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao cadastrar empresa. Por favor, tente novamente!'
      });
    } finally {
      setCreatingCompanyLoading(false);
    }
  }

  async function loginUserAutomatically() {
    try {
      await useLoginExecute(CompanyData.email, CompanyData.password1, '/auth/login');

      navigate('/dashboard');
    } catch (error) {
      ErrorToast.fire({
        text: 'Ocorreu um erro ao tentar redirecioná-lo para a tela de cadastro. Por favor, tente se logar normalmente.'
      });
    }
  }

  function renderInformRegisterData(): JSX.Element {
    return (
      <SignUpFormComponent
        flavor={currentSignupFlavor}
        handleSignup={ValidateDataAndVerifyUserEmail}
        handleSignupLoading={SendingEmail}
        companyData={CompanyData}
        setCompanyData={setCompanyData}
        companyDataErrors={CompanyDataErrors}
        setCompanyDataErrors={setCompanyDataErrors}
        onGoBack={props.onSignUpOperationFinished}
        onSignUpButtonPressed={({ hiddenEmail, expirationTime }) => {
          setHiddenEmail(hiddenEmail);
          setExpireTime(expirationTime);
          setSignupStep(SignupSteps.verifyEmail);
        }}
      />
    );
  }

  function renderVerifyCode(): JSX.Element {
    return (
      <VerifyCodeComponent
        codeExpirationTime={expireTime}
        userHiddenEmail={HiddenEmail}
        disableButtonTimer={120}
        generateNewCode={() => GenerateCodeAndSendEmailForSignup(CompanyData.email)}
        verifyToken={verifyCode}
        onGoBack={() => {
          setSignupStep(SignupSteps.informRegisterData);
        }}
      ></VerifyCodeComponent>
    );
  }

  function renderSignupStep() {
    if (creatingCompanyLoading || verifyingTermOfUseLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          <ClipLoader color={Color.primaryColorLigth} loading={true} size={70} />
        </div>
      );
    }

    switch (signupStep) {
      case SignupSteps.informRegisterData:
        return renderInformRegisterData();
      case SignupSteps.verifyEmail:
        return renderVerifyCode();
      default:
        return renderInformRegisterData();
    }
  }

  return renderSignupStep();
}

export default SignUpComponent;
