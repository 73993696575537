import styled from 'styled-components';
import { screenBreakPoint } from '../../../../../common/screen-break-points';

interface Props {
  TitleMarginBottom?: string;
}

export const SessionContainer = styled.form`
  height: auto;
  width: 95%;
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
  row-gap: 1ch;

  @media (max-width: 900px) {
    width: 92%;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    width: 78%;
    font-size: 21px;
    grid-column-start: 1;
    grid-column-end: 1;
    margin-top: 30px;
  }
`;

export const SessionTitle = styled.div<Props>`
  margin: 10px;
  grid-column-start: 1;
  grid-column-end: 3;
  font-family: 'Montserrat';
  font-size: 20px;
  color: ${(props) => props.theme.title};
  margin-bottom: ${(props) => props.TitleMarginBottom};

  @media (max-width: 1790px) {
    font-size: 20px;
    grid-column-start: 1;
    grid-column-end: 5;
  }

  @media (max-width: 1350px) {
    font-size: 20px;
    grid-column-start: 1;
    grid-column-end: 5;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 18px;
    grid-column-start: 1;
    grid-column-end: 5;
  }
`;

export const ContainerInputsSession = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 15px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    grid-template-columns: 1fr;
    margin-left: 11%;
    & h4 {
      display: none;
    }
  }
`;
