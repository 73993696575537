export const isYouTubeURL = (url: string): boolean => {
  const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=[\w-]+(&.*)?$/;
  return youtubePattern.test(url);
};

export const getYouTubeVideoID = (url: string): string | null => {
  const youtubeIDPattern = /v=([\w-]+)/;
  const match = url.match(youtubeIDPattern);

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
};
