import api from '../../../../../../api/api';

type VerifyCodeProps = {
  code: string;
} & ({ email: string; cpfCnpj?: string } | { email?: string; cpfCnpj: string });

export function VerifyCode({ code, cpfCnpj, email }: VerifyCodeProps): Promise<{ data: { data: { token: string } } }> {
  return api.post('/auth/token/verify', {
    cpfCnpj: cpfCnpj,
    email: email,
    token: code,
    generateNewToken: true
  });
}
