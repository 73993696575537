import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../../../../../common/components/input/input';
import { AttForm, FormType } from '../../../../../../redux/FormSlice';
import Session from '../session';
import { Color } from '../../../../../../common/styles/themes';

interface Props {
  ErrorMessageInputs: FormType;
  handlePFXValidation: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: JSX.Element;
}

function Cert(props: Props) {
  const dispatch = useDispatch();
  const form = useSelector((state: { form: { value: FormType } }) => state.form);
  const [IsChecking, setIsChecking] = useState<boolean>(false);

  function handlePassword() {
    if (IsChecking === true && form.value.certificatePassword.length > 0) {
      if (form.value.certificatePassword === form.value.PasswordConfirm) {
        return (
          <span
            style={{
              color: 'green',
              fontFamily: 'Montserrat'
            }}
          >
            Senhas iguais!
          </span>
        );
      } else {
        return (
          <span
            style={{
              color: Color.red,
              fontFamily: 'Montserrat'
            }}
          >
            Senhas diferentes!
          </span>
        );
      }
    }
  }

  return (
    <Session Title='Certificado digital'>
      {props.children}
      <h4></h4>
      <h4></h4>
      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Senha certificado'
        id='Password'
        type='password'
        value={form.value.certificatePassword}
        onChange={(e) => {
          dispatch(AttForm({ key: 'certificatePassword', value: e.target.value }));
          props.handlePFXValidation(e);
        }}
        ErrorMessage={props.ErrorMessageInputs.certificatePassword}
        autoComplete='off'
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        id='PasswordConfirm'
        placeholder='Confirmar senha do certificado'
        onBlur={() => {
          form.value.certificatePassword.length > 0 ? setIsChecking(true) : null;
        }}
        value={form.value.PasswordConfirm}
        type='password'
        onChange={(e) => {
          dispatch(AttForm({ key: 'PasswordConfirm', value: e.target.value }));
          props.handlePFXValidation(e);
        }}
        ErrorMessage={props.ErrorMessageInputs.PasswordConfirm}
      ></Input>

      <span></span>
      {handlePassword()}
    </Session>
  );
}

export default Cert;
