import { Dispatch, SetStateAction } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { PopupClosesButton, PopupContainer, PopupContentContainer, PopupOverlay } from './popup-styled';
import './popup-styled.ts';
import { Color } from '../../styles/themes';

type PopupProps = {
  trigger: boolean;
  setTrigger: Dispatch<SetStateAction<boolean>>;
  children: React.ReactElement;
  width: string;
  height: string;
};

const Popup: React.FC<PopupProps> = (props) => {
  return props.trigger ? (
    <PopupContainer>
      <PopupOverlay onClick={() => props.setTrigger(false)}></PopupOverlay>
      <PopupContentContainer width={props.width} height={props.height}>
        <>
          {props.children}
          <PopupClosesButton onClick={() => props.setTrigger(false)}>
            <AiOutlineClose size={16} color={Color.red} />
          </PopupClosesButton>
        </>
      </PopupContentContainer>
    </PopupContainer>
  ) : null;
};

export default Popup;
