import styled from 'styled-components';
import { screenBreakPoint } from '../common/screen-break-points';

export const BshopLiteContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 5vh 95vh;
  background: ${(props) => props.theme.background};

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    grid-template-rows: 11vh 89vh;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    grid-template-rows: 8vh 95vh;
  }
`;

export const BshopLiteContent = styled.div`
  height: 100%;
  flex: 32;
  overflow-y: auto;
`;

export const BshopLiteContentLoading = styled.div`
position: 'absolute',
width: '100%',
height: '100%',
backgroundColor: ${(props) => props.theme.backgroundLoading};,
zIndex: '30',
display: 'flex',
justifyContent: 'center',
alignItems: 'center'
`;
