let timer: ReturnType<typeof setTimeout>;

function debounce(func: () => void, timeout = 1200) {
  return (): void => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, timeout);
  };
}
export default debounce;
