import Swal from 'sweetalert2';
import { Color } from '../styles/themes';

export const ConfirmToast = Swal.mixin({
  icon: 'warning',
  title: 'Tem certeza?',
  showCancelButton: true,
  confirmButtonColor: Color.primaryColorLigth,
  cancelButtonColor: Color.red
});
