import styled from 'styled-components';

interface MenuOptionProps {
  isOptionActive?: boolean;
}

export const PagesContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 8px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const SidebarTile = styled.div<MenuOptionProps>`
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props.isOptionActive ? '#123e6b' : 'invisible')};
  color: #ffffff;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    background-color: #123e6b;

    color: #ffffff;
  }

  & > div > .menu-icon {
    color: #ffffff;
  }
`;

export const SidebarMenuCategoryLabel = styled.h5`
  align-self: start;

  margin-top: 2rem;
  margin-left: 10px;
  color: white;

  &:first-child {
    margin-top: 0;
  }
`;

export const SidebarMenuOption = styled.div`
  border-radius: 5px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const SidebarMenuOptionLabel = styled.div`
  flex: 3;
  font-weight: bold;
`;
