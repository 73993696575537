import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';

import NcmTreeView from './ncm-tree-view';
import { InfoToast } from '../../../../../../common/alerts/custom-alert-info';
import Popup from '../../../../../../common/components/popup/popup';
import debounce from '../../../../common/debounce';
import { NcmResponse } from '../../../../dto';
import { GetLastLayerNCMByFilter } from '../../../../utils/get-last-layer-ncm-by-filter';
import { Search, ButtonSearch, SuggestionCard, SuggestionList } from '../../../products/products-styled';
import { NCMIdentifier, NCMIcon, NCMName } from '../../register-or-update-products-styled';
import { mapperNCM } from '../../../../common/mapper/list-ncm.mapper';
import { Input } from '../../../../../../common/components/input/input';

interface NCMProps {
  NCMData: string;
  setNCMData: (ncmIdentifier: string) => void;
}

const SearchNCM: React.FC<NCMProps> = ({ setNCMData, NCMData }) => {
  const [NCMSuggestionsList, setNCMSuggestionsList] = useState<NcmResponse[]>([]);
  const [showSearchNCMPopup, setShowSearchNCMPopup] = useState(false);
  const [searchNcmSuggestionsLoading, setSearchNcmSuggestionsLoading] = useState(false);

  const [isSearchingSuggestionsByFilterChange, setIsSearchingSuggestionsByFilterChange] = useState(false);

  const onSelectNcmSuggestionNcm = (ncm: NcmResponse) => {
    setNCMData(ncm.identifier);
    setNCMSuggestionsList([]);
  };

  const ListLastLayerNCMByFilter = async () => {
    if (!isSearchingSuggestionsByFilterChange) return;
    if (!NCMData) return;

    try {
      setSearchNcmSuggestionsLoading(true);

      const suggestedNcms = await GetLastLayerNCMByFilter(NCMData);
      setNCMSuggestionsList(mapperNCM(suggestedNcms));
    } catch (error) {
      InfoToast.fire({
        text: 'Erro ao mostrar NCM. Por favor, tente novamente!'
      });
    } finally {
      setIsSearchingSuggestionsByFilterChange(false);
      setSearchNcmSuggestionsLoading(false);
    }
  };

  const onNcmDataChange = (ncm: string) => {
    setNCMData(ncm);
    setIsSearchingSuggestionsByFilterChange(true);

    if (!ncm) {
      setIsSearchingSuggestionsByFilterChange(false);
      setNCMSuggestionsList([]);
    }
  };

  useEffect(() => {
    res();
  }, [NCMData]);

  const res = debounce(() => ListLastLayerNCMByFilter());

  return (
    <div>
      <Search>
        <div style={{ width: '100%' }}>
          <Input
            InputSize='medium'
            value={NCMData}
            placeholder='Pesquisar NCM'
            type='text'
            name='ncm'
            onChange={(e) => onNcmDataChange(e.target.value)}
            floatingLabel={true}
            loading={searchNcmSuggestionsLoading}
          ></Input>
        </div>

        <ButtonSearch
          type='button'
          className='searchButton'
          onClick={() => {
            setShowSearchNCMPopup(true);
          }}
        >
          <BiSearch />
        </ButtonSearch>
      </Search>

      {NCMSuggestionsList.length > 0 && (
        <SuggestionCard>
          <SuggestionList>
            {NCMSuggestionsList.map((suggestionNcm) => (
              <NCMIdentifier key={suggestionNcm.identifier} onClick={() => onSelectNcmSuggestionNcm(suggestionNcm)}>
                <NCMIcon>{suggestionNcm.identifier}</NCMIcon>
                <NCMName> {suggestionNcm.description}</NCMName>
              </NCMIdentifier>
            ))}
          </SuggestionList>
        </SuggestionCard>
      )}

      <Popup width={'60%'} height={'90%'} setTrigger={setShowSearchNCMPopup} trigger={showSearchNCMPopup}>
        <NcmTreeView setNCMData={setNCMData} setShowSearchNCMPopup={setShowSearchNCMPopup} />
      </Popup>
    </div>
  );
};

export default SearchNCM;
