import api from '../../../../../api/api';

type GetAvailableTerminalsOutput = {
  availableTerminals: Array<string>;
};

export async function GetAvailableTerminals(): Promise<GetAvailableTerminalsOutput> {
  const resp = await api.get<{ data: Array<string> }>('/terminal/available');

  return { availableTerminals: resp.data.data };
}
