import { useCallback, useEffect } from 'react';

type UseFixMissingScrollProps<T> = {
  hasMoreItems: boolean;
  fetchMoreItems: (cameFromFiltersChange: boolean) => void;
  items: T[];
  element?: HTMLDivElement | null;
};

function useFixMissingScroll<T>({ hasMoreItems, fetchMoreItems, items, element }: UseFixMissingScrollProps<T>) {
  const fetchCb = useCallback(() => {
    fetchMoreItems(false);
  }, [fetchMoreItems]);

  useEffect(() => {
    const hasScroll = element ? element.scrollHeight > element.clientHeight + 400 : false;

    if (!hasScroll && hasMoreItems) {
      setTimeout(() => {
        fetchCb();
      }, 100);
    }
  }, [items]);
}

export default useFixMissingScroll;
