import { CompanyDataState } from './company-data';

export const InitialCompanyDataState: CompanyDataState = {
  name: '',
  cpfCnpj: '',
  phoneNumber: '',
  email: '',
  password1: '',
  password2: ''
};
