import React, { useState } from 'react';
import { InfoIcon, PopupContainer, PopupContent } from './styled';

interface IInfoPopup {
  text?: string;
}

const InfoPopup: React.FC<IInfoPopup> = ({ text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PopupContainer>
      <InfoIcon onClick={togglePopup} />
      <PopupContent isOpen={isOpen}>{text}</PopupContent>
    </PopupContainer>
  );
};

export default InfoPopup;
