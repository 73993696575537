export const SanitizeData = <TData extends object>(data: TData): TData => {
  const sanitizedData = Object.entries(data).reduce<TData>((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value || value === 0 || value === null ? value : undefined
    };
  }, {} as TData);

  return sanitizedData;
};
