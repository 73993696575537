import { useMoneyFormatter } from '../../../../common/utils/formatters/use-money-formatter';
import { VariationsType } from '../../dto';
import { VariationsRequestDto } from '../../dto/requests/variation-request.dto';
import { GetProductVariationResponse } from '../../dto/responses/get-product-response';

export const VariationRequestMapper = (variation: VariationsType): VariationsRequestDto => {
  return {
    ...variation,
    price: useMoneyFormatter(variation.price)
  };
};

export const VariationResponseMapper = (variation: GetProductVariationResponse): VariationsType => {
  return {
    ...variation,
    id: Number(variation.id),
    description: variation.variation
  };
};
