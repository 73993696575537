import Swal from 'sweetalert2';

export const SuccessToast = Swal.mixin({
  icon: 'success',
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  width: '460px',
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});
