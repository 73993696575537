import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast } from '../../../../../../common/alerts/custom-alert-error';
import { Input } from '../../../../../../common/components/input/input';
import { AttForm, FormType } from '../../../../../../redux/FormSlice';
import { useCEPmask } from '../../../../utils/masks/use-cep-mask';
import { useNumbermask } from '../../../../utils/masks/use-number-mask';
import { useSearchCEP } from '../../../../utils/use-search-cep';
import Session from '../session';

function Address(props: { ErrorMessageInputs: FormType }) {
  const dispatch = useDispatch();
  const form = useSelector((state: { form: { value: FormType } }) => state.form);
  const [CEPLoading, setCEPLoading] = useState<boolean>(false);
  const [isCityInformed, setIsCityInformed] = useState<boolean>(true);
  const [isNeighborhoodInformed, setIsNeighborhoodInformed] = useState<boolean>(true);
  const [isStateInformed, setIsStateInformed] = useState<boolean>(true);
  const [isStreetInformed, setIsStreetInformed] = useState<boolean>(true);
  const [isCodIbgeMunInformed, setIsCodIbgeMunInformed] = useState<boolean>(true);

  async function handleCEP(e: ChangeEvent<HTMLInputElement>) {
    const MaskCEP = useCEPmask(e.target.value);
    dispatch(AttForm({ key: 'zipcode', value: MaskCEP }));

    if (MaskCEP.length === 9) {
      setCEPLoading(true);
      try {
        const resp = await useSearchCEP(e.target.value);

        setIsCityInformed(Boolean(resp.data.data.city));
        setIsNeighborhoodInformed(Boolean(resp.data.data.neighborhood));
        setIsStateInformed(Boolean(resp.data.data.state));
        setIsStreetInformed(Boolean(resp.data.data.street));
        setIsCodIbgeMunInformed(Boolean(resp.data.data.codIbgeMun));

        dispatch(AttForm({ key: 'city', value: resp.data.data.city || '' }));
        dispatch(AttForm({ key: 'neighborhood', value: resp.data.data.neighborhood || '' }));
        dispatch(AttForm({ key: 'state', value: resp.data.data.state || '' }));
        dispatch(AttForm({ key: 'street', value: resp.data.data.street || '' }));
        dispatch(AttForm({ key: 'codIbgeMun', value: resp.data.data.codIbgeMun || '' }));

        setCEPLoading(false);
      } catch (error) {
        setCEPLoading(false);

        dispatch(AttForm({ key: 'city', value: '' }));
        dispatch(AttForm({ key: 'neighborhood', value: '' }));
        dispatch(AttForm({ key: 'state', value: '' }));
        dispatch(AttForm({ key: 'street', value: '' }));
        dispatch(AttForm({ key: 'codIbgeMun', value: '' }));

        ErrorToast.fire({
          text: 'Erro ao pesquisar o seu CEP! Por favor, tente novamente.'
        });
      }
    }
  }

  function handleNumber(e: ChangeEvent<HTMLInputElement>) {
    const MaskNumber = useNumbermask(e.target.value);
    dispatch(AttForm({ key: 'number', value: MaskNumber }));
  }

  return (
    <Session Title='Endereço'>
      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='CEP'
        type='text'
        value={form.value.zipcode}
        ErrorMessage={props.ErrorMessageInputs.zipcode}
        onChange={(e) => handleCEP(e)}
        loading={CEPLoading}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Rua'
        type='text'
        value={form.value.street}
        onChange={(e) => dispatch(AttForm({ key: 'street', value: e.target.value }))}
        disabled={isStreetInformed}
        ErrorMessage={props.ErrorMessageInputs.street}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        title='number field'
        value={form.value.number}
        placeholder='Número'
        type='text'
        onChange={(e) => handleNumber(e)}
        ErrorMessage={props.ErrorMessageInputs.number}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Estado'
        type='text'
        value={form.value.state}
        disabled={isStateInformed}
        onChange={(e) => dispatch(AttForm({ key: 'state', value: e.target.value }))}
        ErrorMessage={props.ErrorMessageInputs.state}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Cidade'
        value={form.value.city}
        type='text'
        onChange={(e) => dispatch(AttForm({ key: 'city', value: e.target.value }))}
        disabled={isCityInformed}
        ErrorMessage={props.ErrorMessageInputs.city}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Código IBGE'
        value={form.value.codIbgeMun}
        type='text'
        onChange={(e) => dispatch(AttForm({ key: 'codIbgeMun', value: e.target.value }))}
        disabled={isCodIbgeMunInformed}
        ErrorMessage={props.ErrorMessageInputs.codIbgeMun}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Bairro'
        type='text'
        value={form.value.neighborhood}
        onChange={(e) => dispatch(AttForm({ key: 'neighborhood', value: e.target.value }))}
        disabled={isNeighborhoodInformed}
        ErrorMessage={props.ErrorMessageInputs.neighborhood}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Complemento'
        autoComplete='off'
        type='text'
        value={form.value.complement}
        onChange={(e) => dispatch(AttForm({ key: 'complement', value: e.target.value }))}
      ></Input>
    </Session>
  );
}

export default Address;
