import { TerminalInfosContainer } from './terminal-infos-styled';

function TerminalInfos() {
  return (
    <TerminalInfosContainer>
      <h2>Informações:</h2>

      <br />

      <h3>O que é a página de 'Ativação de Terminais'?</h3>
      <br />
      <p>
        O BshopLite está disponível para os mais diversos dispositivos do mercado, incluindo as SmartsPOS (PDVs). No entanto, o modo de
        instalação do aplicativo difere de maquininha para maquininha. Em algumas, é possível acessar uma loja pública e instalá-lo
        normalmente. Em outras - como STONE e PAGSEGURO - é necessário uma ativação manual do terminal diretamente com os provedores do
        serviço. Desse modo, pensando no nosso usuário, implementamos um serviço de Ativação de Terminais próprio para o BshopLite.
      </p>

      <br />
    </TerminalInfosContainer>
  );
}

export default TerminalInfos;
