import React, { ReactElement } from 'react';
import { ContainerMenuOption, ContentMenuOption } from './header-styled';
interface props {
  Icon: ReactElement;
  onClick?: () => void;
  title?: string;
  children: React.ReactNode;
}
const MenuOption: React.FC<props> = ({ children, Icon, onClick, title }) => {
  return (
    <ContainerMenuOption onClick={onClick}>
      <ContentMenuOption>
        <div id='icon' title={title}>
          {Icon}
        </div>
        {children}
      </ContentMenuOption>
    </ContainerMenuOption>
  );
};
export default MenuOption;
