import { useState } from 'react';
import { ErrorToast } from '../../../../../common/alerts/custom-alert-error';
import { SuccessToast } from '../../../../../common/alerts/custom-alert-success';
import Button from '../../../../../common/components/button/button';
import { ValidatePassword } from '../../../../../common/components/verify-password/utils/validate-password';
import VerifyPasswordInputs from '../../../../../common/components/verify-password/verify-password-inputs';
import { UpdatePassword } from '../utils/update-password';
import { ButtonWrapper } from './forgot-password-styled-components';
interface Props {
  cpfCnpj: string;
  NewToken: string;
  onPasswordUpdated: () => void;
}

function NewPasswordInputsComponent(props: Props) {
  const [NewPassword, setNewPassword] = useState('');
  const [NewPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [UpdatingPassword, setUpdatingPassword] = useState<boolean>(false);
  const [ErrorPassword, setErrorPassword] = useState('');

  async function Send() {
    if (!ValidatePassword(NewPassword)) {
      setErrorPassword('A senha não possui as condições necessárias!');
      return;
    }

    if (NewPassword != NewPasswordConfirm) {
      setErrorPassword('As senhas não coincidem!');
      return;
    }

    setErrorPassword('');
    try {
      setUpdatingPassword(true);
      await UpdatePassword(props.cpfCnpj, NewPassword, props.NewToken);
      SuccessToast.fire({
        text: 'Senha alterada com sucesso!'
      });
      props.onPasswordUpdated();
    } catch (e) {
      ErrorToast.fire({
        text: 'Erro ao alterar a sua senha. Por favor, tente novamente!'
      });
    } finally {
      setUpdatingPassword(false);
    }
  }

  return (
    <div style={{ fontFamily: 'Montserrat', position: 'relative' }}>
      <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <VerifyPasswordInputs
          InputSize='medium'
          Password1={NewPassword}
          Password2={NewPasswordConfirm}
          setPassword1={setNewPassword}
          setPassword2={setNewPasswordConfirm}
          errorPassword1={ErrorPassword}
          setErrorPassword1={setErrorPassword}
        />
      </form>

      <ButtonWrapper>
        <Button
          title='update password button'
          width='45%'
          color='white'
          fontSize='92%'
          marginLeft='0%'
          onClick={() => Send()}
          disabled={UpdatingPassword}
        >
          Alterar senha
        </Button>
      </ButtonWrapper>
    </div>
  );
}

export default NewPasswordInputsComponent;
