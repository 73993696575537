import styled, { keyframes } from 'styled-components';
import { screenBreakPoint } from '../../../common/screen-break-points';
import { Link } from 'react-router-dom';
import { Color } from '../../../common/styles/themes';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #eff1f6;
  @media (max-width: 1280px) {
    height: fit-content;
  }
`;

export const HeaderTelaAdquirentes = styled.div`
  background-color: #004b90;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0px 3px;
`;

export const ContainerCarousel = styled.div`
  min-height: fit-content;
  max-width: 100vw;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    max-width: 99.2vw;
  }
`;

export const CarouselSlide = styled.div<{ currentSlide: number }>`
  display: flex;
  transform: translateX(-${(props) => props.currentSlide * 33.33}%);
  min-width: fit-content;
  justify-content: center;
  transition: transform 1s ease-in-out;
  overflow: hidden;
`;

export const SlideWeb = styled.div`
  display: none;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    display: flex;
    height: fit-content;
    width: 100vw;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      object-position: center;
      /* scale: 1;
      margin-top: -20px; */
    }
  }
  @media (min-width: 1280px) {
    img {
      width: 100vw;
      height: 320px;
      /* scale: 1;
      margin-top: 0; */
    }
  }
`;

export const SlideMobile = styled.div`
  display: flex;
  overflow: hidden;
  img {
    min-width: 100%;
    height: 150px;
    object-fit: cover;
    overflow: hidden;
    object-position: center;
  }
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    display: none;
  }
  @media (max-width: 500px) {
    display: flex;
    width: 100vw;
  }
`;

export const WrapperDots = styled.div`
  display: flex;
  justify-content: center;
`;
export const Dots = styled.div`
  position: absolute;
  gap: 10px;
  display: flex;
  justify-content: center;
`;

export const Dot = styled.span<{ isActive: boolean }>`
  cursor: pointer;
  position: relative;
  top: -1.5rem;
  height: 10px;
  width: 10px;
  border: 2px solid white;
  background-color: ${(props) => (props.isActive ? 'white' : 'transparent')};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  @media (min-width: 1024px) {
    height: 13px;
    width: 13px;
  }
`;

export const ContainerTelaAdquirentes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  padding: 3% 10%;
  & h2 {
    font-family: 'Montserrat-Variable';
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    text-align: left;
  }
  & strong {
    font-family: 'Montserrat-Variable';
    font-weight: 800;
  }
  @media (min-width: 1024px) {
    padding: 4% 10%;
    padding-bottom: 1%;
    gap: 2rem;
    flex-direction: row;
    & h2 {
      width: 80%;
      font-size: 33px;
      text-align: center;
    }
  }
`;

export const TextCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat-Variable';
  gap: 10px;
  width: 100%;
  letter-spacing: 1px;
  & h1 {
    width: 100%;
    font-size: 1.8rem;
    font-family: 'Montserrat-Variable';
    font-weight: 800;
    color: ${(props) => props.theme.primaryColor};
  }
  & p {
    width: 100%;
    font-weight: 700;
    font-size: 1rem;
    text-align: left;
  }
  & span {
    font-family: 'Montserrat-Variable';
    font-size: 1rem;
    font-weight: 900;
    color: black;
  }
  @media (min-width: 1024px) {
    justify-content: center;
    max-height: 310px;
    gap: 0px;
    align-items: center;
    & p {
      font-size: 1.1rem;
    }
    & h1 {
      font-size: 2.5rem;
      text-align: left;
    }
    & span {
      font-size: 1.2rem;
    }
  }
`;

export const ButtonToAdd = styled.button`
  margin-top: 15px;
  cursor: pointer;
  padding: 10px;
  background-color: #f2bf4e;
  font-family: 'Montserrat-Variable';
  font-size: 1rem;
  font-weight: 600;
  border: none;
  width: 240px;
  border-radius: 20px;
  color: #182020;
  &:hover {
    background-color: #ce961b;
  }
`;

export const ContainerCards = styled.div`
  width: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  flex-wrap: wrap;
  gap: 10px;
  display: flex;

  @media (min-width: 1024px) {
    padding-bottom: 50px;
  }
`;
export const CardAdquirente = styled(Link)`
  border-radius: 20px;
  border: 3px solid #d5e9eb;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  padding: 0px;
  height: 100px;
  width: 140px;
  &:hover {
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: none;
  }
  @media (min-width: 1024px) {
    height: 160px;
    width: 200px;
    padding: 10px;
  }
`;

export const AdquirenteImg = styled.img`
  width: 150px;
  @media (max-width: 1280px) {
    width: 100px;
  }
`;

export const ContainerFeatures = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
`;

export const FeaturesTitle = styled.h3`
  text-align: justify;
  color: #182020;
  padding: 0px 10%;
  font-family: 'Montserrat-Variable';
  font-size: 18px;
  line-height: 26px;
  strong {
    font-family: 'Montserrat-Variable';
    font-weight: 900;
    color: ${(props) => props.theme.primaryColor};
  }
  @media (min-width: 1024px) {
    padding: 0px 5%;
  }
  @media (min-width: 1280px) {
    padding: 0px 15%;
    font-size: 33px;
    font-weight: 800;
    line-height: 48px;
    text-align: center;
  }
`;

export const FeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 10%;
  gap: 20px;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    padding: 20px 10%;
    flex-direction: row;
    gap: 100px;
  }
`;

export const Features = styled.div<{ isActive: boolean }>`
  color: ${(props) => props.theme.primaryColor};
  background-color: ${(props) => (props.isActive ? '#d7e3f6' : '')};
  text-transform: uppercase;
  width: 85vw;
  /* padding: 20px; */
  height: 50px;
  font-size: 13px;
  cursor: pointer;
  border-bottom: 1px solid #d0d3d6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease-in;
  font-family: 'Montserrat-Variable', sans-serif;
  font-weight: 700;
  /* line-height: 50px; */
  &:after {
    content: none;
  }
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    max-width: 350px;
    padding: 10px;
    height: 50px;
    line-height: 50px;
    &:after {
      content: '';
      position: absolute;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-right: 25px solid transparent;
      border-left: 25px solid ${(props) => (props.isActive ? '#d7e3f6' : 'transparent')};
      right: -49px;
      transition: all 0.3s ease-in;
    }
  }
  @media (min-width: 1280px) {
    height: 70px;
    line-height: 70px;
    &:after {
      border-top: 35px solid transparent;
      border-bottom: 35px solid transparent;
      border-right: 25px solid transparent;
      border-left: 25px solid ${(props) => (props.isActive ? '#d7e3f6' : 'transparent')};
    }
  }
`;

export const ContainerCarouselFeatures = styled.div`
  width: fit-content;
  overflow: hidden;
`;

export const CarouselSlideFeatures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const FeatureSlide = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  height: fit-content;
  gap: 15px;
  max-width: 450px;
  img {
    height: auto;
    width: 100%;
    transition: transform 3s ease-in-out;
    animation: ${fadeIn} 3s;
    border-radius: 10px;
  }
  p {
    font-size: 15px;
    line-height: 28px;
  }
  @media (min-width: 1280px) {
    max-width: 750px;
    img {
      width: 100%;
      height: 500px;
    }
  }
`;

export const VideoCol = styled.div`
  height: 280px;
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    height: 500px;
    padding-right: 30px;
    :before {
      position: absolute;
      top: 20px;
      left: 0px;
      background-image: url(https://bshoplite.com.br/templates/img/fundo-capa-implantacao.png);
      width: 579px;
      height: 682px;
      content: '';
      display: block;
    }
  }
`;
export const FooterStyled = styled.footer`
  display: flex;
  width: 100%;
  background-color: ${Color.primaryColor};
  color: white;
  padding: 1% 2% 1% 2%;
  height: 100%;
  flex-direction: column;
`;
export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    flex-direction: row;
    gap: 0px;
  }
`;
export const FooterCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
  img {
    width: 150px;
  }
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    width: 33%;
  }
`;
export const FooterRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  small {
    width: 60%;
  }
`;
export const SocialIcons = styled.a`
  color: white;
`;
export const SocialMidiaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  align-self: center;
  gap: 5px;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    align-self: end;
  }
`;
export const SocialMidiaList = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const YoutubeVideo = styled.iframe`
  width: 426px;
  height: 240px;
  z-index: 1;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    width: 557px;
    height: 315px;
  }
`;
export const WrapperSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5% 7%;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    flex-direction: row;
    padding: 2% 11%;
  }
`;
export const AdvantagesList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    flex-direction: row;
  }
`;

export const AdvantagesCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: ${screenBreakPoint.mobileWidth}px) {
    gap: 2px;
  }
`;
export const AdvantageItem = styled.p`
  svg {
    width: 35px;
  }
  display: flex;
  align-items: center;
`;
