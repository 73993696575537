import { urlDownloadAppInstructions } from '../../../common/url-download-app-instructions';
import { Link, PopUpContainer, Wrapper } from './download-app-infos-styled';

function DownlaodAppInfos({ prop }: { prop?: string }) {
  const instruction = prop ? urlDownloadAppInstructions.find((item) => item.name === prop) : null;
  const instructionUrl = instruction ? instruction.url : '';

  return (
    <PopUpContainer>
      <h2>Ainda não acessou o app do BShopLite?</h2>

      <br />
      <Wrapper style={{ paddingTop: '6%' }}>
        <p>Siga as instruções para fazer o download do app no link: </p>
        <Link href={instructionUrl} target='_blank' rel='noopener noreferrer'>
          Clique aqui
        </Link>
      </Wrapper>
    </PopUpContainer>
  );
}

export default DownlaodAppInfos;
