export const usePhoneMask = (value: string | null) => {
  if (!value) return '';
  value = value.replace(/\D+/g, '');
  value = value.replace(/^0/, '');
  value = value.replace(/(\d{0})(\d)/, '$1($2');
  value = value.replace(/(\d{2})(\d)/, '$1)$2');
  if (value.length <= 12) {
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
    value = value.replace(/(-\d{4})\d+?$/, '$1');
  } else {
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
    value = value.replace(/(-\d{4})\d+?$/, '$1');
  }
  return value;
};
