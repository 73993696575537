import styled from 'styled-components';
import { screenBreakPoint } from '../../common/screen-break-points';

export const UpdatePasswordContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 4fr 1fr;
`;

export const HeaderStyle = styled.div`
  background-color: ${(props) => props.theme.primaryColor};
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  padding: 6px;
`;

export const FlexCenterAlignContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 4;
`;

export const InputsGridContainer = styled.div`
  height: 55%;
  width: 75%;
  display: grid;
  row-gap: 15px;
`;

export const UpdatePasswordContainerInputs = styled.div`
  height: 73%;
  width: 30vw;
  background-color: rgb(250, 250, 250);
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding-bottom: 15px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    width: 90vw;
  }

  @media (max-height: 600px) {
    margin-top: 50px;
  }
`;

export const FooterText = styled.div`
  font-family: 'Montserrat';
  font-size: 15px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 10px;
  }
`;

export const PopupPassword = styled.div`
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border: 1px solid #aaa;
  border-radius: 12px;
  background-color: #fff;
  width: 200px;
  height: 150px;
  -webkit-box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  position: relative;
  bottom: 200px;
  z-index: 10;
  left: calc(50% - 10px);

  :after {
    content: '';
    background-color: #fff;
    border-top: 2px solid #aaa;
    border-right: 2px solid #aaa;
    width: 20px;
    height: 20px;
    transform: rotate(-225deg);
    position: absolute;
    z-index: -1;
    top: 173px;
    left: calc(50% - 10px);
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    left: 10px;
  }
`;

export const Footer = styled.footer`
  background-color: #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 4;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    align-items: flex-start;
    padding-top: 40px;
  }
`;
