import React from 'react';
import { ErrorMessageDiv } from '../input/input-style';

import { PropsSelect } from './select-interface';
import { Container, SelectWrapper } from './select-style';

export const Select: React.FC<PropsSelect> = ({ onChange, label, labelBackgroundColor, children, ErrorMessage, value }) => {
  return (
    <div>
      <SelectWrapper ErrorMessage={ErrorMessage} labelBackgroundColor={labelBackgroundColor}>
        <Container value={value} onChange={onChange}>
          {children}
        </Container>
        <span>{label}</span>
      </SelectWrapper>
      {ErrorMessage && <ErrorMessageDiv>{ErrorMessage}</ErrorMessageDiv>}
    </div>
  );
};
