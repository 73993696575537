import {
  AdquirenteImg,
  CardAdquirente,
  Container,
  ContainerCards,
  ContainerTelaAdquirentes,
  HeaderTelaAdquirentes
} from './styled-components_adquirentes';
import Logo from './../../../images/logo-bshop-lite-header-branco.svg';
import { CarouselBanners } from './CarouselBanners/carousel-banners';
import { FeaturesWithImages } from './FeaturesWithImages/features-with-images';
import { VideoSection } from './VideoSection/videoSection';
import TiktokPixel from 'tiktok-pixel';
import { useEffect } from 'react';
import { adquirentesImages } from '../common/adquirentesImages';
import { FooterAdquirentes } from './FooterAdquirentes/FooterAdquirentes';

function TelaAdquirentes() {
  TiktokPixel.init('CO1BH23C77UEH42AB5MG');
  const contents = [
    {
      content_id: '123456',
      content_type: 'PainelWeb',
      content_name: 'BshopLiteWeb'
    }
  ];
  TiktokPixel.track('ClickButton', {
    value: '1',
    currency: 'BRL',
    contents: contents
  });

  TiktokPixel.track('AddToWishlist', {
    value: '1',
    currency: 'BRL',
    contents: contents
  });
  TiktokPixel.track('Contact', {
    value: '1',
    currency: 'BRL',
    contents: contents
  });

  useEffect(() => {
    const scriptTextGM = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16495794388');
    `;

    const scriptTagGM = document.createElement('script');
    scriptTagGM.async = true;
    scriptTagGM.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16495794388';
    document.head.appendChild(scriptTagGM);

    const scriptTagInline = document.createElement('script');
    scriptTagInline.innerHTML = scriptTextGM;
    document.head.appendChild(scriptTagInline);

    const scriptTextFB = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1160246435337265');
    fbq('track', 'PageView');
`;
    const scriptTagFB = document.createElement('script');
    scriptTagFB.async = true;
    scriptTagFB.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(scriptTagFB);
    const scriptTagInlineFB = document.createElement('script');
    scriptTagInlineFB.innerHTML = scriptTextFB;
    document.head.appendChild(scriptTagInlineFB);

    return () => {
      document.head.removeChild(scriptTagGM);
      document.head.removeChild(scriptTagInline);

      document.head.removeChild(scriptTagFB);
      document.head.removeChild(scriptTagInlineFB);
    };
  }, []);

  return (
    <Container>
      <HeaderTelaAdquirentes>
        <img src={Logo} style={{ height: '50px', padding: '2px' }}></img>
      </HeaderTelaAdquirentes>
      <VideoSection />
      <div id={'adquirentes'}>
        <CarouselBanners />
        <ContainerTelaAdquirentes>
          <h2>
            Escolha sua adquirente/máquina abaixo, <strong>crie sua conta de usuário do aplicativo</strong> e comece a vender agora mesmo!
          </h2>
          <ContainerCards>
            {adquirentesImages.map((adquirente, index) => {
              return (
                <CardAdquirente to={`/${adquirente.nome}/cadastre-sua-conta`} key={index}>
                  <AdquirenteImg src={adquirente.imagem} />
                </CardAdquirente>
              );
            })}
          </ContainerCards>
        </ContainerTelaAdquirentes>
      </div>
      <FeaturesWithImages />
      <FooterAdquirentes />
    </Container>
  );
}

export default TelaAdquirentes;
