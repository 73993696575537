import styled from 'styled-components';
import { screenBreakPoint } from '../../screen-break-points';

export const PopupPassword = styled.div`
  padding: 20px 10px 16px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #aaa;
  border-radius: 12px;
  background-color: #fff;
  width: 200px;
  -webkit-box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  position: relative;
  bottom: 130px;
  z-index: 10;
  left: calc(50% - 10px);

  :after {
    content: '';
    background-color: #fff;
    border-top: 2px solid #aaa;
    border-right: 2px solid #aaa;
    width: 20px;
    height: 20px;
    transform: rotate(-225deg);
    position: absolute;
    z-index: -1;
    top: 173px;
    left: calc(50% - 10px);
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    left: 10px;
  }
`;

interface passwordPopupConditionsProps {
  validCondition: boolean;
}

export const PasswordCondition = styled.div<passwordPopupConditionsProps>`
  width: fit-content;
  font-weight: 600;
  font-size: clamp(8px, 0.9rem, 14px);
  color: ${(passwordPopupConditionsProps) => (passwordPopupConditionsProps.validCondition ? 'green' : 'red')};
  display: flex;
  word-break: break-word;
  gap: 10px;
`;
export const ContainerPasswordCondition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 10px;
`;

export const ClosePopUp = styled.p`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  &:hover {
    transform: scale(1.15);
  }
`;
