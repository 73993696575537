import styled from 'styled-components';

export const PopupContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 100;
`;

export const PopupOverlay = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: rgba(33, 30, 30, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
`;

interface PopupContentContainerProps {
  width: string;
  height: string;
}

export const PopupContentContainer = styled.div<PopupContentContainerProps>`
  min-width: 300px;
  min-height: 360px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: 90%;
  max-height: 80%;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background-color: #f5f5f5;
  padding: 20px 30px;
  border-radius: 14px;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.background};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 200;
`;

export const PopupClosesButton = styled.div`
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 1px solid #d66851;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transition: 0.4s;
  }
`;
