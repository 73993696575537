import { useState } from 'react';
import { FeaturesConst } from '../../common/adquirentesTexts';
import {
  CarouselSlideFeatures,
  ContainerCarouselFeatures,
  ContainerFeatures,
  FeatureSlide,
  Features,
  FeaturesTitle,
  FeaturesWrapper
} from '../styled-components_adquirentes';

export const FeaturesWithImages = () => {
  const [currentSlideFeatures, setCurrentSlideFeatures] = useState(0);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
      <ContainerFeatures>
        <FeaturesTitle>
          O BShopLite é simples, prático e traz as principais funções de <strong>gestão da sua loja</strong> para a palma da sua mão:
        </FeaturesTitle>

        <FeaturesWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
            {FeaturesConst.map((feature, index) => {
              return (
                <Features
                  key={index}
                  onClick={() => {
                    setCurrentSlideFeatures(index),
                      window.scrollTo({
                        left: 0,
                        top: document.body.scrollHeight,
                        behavior: 'smooth'
                      });
                  }}
                  isActive={currentSlideFeatures === index}
                >
                  {feature.title}
                </Features>
              );
            })}
          </div>
          <ContainerCarouselFeatures>
            <CarouselSlideFeatures>
              {FeaturesConst.map((feature, index) => {
                return (
                  <FeatureSlide key={index} isActive={currentSlideFeatures === index}>
                    <img src={feature.img} />
                  </FeatureSlide>
                );
              })}
            </CarouselSlideFeatures>
          </ContainerCarouselFeatures>
        </FeaturesWrapper>
      </ContainerFeatures>
    </div>
  );
};
