import React, { useEffect, useState } from 'react';
import DashboardCardIndicator from './components/card-indicator/card-indicator';
import {
  ContainerChartsComponents,
  DashboardCenteredComponentsContainer,
  DashboardComponentsContainer,
  DashboardPage,
  DashboardSelectPeriod,
  IndicatorsContainer,
  SelectOption
} from './dashboard-styled-components';
import MonthlySales from './components/charts/monthly-sales';
import SalesByPaymentType from './components/charts/sales-by-payment-type';
import SalesByHour from './components/charts/sales-hour';
import TopProducts from './components/charts/top-products';
import TemplateBshopLite from '../../../template/template';
import { IndicatorsList } from '../common/indicators-list';
import { SubTitle } from '../../../common/styles/global';

function Dashboard() {
  const [period, setPeriod] = useState<string>('DAY');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowWHeight, setWindowWHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowWHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function HandlePeriodChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setPeriod(e.target.value);
  }

  return (
    <TemplateBshopLite>
      <DashboardPage>
        <DashboardComponentsContainer>
          <DashboardCenteredComponentsContainer>
            <div style={{ width: '100%', marginTop: '25px', marginBottom: '30px', fontFamily: 'Montserrat' }}>
              <SubTitle>
                <h5>Período</h5>
              </SubTitle>

              <DashboardSelectPeriod onChange={(e) => HandlePeriodChange(e)}>
                <SelectOption value='DAY'>Hoje</SelectOption>
                <SelectOption value='LAST7DAYS'>Últimos 7 dias</SelectOption>
                <SelectOption value='LAST30DAYS'>Últimos 30 dias</SelectOption>
                <SelectOption value='MONTH'>Este mês</SelectOption>
                <SelectOption value='YEAR'>Este ano</SelectOption>
              </DashboardSelectPeriod>
            </div>

            <div></div>
            <IndicatorsContainer>
              {IndicatorsList.map((Indicator) => {
                return (
                  <DashboardCardIndicator
                    isMoney={Indicator.isMoney}
                    path={Indicator.path}
                    period={period}
                    title={Indicator.title}
                    key={Indicator.key}
                    name={Indicator.name}
                  ></DashboardCardIndicator>
                );
              })}
            </IndicatorsContainer>
            <ContainerChartsComponents id='ContainerChartsComponents'>
              <MonthlySales period={period} windowWidth={windowWidth} windowWHeight={windowWHeight}></MonthlySales>
              <SalesByHour period={period} windowWidth={windowWidth} windowWHeight={windowWHeight}></SalesByHour>
              <SalesByPaymentType period={period} windowWidth={windowWidth}></SalesByPaymentType>
              <TopProducts period={period}></TopProducts>
            </ContainerChartsComponents>
          </DashboardCenteredComponentsContainer>
        </DashboardComponentsContainer>
      </DashboardPage>
    </TemplateBshopLite>
  );
}

export default Dashboard;
