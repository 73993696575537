import { useState, useEffect, useRef } from 'react';
import { BsFolderFill } from 'react-icons/bs';
import { RiArrowDropUpLine, RiArrowDropDownLine } from 'react-icons/ri';
import { InfoToast } from '../../../../../../common/alerts/custom-alert-info';
import { deepCopy } from '../../../../../../common/utils/deep-copy';
import { NcmResponse, ListNcmResponse } from '../../../../dto';
import { GetLastLayerNCMByFilter } from '../../../../utils/get-last-layer-ncm-by-filter';
import { GetNCMChildren } from '../../../../utils/get-ncm-children';
import { GetNCMTreeView } from '../../../../utils/get-ncms-tree-view';
import { InfoTitle } from '../../../product-details/products-details-styled';
import {
  TreeContainer,
  TreeCard,
  NCMIdentifier,
  NCMIcon,
  NCMName,
  TreeIdentifier,
  ArrowIcon
} from '../../register-or-update-products-styled';
import { mapperNCM } from '../../../../common/mapper/list-ncm.mapper';
import { Input } from '../../../../../../common/components/input/input';
import debounce from '../../../../common/debounce';

const NcmTreeView: React.FC<{
  setNCMData: (ncmIdentifier: string) => void;
  setShowSearchNCMPopup: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setNCMData, setShowSearchNCMPopup }) => {
  const [NCMList, setNCMList] = useState<NcmResponse[]>([]);
  const [NCMSaveList, setNCMSaveList] = useState<NcmResponse[]>([]);

  const [filterNCM, setfilterNCM] = useState('');
  const isFirstRender = useRef(true);

  const [isSearchingByFilterChange, setIsSearchingByFilterChange] = useState(false);

  useEffect(() => {
    ListNCMTreeView();
  }, []);

  const onSelectFinalLayerNCM = (ncm: ListNcmResponse) => {
    setShowSearchNCMPopup(false);
    setNCMData(ncm.identifier);
  };

  const handleFilterChange = async () => {
    if (filterNCM === '') {
      setNCMList(deepCopy(NCMSaveList));
    } else {
      try {
        setIsSearchingByFilterChange(true);

        const res = await GetLastLayerNCMByFilter(filterNCM);

        setNCMList(mapperNCM(res));
      } catch {
        InfoToast.fire({
          text: 'Erro ao mostrar NCM. Por favor, tente novamente!'
        });
      } finally {
        setIsSearchingByFilterChange(false);
      }
    }
  };

  const debouceHandleFilterChange = debounce(handleFilterChange);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    debouceHandleFilterChange();
  }, [filterNCM]);

  const ListNCMTreeView = async () => {
    const res = await GetNCMTreeView();

    setNCMList(mapperNCM(res));
    setNCMSaveList(deepCopy(mapperNCM(res)));
  };

  const addChildrenItem = async (ncm: NcmResponse) => {
    ncm.isExpanded = !ncm.isExpanded;

    setNCMList([...NCMList]);
    setNCMSaveList([...NCMSaveList]);

    if (!ncm.isExpanded) {
      return;
    }

    if (ncm.children.length > 0) {
      return;
    }

    const queryParams = new URLSearchParams({
      parentId: ncm.identifier
    }).toString();

    const childrens = await GetNCMChildren(queryParams);

    ncm.children = mapperNCM(childrens);

    setNCMList([...NCMList]);
    setNCMSaveList([...NCMSaveList]);
  };

  const renderTree = (ncmList: NcmResponse[]) => {
    return ncmList.map((ncm) => (
      <TreeContainer key={ncm.identifier}>
        <TreeCard>
          {ncm.isLastLayer === true ? (
            <div onClick={() => onSelectFinalLayerNCM(ncm)}>
              <NCMIdentifier>
                <NCMIcon>{ncm.identifier}</NCMIcon>
                <NCMName> {ncm.description}</NCMName>
              </NCMIdentifier>
            </div>
          ) : (
            <div>
              <TreeIdentifier isExpanded={ncm.isExpanded} onClick={() => addChildrenItem(ncm)}>
                <NCMName>
                  <BsFolderFill size={13} color='#7d7d7d' /> {ncm.identifier + '  -' + ncm.description}
                </NCMName>
                <ArrowIcon>{ncm.isExpanded ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}</ArrowIcon>
              </TreeIdentifier>

              {ncm.isExpanded && ncm.children.length > 0 && <div>{renderTree(ncm.children)}</div>}
            </div>
          )}
        </TreeCard>
      </TreeContainer>
    ));
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <InfoTitle>Faça uma busca por NCM ou selecione uma camada:</InfoTitle>

      <Input
        InputSize='medium'
        value={filterNCM}
        placeholder='Pesquisar NCM'
        type='text'
        name='ncm'
        onChange={(e) => setfilterNCM(e.target.value)}
        floatingLabel={true}
        loading={isSearchingByFilterChange}
      ></Input>

      <div style={{ width: '100%', height: '80%', marginTop: '15px', overflowY: 'scroll' }}>
        {NCMList.length > 0 && renderTree(NCMList)}
      </div>
    </div>
  );
};

export default NcmTreeView;
