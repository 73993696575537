import styled from 'styled-components';

export const ContainerTermosDeUso = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const HeaderTermosDeUso = styled.div`
  height: 80px;
  width: 100%;
  background-color: ${(props) => props.theme.primaryColor};
`;

export const ContainerConteudoTermosDeUso = styled.div`
  height: 100%;
  width: 90%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.8rem;

  border: 1px solid #aaa;
  border-radius: 0.4rem;
`;

interface DarkThemeInterface {
  isDarkTheme: boolean;
}

export const ContainerTituloTermosDeUso = styled.h2<DarkThemeInterface>`
  align-self: center;
  background: ${(props) => (props.isDarkTheme ? 'linear-gradient(90deg, #0090ee, #00f0ff)' : 'linear-gradient(90deg, #004b73, #00b9bf)')};
  font-family: 'Montserrat';
  background-clip: border-box;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ContainerCorpoTermosDeUso = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
`;
