import { ProductSearchEntity } from '../../dto';
import { GetProductResponse } from '../../dto/responses/get-product-response';
import { ImageMapper } from './image.mapper';
import { VariationResponseMapper } from './variation.mapper';

export const ProductSearchMapper = (product: GetProductResponse): ProductSearchEntity => {
  return {
    ...product,
    id: Number(product.id),
    description: product.name,
    defaultPrice: Number(product.defaultPrice),
    Images: product.Images.map(ImageMapper),
    ProductVariation: product.ProductVariation.map(VariationResponseMapper),
    aliquotaIcms: product.fiscalProductData?.aliquotaIcms ? Number(product.fiscalProductData?.aliquotaIcms) : null,
    aliquotaPis: product.fiscalProductData?.aliquotaPis ? Number(product.fiscalProductData?.aliquotaPis) : null,
    aliquotaCofins: product.fiscalProductData?.aliquotaCofins ? Number(product.fiscalProductData?.aliquotaCofins) : null,
    aliquotaIpi: product.fiscalProductData?.aliquotaIpi ? Number(product.fiscalProductData?.aliquotaIpi) : null,
    margemValorAgregado: product.fiscalProductData?.margemValorAgregado ? Number(product.fiscalProductData?.margemValorAgregado) : null,
    cfop: product.fiscalProductData?.cfop ?? null,
    cstPis: product.fiscalProductData?.cstPis ?? null,
    cstIcms: product.fiscalProductData?.cstIcms ?? null,
    cstCofins: product.fiscalProductData?.cstCofins ?? null,
    origem: product.fiscalProductData?.origem ?? null,
    idxReducaoBaseCalculo: product.fiscalProductData?.idxReducaoBaseCalculo
      ? Number(product.fiscalProductData?.idxReducaoBaseCalculo)
      : null,
    codBeneficioFiscal: product.fiscalProductData?.codBeneficioFiscal ?? null,
    codEspecificadorSubstTribCest: product.fiscalProductData?.codEspecificadorSubstTribCest ?? null
  };
};
