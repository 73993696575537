import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import { GetAvailableTerminals } from '../pages/login/view/signup/utils/get-available-terminals';
import { Color } from '../common/styles/themes';

const SignupRouteMiddleware = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [isValidFlavor, setIsValidFlavor] = useState<boolean>(false);

  useEffect(() => {
    (async function validateUrlFlavor() {
      try {
        const currentFlavor = params.flavor;
        if (!currentFlavor) throw new Error('Flavor not found');

        const { availableTerminals } = await GetAvailableTerminals();

        if (availableTerminals.includes(currentFlavor?.toLocaleLowerCase())) {
          setIsValidFlavor(true);
        } else {
          throw new Error('Flavor not found');
        }
      } catch (e) {
        navigate('/pagina-nao-encontrada');
      }
    })();
  }, [location]);

  if (isValidFlavor) {
    return <Outlet></Outlet>;
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <ClipLoader color={Color.primaryColorLigth} loading={true} size={70} />
      </div>
    );
  }
};

export default SignupRouteMiddleware;
