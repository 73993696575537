import styled from 'styled-components';

export const RegisterTerminalForm = styled.form`
  width: 100%;
  height: 100%;

  padding: 1.4rem 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RegisterTerminalFormInputsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const RegisterTerminalFormButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
