import Cielo from '../../../images/adquirentes/cielo.png';
import GetNet from '../../../images/adquirentes/getnet.png';
import PagSeguro from '../../../images/adquirentes/pag-seguro.png';
import Rede from '../../../images/adquirentes/rede.png';
import Stone from '../../../images/adquirentes/stone.png';
import Vero from '../../../images/adquirentes/vero.png';
export const adquirentesImages = [
  {
    nome: 'cielo',
    imagem: Cielo
  },
  {
    nome: 'redecard',
    imagem: Rede
  },
  {
    nome: 'vero',
    imagem: Vero
  },

  {
    nome: 'pagseguro',
    imagem: PagSeguro
  },

  {
    nome: 'stone',
    imagem: Stone
  },
  {
    nome: 'getnet',
    imagem: GetNet
  }
];
