import * as AiIcons from 'react-icons/ai';
import { ClosePopUp, ContainerPasswordCondition, PasswordCondition, PopupPassword } from './popup-password-styled-components';
import { hasLetterRegExp, hasNumberRegExp, hasSpaceRegEXp } from './utils/validate-password';

interface PopUpProps {
  Password: string;
  closePopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

function PasswordPopUp(props: PopUpProps) {
  return (
    <div style={{ position: 'absolute' }}>
      <PopupPassword>
        <ClosePopUp onClick={() => props.closePopUp(false)}>X</ClosePopUp>
        <span style={{ fontWeight: '600' }}>Senha deve incluir:</span>

        <ContainerPasswordCondition>
          <PasswordCondition validCondition={props.Password.length >= 8}>
            <div>{props.Password.length >= 8 ? <AiIcons.AiFillCheckCircle /> : <AiIcons.AiFillCloseCircle />}</div>
            <span>8 caracteres</span>
          </PasswordCondition>
          <PasswordCondition validCondition={hasLetterRegExp.test(props.Password)}>
            <div>{hasLetterRegExp.test(props.Password) ? <AiIcons.AiFillCheckCircle /> : <AiIcons.AiFillCloseCircle />}</div>
            <span>Pelo menos uma letra</span>
          </PasswordCondition>
          <PasswordCondition validCondition={hasNumberRegExp.test(props.Password)}>
            <div>{hasNumberRegExp.test(props.Password) ? <AiIcons.AiFillCheckCircle /> : <AiIcons.AiFillCloseCircle />}</div>
            <span>Pelo menos um número</span>
          </PasswordCondition>
          <PasswordCondition validCondition={!hasSpaceRegEXp.test(props.Password)}>
            <div>{!hasSpaceRegEXp.test(props.Password) ? <AiIcons.AiFillCheckCircle /> : <AiIcons.AiFillCloseCircle />}</div>
            <span>Nenhum espaço</span>
          </PasswordCondition>
        </ContainerPasswordCondition>
      </PopupPassword>
    </div>
  );
}

export default PasswordPopUp;
