import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { ConfirmToast } from '../../../../common/alerts/custom-alert-confirm';
import { ErrorToast } from '../../../../common/alerts/custom-alert-error';
import { SuccessToast } from '../../../../common/alerts/custom-alert-success';
import ButtonOutline from '../../../../common/components/button-outline/button-outline';
import Button from '../../../../common/components/button/button';
import { FlexCenteredDivFhFw } from '../../../../common/components/common-styled-components';
import TemplateBshopLite from '../../../../template/template';
import { ProductFormTabs } from '../../common/enum/product-form-tabs';
import { ProductSearchMapper } from '../../common/mapper/product-search.mapper';
import { UseMoneyMask } from '../../common/mask/use-money-mask';
import { InitialStateProduct } from '../../common/state';
import { ProductSearchEntity } from '../../dto';
import { DeleteProduct } from '../../utils/delete-product';
import { GetProduct } from '../../utils/get-product';
import ImageSelector from '../register-or-update/components/image-selector/image-selector';
import { TabLabel, TabsContentContainer, TabsLabelContainer } from '../register-or-update/register-or-update-products-styled';
import {
  Col,
  FixedCol,
  Grid,
  Header,
  ProductName,
  ProductPrice,
  ScrollableCol,
  InfoSubtitle,
  Tags,
  TagsRounded,
  InfoTitle,
  TributaryData,
  TributaryInfo,
  TributaryRow,
  Variation,
  VariationRounded
} from './products-details-styled';
import { Title } from '../../../../common/styles/global';
import { useTheme } from '../../../../common/theme/theme-context';
import { Color } from '../../../../common/styles/themes';

function ProductDetails() {
  const { theme } = useTheme();

  const { id } = useParams();
  const navigate = useNavigate();
  const updateProduct = () =>
    navigate({
      pathname: '/register-or-update-product',
      search: '?id=' + id
    });
  const [product, setproduct] = useState<ProductSearchEntity>(InitialStateProduct);
  const [loading, setLoading] = useState(false);
  const [selectedFormTab, setSelectedFormTab] = useState<ProductFormTabs>(ProductFormTabs.detailsTab);

  useEffect(() => {
    getProduct();
  }, []);

  async function getProduct() {
    try {
      if (!id) return;

      setLoading(true);

      const productData = await GetProduct(id);

      setproduct(ProductSearchMapper(productData));
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao buscar Produto. Por favor, tente novamente!'
      });
    } finally {
      setLoading(false);
    }
  }

  const deleteProduct = async () => {
    const result = await ConfirmToast.fire({
      text: 'Você deseja apagar este produto?',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      color: theme === 'dark' ? '#EDF5FD' : '#343239',
      background: theme === 'dark' ? '#0A1623' : '#fff'
    });

    if (result.isConfirmed) {
      if (!id) return;

      try {
        await DeleteProduct(id);

        SuccessToast.fire({
          text: 'Produto removido com sucesso!'
        });
        navigate('/products');
      } catch (e) {
        if ((e as AxiosError).response?.status == 422) {
          ErrorToast.fire({
            title: 'Erro!',
            text: 'Não é possível excluir um produto que já foi vendido.'
          });
        }
      }
    }
  };

  const fiscalProductData = [
    {
      label: 'Alíquota ICSM (%)',
      field: product.aliquotaIcms ? product.aliquotaIcms : '-'
    },
    {
      label: 'Alíquota PIS (%)',
      field: product.aliquotaPis ? product.aliquotaPis : '-'
    },
    {
      label: 'Alíquota COFINS (%)',
      field: product.aliquotaCofins ? product.aliquotaCofins : '-'
    },
    {
      label: 'Alíquota IPI (%)',
      field: product.aliquotaIpi ? product.aliquotaIpi : '-'
    },
    {
      label: 'Código de situação Tributário do ICSM',
      field: product.cstIcms ? product.cstIcms : '-'
    },
    {
      label: 'Origem',
      field: product.origem ? product.origem : '-'
    },
    {
      label: 'Código de situação Tributário do PIS',
      field: product.cstPis ? product.cstPis : '-'
    },
    {
      label: 'Código de situação Tributário da COFINS',
      field: product.cstCofins ? product.cstCofins : '-'
    },
    {
      label: 'Código Fiscal de Operações e Prestações',
      field: product.cfop ? product.cfop : '-'
    },
    {
      label: 'Índice Redução Base de cálculo (%)',
      field: product.idxReducaoBaseCalculo ? product.idxReducaoBaseCalculo : '-'
    },
    {
      label: 'Código Benefício Físcal',
      field: product.codBeneficioFiscal ? product.codBeneficioFiscal : '-'
    },
    {
      label: 'Código Especificador Subst. Trib. CEST',
      field: product.codEspecificadorSubstTribCest ? product.codEspecificadorSubstTribCest : '-'
    },
    {
      label: 'Margem Valor Agregado (%)',
      field: product.margemValorAgregado ? product.margemValorAgregado : '-'
    }
  ];

  return (
    <TemplateBshopLite>
      {loading ? (
        <FlexCenteredDivFhFw title='loading icon'>
          <ClipLoader color={Color.primaryColorLigth} loading={!loading} size={30} />
        </FlexCenteredDivFhFw>
      ) : (
        <div>
          <Grid>
            <Col />
            <Col>
              <Header>
                <Title>Detalhes do produto</Title>
              </Header>
            </Col>

            <Col>
              <Header>
                <ButtonOutline onClick={updateProduct} width={'150px'} fontSize={''} marginLeft={''}>
                  Editar Produto
                </ButtonOutline>

                <Button
                  style={{ backgroundColor: Color.red }}
                  color={'#fff'}
                  onClick={deleteProduct}
                  width={'150px'}
                  fontSize={''}
                  marginLeft={''}
                >
                  Excluir Produto
                </Button>
              </Header>
            </Col>
          </Grid>

          <Grid>
            <Col>
              <FixedCol>
                <ImageSelector images={product.Images} isEditingOrRegistering={false} productId={product.id} />
              </FixedCol>
            </Col>

            <Col>
              <ScrollableCol>
                <TabsLabelContainer>
                  <TabLabel
                    isActive={selectedFormTab === ProductFormTabs.detailsTab}
                    onClick={() => setSelectedFormTab(ProductFormTabs.detailsTab)}
                  >
                    Detalhes
                  </TabLabel>
                  <TabLabel
                    isActive={selectedFormTab === ProductFormTabs.fiscalTab}
                    onClick={() => setSelectedFormTab(ProductFormTabs.fiscalTab)}
                  >
                    Informações fiscais
                  </TabLabel>
                </TabsLabelContainer>

                <div>
                  {selectedFormTab === ProductFormTabs.detailsTab ? (
                    <TabsContentContainer>
                      <div>
                        <ProductName>
                          <h3>{product.name}</h3>
                        </ProductName>
                        <InfoTitle>NCM: {product.ncm ? product.ncm : 'Nenhum'}</InfoTitle>
                        <ProductPrice>{UseMoneyMask(product.defaultPrice ? product.defaultPrice.toString() : '0')}</ProductPrice>
                        {product.reference ? (
                          <>
                            <InfoTitle>Referência</InfoTitle>
                            <InfoSubtitle>{product.reference}</InfoSubtitle>
                          </>
                        ) : (
                          <></>
                        )}
                        <InfoTitle>Tags</InfoTitle>
                        {product.tags.length > 0 ? (
                          <Tags>
                            {product.tags.map((tags, index: number) => (
                              <TagsRounded key={index}>{tags.name}</TagsRounded>
                            ))}
                          </Tags>
                        ) : (
                          <InfoSubtitle>Nenhuma</InfoSubtitle>
                        )}
                        <InfoTitle>Variação</InfoTitle>
                        {product.ProductVariation.length > 0 ? (
                          <Variation>
                            {product.ProductVariation.map((variations, index: number) => (
                              <VariationRounded key={index}>{variations.description}</VariationRounded>
                            ))}
                          </Variation>
                        ) : (
                          <InfoSubtitle>Nenhuma</InfoSubtitle>
                        )}
                      </div>
                    </TabsContentContainer>
                  ) : (
                    <TabsContentContainer>
                      <div>
                        <TributaryInfo>
                          {fiscalProductData.map((data, index: number) => (
                            <TributaryRow key={index}>
                              <TributaryData>{data.label} </TributaryData>
                              <TributaryData>{data.field}</TributaryData>
                            </TributaryRow>
                          ))}
                        </TributaryInfo>
                      </div>
                    </TabsContentContainer>
                  )}
                </div>
              </ScrollableCol>
            </Col>
          </Grid>
        </div>
      )}
    </TemplateBshopLite>
  );
}

export default ProductDetails;
