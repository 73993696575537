import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';
import { IoReloadSharp } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import { FlexCenteredDivFhFwRelative } from '../../../../../common/components/common-styled-components';
import {
  IndicatorContentContainter,
  IndicatorHeader,
  IndicatorPopUp,
  IndicatorStatus,
  IndicatorTitle,
  IndicatorValue,
  InfoContainer,
  MoneySymbol,
  Percentage,
  PercentageContainter,
  IndicatorPopUpContainer,
  IndicatorPopUpPeriodDiv,
  IndicatorPopUpName
} from '../../dashboard-styled-components';
import { IndicatorProps } from './indicator-interface';
import { Color } from '../../../../../common/styles/themes';

const Indicator = (props: IndicatorProps) => {
  const [showingPopUp, setShowingPopUp] = useState<boolean>(false);
  const popUpRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseDownEvent = (e: MouseEvent) => {
      if (!popUpRef.current?.contains(e.target as Node)) {
        setShowingPopUp(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDownEvent);

    return () => {
      document.removeEventListener('mousedown', handleMouseDownEvent);
    };
  }, []);

  function getCurrPeriod() {
    switch (props.period) {
      case 'DAY':
        return 'Hoje';
      case 'LAST7DAYS':
        return 'Últimos 7 dias';
      case 'LAST30DAYS':
        return 'Últimos 30 dias';
      case 'MONTH':
        return 'Este mês';
      case 'YEAR':
        return 'Este ano';
    }
  }

  function getLastPeriod() {
    switch (props.period) {
      case 'DAY':
        return 'Ontem';
      case 'LAST7DAYS':
        return 'Anterior';
      case 'LAST30DAYS':
        return 'Anterior';
      case 'MONTH':
        return 'Último mês';
      case 'YEAR':
        return 'Ano anterior';
    }
  }

  return (
    <IndicatorContentContainter>
      <IndicatorHeader>
        <IndicatorTitle>{props.title}</IndicatorTitle>
        <InfoContainer title='informações adicionais' onClick={() => setShowingPopUp(true)}>
          <BsInfoCircle size={12} style={{ cursor: 'pointer' }}></BsInfoCircle>
          {showingPopUp ? (
            <IndicatorPopUpContainer>
              <IndicatorPopUp ref={popUpRef}>
                <IndicatorPopUpName>{props.name}</IndicatorPopUpName>
                <IndicatorPopUpPeriodDiv>
                  <span>{getCurrPeriod()}:</span> <p title={props.currValue}>{props.currValue}</p>
                </IndicatorPopUpPeriodDiv>
                <IndicatorPopUpPeriodDiv>
                  <span>{getLastPeriod()}:</span> <p title={props.lastValue}>{props.lastValue}</p>
                </IndicatorPopUpPeriodDiv>
              </IndicatorPopUp>
            </IndicatorPopUpContainer>
          ) : null}
        </InfoContainer>
      </IndicatorHeader>
      <div
        style={{
          height: '100%',
          width: '100%',
          flex: 3,
          display: 'flex'
        }}
      >
        {props.isLoading ? (
          <FlexCenteredDivFhFwRelative>
            <ClipLoader color={Color.primaryColorLigth} loading={props.isLoading} size={30} />
          </FlexCenteredDivFhFwRelative>
        ) : props.isError ? (
          <FlexCenteredDivFhFwRelative>
            <IoReloadSharp
              title='retry'
              style={{ color: Color.primaryColor, cursor: 'pointer' }}
              size={40}
              onClick={() => props.retry()}
            ></IoReloadSharp>
          </FlexCenteredDivFhFwRelative>
        ) : (
          <>
            <MoneySymbol isMoney={props.isMoney}>R$</MoneySymbol>
            <IndicatorValue isMoney={props.isMoney} valueLength={props.currValue.length}>
              <div style={{ width: '100%', textAlign: props.isMoney ? 'right' : 'center' }}>
                {props.isMoney ? props.currValue.split('R$')[1] : props.currValue}
              </div>
            </IndicatorValue>
            <IndicatorStatus>
              {props.percent.includes('+') ? (
                <PercentageContainter>
                  <Percentage isPositive={true}>{props.percent}%</Percentage> <AiOutlineArrowUp color='green' />
                </PercentageContainter>
              ) : props.percent.includes('-') ? (
                <PercentageContainter>
                  <Percentage isNegative={true}>{props.percent}%</Percentage>
                  <AiOutlineArrowDown color='red' />
                </PercentageContainter>
              ) : (
                <PercentageContainter>
                  <Percentage>{props.percent}%</Percentage>
                </PercentageContainter>
              )}
            </IndicatorStatus>
          </>
        )}
      </div>
    </IndicatorContentContainter>
  );
};

export default Indicator;
