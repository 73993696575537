import { useState } from 'react';

import { VariationContainer, VariationRow } from '../variation-register/variation-register.styled';
import Button from '../../../../../../common/components/button/button';
import { Input } from '../../../../../../common/components/input/input';
import { Select } from '../../../../../../common/components/select/select';
import { useMoneyFormatter } from '../../../../../../common/utils/formatters/use-money-formatter';
import { useMoneyMask } from '../../../../../tela_cadastro/utils/masks/use-money-mask';
import { VariationsGridType, VariationsGridAge } from '../../../../dto';
import { InfoTitle } from '../../../product-details/products-details-styled';
import { Color } from '../../../../../../common/styles/themes';

type VariationGridGeneratorProps = {
  generateVariation: (props: {
    variationsGridType: VariationsGridType;
    variationsGridAge: VariationsGridAge;
    variationPrice: string;
  }) => void;
  defaultPrice: string;
};

function VariationGridGenerator({ generateVariation, defaultPrice }: VariationGridGeneratorProps) {
  const [variationsGridType, setVariationsGridType] = useState<VariationsGridType>(VariationsGridType.shoes);
  const [variationsGridAge, setVariationsGridAge] = useState<VariationsGridAge>(VariationsGridAge.adult);
  const [variationPrice, setVariationPrice] = useState<string>(defaultPrice);

  const [variationPriceError, setVariationPriceError] = useState<string>('');

  const validateGenerateVariationsGridForm = (): boolean => {
    setVariationPriceError('');

    let isValidVariation = true;

    if (useMoneyFormatter(variationPrice) <= 0) {
      setVariationPriceError('Preço inválido!');
      isValidVariation = false;
    }

    return isValidVariation;
  };

  const onGenerateVariationsGrid = () => {
    if (!validateGenerateVariationsGridForm()) return;

    generateVariation({
      variationPrice,
      variationsGridAge,
      variationsGridType
    });
  };

  return (
    <VariationContainer>
      <VariationRow>
        <InfoTitle>Gerar variação</InfoTitle>
        <Input
          InputSize='medium'
          value={variationPrice}
          placeholder='Preço'
          type='text'
          name='price'
          onChange={(e): void => setVariationPrice(useMoneyMask(e.target.value))}
          floatingLabel={true}
          ErrorMessage={variationPriceError}
        ></Input>

        <Select
          value={variationsGridType}
          label='Tipo de produto:'
          onChange={(event) => setVariationsGridType(VariationsGridType[event.target.value as keyof typeof VariationsGridType])}
        >
          <option value={VariationsGridType.shoes}>Calçado</option>
          <option value={VariationsGridType.clothes}>Vestuario</option>
        </Select>

        <Select
          value={variationsGridAge}
          label='Faixa Etária:'
          onChange={(event) => setVariationsGridAge(VariationsGridAge[event.target.value as keyof typeof VariationsGridAge])}
        >
          <option value={VariationsGridAge.kids}>Criança</option>
          <option value={VariationsGridAge.adult}>Adulto</option>
        </Select>
      </VariationRow>
      <VariationRow>
        <Button
          onClick={onGenerateVariationsGrid}
          style={{ background: Color.secondaryColor }}
          width={'100%'}
          color={'#fff'}
          fontSize={''}
          marginLeft={''}
        >
          Gerar grade
        </Button>
      </VariationRow>
    </VariationContainer>
  );
}

export default VariationGridGenerator;
