import { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Input } from '../input/input';
import PasswordPopUp from './popup-password';
import { PasswordEyes, PasswordInputWrapper } from './verify-password-inputs-styled-component';
import { Color } from '../../styles/themes';

type Props = {
  Password1: string;
  Password2: string;
  setPassword1: (value: string) => void;
  setPassword2: (value: string) => void;
  errorPassword1?: string;
  errorPassword2?: string;
  setErrorPassword1?: (value: string) => void;
  setErrorPassword2?: (value: string) => void;

  InputSize: 'big' | 'medium';
};

function VerifyPasswordInputs({
  Password1,
  Password2,
  setPassword1,
  setPassword2,
  errorPassword1,
  errorPassword2,
  setErrorPassword1,
  InputSize
}: Props) {
  const [ShowPassword1, setShowPassword1] = useState(false);
  const [ShowPassword2, setShowPassword2] = useState(false);

  const [ShowPasswordPopup, setShowPasswordPopup] = useState(false);

  function PasswordController() {
    if (Password1 != '' && Password2 != '') {
      return Password1 === Password2 ? (
        <label style={{ color: 'green' }}>Senhas iguais!</label>
      ) : (
        <label style={{ color: Color.red }}>Senhas Diferentes!</label>
      );
    }
    return null;
  }

  function handlePassword1Change(value: string) {
    setErrorPassword1?.('');
    setPassword1(value);
    setShowPasswordPopup(value.length !== 0);
  }

  return (
    <>
      <PasswordInputWrapper>
        {ShowPasswordPopup && <PasswordPopUp Password={Password1} closePopUp={setShowPasswordPopup}></PasswordPopUp>}
        <Input
          InputSize={InputSize}
          ErrorMessage={errorPassword1}
          id='password1'
          name='password1'
          placeholder='Digite a nova senha'
          type={ShowPassword1 ? 'text' : 'password'}
          value={Password1}
          onChange={(e) => handlePassword1Change(e.target.value)}
          onBlur={() => setShowPasswordPopup(false)}
          autoComplete='off'
          floatingLabel={true}
          passwordInputElement={
            <PasswordEyes onClick={() => setShowPassword1(!ShowPassword1)}>
              {ShowPassword1 ? <AiFillEye fontSize={24} /> : <AiFillEyeInvisible fontSize={24} />}
            </PasswordEyes>
          }
          labelBackgroundColor={'white'}
        ></Input>
      </PasswordInputWrapper>

      <PasswordInputWrapper>
        <Input
          InputSize={InputSize}
          ErrorMessage={errorPassword2}
          id='password2'
          name='password2'
          placeholder='Confirme a nova senha'
          type={ShowPassword2 ? 'text' : 'password'}
          value={Password2}
          onChange={(e) => setPassword2(e.target.value)}
          autoComplete='off'
          floatingLabel={true}
          passwordInputElement={
            <PasswordEyes onClick={() => setShowPassword2(!ShowPassword2)}>
              {ShowPassword2 ? <AiFillEye fontSize={24} /> : <AiFillEyeInvisible fontSize={24} />}
            </PasswordEyes>
          }
          labelBackgroundColor={'white'}
        ></Input>
      </PasswordInputWrapper>

      {PasswordController()}
    </>
  );
}

export default VerifyPasswordInputs;
