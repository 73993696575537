import React, { useEffect, useState } from 'react';
import { CardIndicator } from '../../dashboard-styled-components';
import { DecimalsUtils } from '@bshop6/bshop-utility';
import api from '../../../../../api/api';
import Indicator from './indicator';

interface Props {
  title: string;
  period: string;
  path: string;
  isMoney: boolean;
  name: string;
}

function DashboardCardIndicator(props: Props) {
  const [currentValue, setCurrentValue] = useState<number | null>(0);
  const [lastValue, setLastValue] = useState<number | null>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  function GetData() {
    setIsLoading(true);
    api
      .get(props.path, {
        params: {
          period: props.period
        }
      })
      .then((resp) => {
        if (resp.data.data.currentPeriodSales.pa) {
          setCurrentValue(resp.data.data.currentPeriodSales.pa);
          setLastValue(resp.data.data.lastPeriodSales.pa);
          setError(false);
          return;
        }
        setCurrentValue(resp.data.data.currentPeriodSales.total);
        setLastValue(resp.data.data.lastPeriodSales.total);
        setError(false);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getPercentual() {
    let res = 0;

    if (lastValue == 0) return '0';

    if (currentValue == null || lastValue == null) return '0';

    if (currentValue < lastValue) {
      res = DecimalsUtils.round(((currentValue - lastValue) / lastValue) * 100);
    } else {
      res = DecimalsUtils.round((currentValue * 100) / lastValue);
    }

    return res > 0 ? `+${res}` : `${res}`;
  }

  useEffect(() => {
    GetData();
  }, [props.period]);

  function getValue(value: number | null): string {
    if (value == null) {
      if (!props.isMoney) {
        return '0';
      } else {
        return 'R$ 0,00';
      }
    }
    if (!props.isMoney) {
      if (value % 1 === 0) {
        return value.toFixed(0).toString();
      }
      return value.toFixed(2).toString();
    }
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  /* function customFontSize(value: string): number {
    let fontSize = 44;

    fontSize -= (value.length - 1) * 2.3;

    const quantityOfCommas = value.split(',').length;

    return fontSize + (quantityOfCommas > 1 ? quantityOfCommas * 2 : 0);
  }*/

  return (
    <CardIndicator>
      <Indicator
        isError={error}
        isLoading={isLoading}
        period={props.period}
        isMoney={props.isMoney}
        currValue={getValue(currentValue)}
        lastValue={getValue(lastValue)}
        percent={getPercentual()}
        title={props.title}
        retry={() => GetData()}
        name={props.name}
      ></Indicator>
    </CardIndicator>
  );
}

export default DashboardCardIndicator;
