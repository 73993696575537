import { validate } from 'class-validator';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import Button from '../../../common/components/button/button';
import { GetCompanyData } from '../../../get-company-data';
import { AuthType } from '../../../redux/AuthSlice';
import { AttForm, CompleteForm, FormType } from '../../../redux/FormSlice';
import { InitialStateInputsError } from '../common/InitialStateError';
import { SendData } from '../utils/send-data';
import { validatePFX } from '../utils/validations/validate-pfx';
import { ValidatorCadastro } from '../utils/validations/validator-cadastro';
import FilesIconsInputs from './components/file-icons-inputs-component/file-icons-inputs';
import AddressSession from './components/session-component/session-address/session-address';
import CertSession from './components/session-component/session-cert/session-cert';
import CompanyDataSession from './components/session-component/session-company-data/session-company-data';
import {
  ContainerButton,
  ContainerCadastro,
  ContainerCadastroSide,
  ContainerTelaCadastro,
  ContainerUploadFilesSide,
  LoadingContainerButton
} from './styled-components_cadastro';
import { aes128encrypt } from '@bshop6/bshop-encrypt';
import { useNavigate } from 'react-router';
import { ErrorToast } from '../../../common/alerts/custom-alert-error';
import { SuccessToast } from '../../../common/alerts/custom-alert-success';
import { GetCryptoSecret } from '../../../common/utils/get-crypto-secret';
import TemplateBshopLite from '../../../template/template';
import { getForm } from '../utils/get-form';
import { certValidator } from '../utils/validations/cert-validator';
import FiscalSession from './components/session-component/session-fiscal/session-fiscal';
import { VerifyAppDownloaded } from '../utils/verify-app-downloaded';
import Popup from '../../../common/components/popup/popup';
import DownlaodAppInfos from './components/download-app-infos/download-app-infos';
function TelaCadastro() {
  const form = useSelector((state: { form: { value: FormType }; auth: AuthType }) => state.form);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Validator = new ValidatorCadastro();
  const [loading, setLoading] = useState<boolean>(false);
  const [CheckingPFX, setCheckingPFX] = useState<boolean>(false);
  const [IsCertExpired, setIsCertExpired] = useState<boolean>(false);
  const [IsCertPasswordValid, setIsCertPasswordValid] = useState<boolean>(true);
  const [IsCertCheck, setIsCertCheck] = useState<boolean>(true);
  const [IsSendingData, setIsSendingData] = useState<boolean>(false);
  const [ErrorMessageInputs, setErrorMessageInputs] = useState<FormType>(InitialStateInputsError);
  const [triggerPopUp, setTriggerPopUp] = useState<boolean>(false);
  const [origemCompany, setOrigemCompany] = useState('');
  const [updateTrigger, setUpdateTrigger] = useState(false);

  function handlePFXValidation(e: ChangeEvent<HTMLInputElement>, cert?: string | unknown) {
    if (e.target.id === 'Password' && !cert) {
      if (e.target.value === form.value.PasswordConfirm && form.value.certificate) {
        ValidateCERT(form.value.certificate, e.target.value);
      }
    }

    if (e.target.id === 'PasswordConfirm' && !cert) {
      if (e.target.value === form.value.certificatePassword && form.value.certificate) {
        ValidateCERT(form.value.certificate, form.value.certificatePassword);
      }
    }

    if (cert && typeof cert === 'string') {
      if (form.value.certificatePassword === form.value.PasswordConfirm && cert.length > 0) {
        setErrorMessageInputs({ ...ErrorMessageInputs, certificate: '' });
        ValidateCERT(cert, form.value.certificatePassword);
      }
    }
  }

  async function ValidateCERT(cert: string, CertPassword: string) {
    if (CertPassword.length === 0) return;
    try {
      setCheckingPFX(true);
      const result = await validatePFX(cert, CertPassword); //checking if the cert is expired and if the password is correct
      setIsCertCheck(true);
      setCheckingPFX(false);

      if (result.data.expired) {
        setIsCertExpired(true);
        setErrorMessageInputs({ ...ErrorMessageInputs, certificate: 'Certificado expirado!' });
        ErrorToast.fire({
          title: 'Certificado expirado',
          text: 'O certificado fornecido está expirado'
        });

        return;
      }

      if (result.data.invalidPassword) {
        setIsCertPasswordValid(false);
        ErrorToast.fire({
          title: 'Senha incorreta!',
          text: 'A senha do certificado está incorreta'
        });

        return;
      }
      setIsCertExpired(false);
      setIsCertPasswordValid(true);
    } catch (e) {
      setIsCertCheck(false);
      setCheckingPFX(false);
      ErrorToast.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao ler o seu certificado'
      });

      return;
    }
  }

  function sendValidator() {
    Validator.email = form.value.email;
    Validator.certificate = form.value.certificate;
    Validator.logo = form.value.logo;
    Validator.name = form.value.name;
    Validator.fantasyName = form.value.fantasyName ? form.value.fantasyName : null;
    Validator.neighborhood = form.value.neighborhood;
    Validator.city = form.value.city;
    Validator.codIbgeMun = form.value.codIbgeMun;
    Validator.state = form.value.state;
    Validator.street = form.value.street;
    Validator.phone = form.value.phone.replace('(', '').replace(')', '').replace('-', '');
    Validator.zipcode = form.value.zipcode.replace('-', '');
    Validator.stateRegistration = form.value.stateRegistration || null;
    ErrorHandler();
  }

  function ErrorHandler() {
    validate(Validator).then((errors) => {
      const ErrorMessages = { ...InitialStateInputsError };
      let hasErrors = false;

      if (errors.length > 0) {
        for (let x = 0; x < errors.length; x++) {
          const Messages = Object.values(errors[x].constraints || {});

          ErrorMessages[errors[x].property as keyof typeof ErrorMessages] = Messages[0] || 'Campo não preenchido corretamente'; // it gets the error property name and adds the error message to the "ErrorMessages" object where the key is equal to the error property name
        }

        hasErrors = true;
      }

      if (form.value.certificatePassword !== form.value.PasswordConfirm) {
        ErrorToast.fire({
          title: 'Erro!',
          text: 'As senhas devem ser iguais!'
        });

        return;
      }

      if (form.value.certificate && IsCertExpired) {
        ErrorMessages['certificate'] = 'Certificado expirado!';
      }

      const respCertValidator = certValidator(form.value, IsCertCheck);

      respCertValidator.map((certError) => {
        hasErrors = true;
        ErrorMessages[certError.key] = certError.message;
      });

      if (hasErrors) {
        setErrorMessageInputs({ ...ErrorMessages });
        return;
      }

      Submit();
    });
  }

  async function Submit() {
    if (!IsCertExpired && IsCertPasswordValid) {
      const CompanyData: FormType = {
        ...form.value,
        cpfCnpj: form.value.cpfCnpj.replaceAll('.', '').replace('/', '').replace('-', ''),
        phone: form.value.phone.replace('(', '').replace(')', '').replace('-', ''),
        zipcode: form.value.zipcode.replace('-', ''),
        state: form.value.state,
        certificatePassword: form.value.certificatePassword == '' ? '' : aes128encrypt(form.value.certificatePassword, GetCryptoSecret())
      };

      delete CompanyData.PasswordConfirm;
      try {
        setIsSendingData(true);
        await SendData(CompanyData); // sending data to backend
        SuccessToast.fire({
          width: '460px',
          timer: 3000,
          title: 'Dados enviados!'
        });
        setUpdateTrigger((prev) => !prev);
        verifyIfUserDownloadedTheApp();
        if (form.value.nfeSerie) {
          dispatch(AttForm({ key: 'nfeSerie', value: form.value.nfeSerie.padStart(3, '0') }));
        }
        if (form.value.nfceSerie) {
          dispatch(AttForm({ key: 'nfceSerie', value: form.value.nfceSerie.padStart(3, '0') }));
        }
        setErrorMessageInputs({ ...InitialStateInputsError });
      } catch (err) {
        ErrorToast.fire({
          title: 'Erro!',
          text: 'Erro ao enviar os dados.'
        });
      }
      setIsSendingData(false);
      return;
    }

    let text;
    IsCertExpired
      ? (text = 'O certificado fornecido está expirado, antes de enviar os dados mude o certificado.')
      : (text = 'A senha fornecida não corresponde a senha do certificado, antes de enviar os dados informe a senha correta.');

    ErrorToast.fire({
      title: 'Erro!',
      text: text
    });
  }

  async function verifyIfUserDownloadedTheApp() {
    const result = await VerifyAppDownloaded();
    if (!result.data) {
      setTriggerPopUp(true);
    } else {
      setTriggerPopUp(false);
    }
  }

  useEffect(() => {
    async function Validate() {
      try {
        setLoading(true);
        const resp = await GetCompanyData();

        const Form: FormType = getForm(resp.data);

        setOrigemCompany(resp.data.origem);
        dispatch(CompleteForm(Form));
      } catch (err) {
        ErrorToast.fire({
          text: 'Erro ao buscar os dados da sua empresa!'
        });
        navigate('/');
      }
      setLoading(false);
    }

    Validate();
  }, []);

  return (
    <TemplateBshopLite loading={loading} updateTrigger={updateTrigger}>
      <ContainerTelaCadastro>
        <ContainerCadastroSide>
          <ContainerCadastro>
            <CompanyDataSession ErrorMessageInputs={ErrorMessageInputs}></CompanyDataSession>
            <FiscalSession ErrorMessageInputs={ErrorMessageInputs}></FiscalSession>
            <AddressSession ErrorMessageInputs={ErrorMessageInputs}></AddressSession>
            <CertSession ErrorMessageInputs={ErrorMessageInputs} handlePFXValidation={handlePFXValidation}>
              <FilesIconsInputs
                name='cert input'
                ErrorMessageInputs={ErrorMessageInputs}
                handlePFXValidation={handlePFXValidation}
                CheckingPFX={CheckingPFX}
                setCheckingPFX={setCheckingPFX}
                setIsCertExpired={setIsCertExpired}
                setIsCertPasswordValid={setIsCertPasswordValid}
              ></FilesIconsInputs>
            </CertSession>
          </ContainerCadastro>
        </ContainerCadastroSide>
        <ContainerUploadFilesSide>
          <FilesIconsInputs
            name='logo input'
            ErrorMessageInputs={ErrorMessageInputs}
            handlePFXValidation={handlePFXValidation}
            CheckingPFX={CheckingPFX}
            setCheckingPFX={setCheckingPFX}
            setIsCertExpired={setIsCertExpired}
            setIsCertPasswordValid={setIsCertPasswordValid}
          ></FilesIconsInputs>
        </ContainerUploadFilesSide>
        <ContainerButton>
          {CheckingPFX ? <LoadingContainerButton aria-label='LoadingContainerButton' /> : null}
          <Button disabled={IsSendingData} width='40%' color='white' fontSize='15px' marginLeft='0px' onClick={() => sendValidator()}>
            {IsSendingData ? <ClipLoader color={'#ffffff'} loading={IsSendingData} size={25}></ClipLoader> : 'Salvar Dados'}
          </Button>
        </ContainerButton>
        <Popup
          trigger={triggerPopUp}
          setTrigger={setTriggerPopUp}
          children={<DownlaodAppInfos prop={origemCompany} />}
          width={'20%'}
          height={'30%'}
        />
      </ContainerTelaCadastro>
    </TemplateBshopLite>
  );
}

export default TelaCadastro;
