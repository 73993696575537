import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { AuthType } from '../../../redux/AuthSlice';
import { ErrorToast } from '../../alerts/custom-alert-error';
import { SuccessToast } from '../../alerts/custom-alert-success';
import Button from '../button/button';
import { Input } from '../input/input';
import { Select } from '../select/select';
import { MonthsList } from './auxiliares/months';
import { ExportXmlContainer, ExportXmlForm, ExportXmlFormButtons, ExportXmlFormInputs } from './export-xml-styled';
import { DownloadXmls } from './utils/download-xmls';
import { SendXmlsByEmail } from './utils/send-xmls-by-email';

const ExportXml = () => {
  const user = useSelector((state: { auth: { value: AuthType } }) => state.auth);
  const currentYear = new Date().getFullYear();

  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [email, setEmail] = useState(user.value.email);

  const [monthErrorMessage, setMonthErrorMessage] = useState('');
  const [yearErrorMessage, setYearErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const [sendXmlsByEmailLoading, setSendXmlsByEmailLoading] = useState(false);
  const [downloadXmlsLoading, setDownloadXmlsLoading] = useState(false);

  const downloadXmls = async () => {
    if (!validateForm(false)) return;

    try {
      setDownloadXmlsLoading(true);

      const resp = await DownloadXmls({
        month,
        year
      });

      const url = URL.createObjectURL(resp.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `xmls-${year}-${month.padStart(2, '0')}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if ((error as { response?: { status?: number } })?.response?.status === 422) {
        ErrorToast.fire({
          text: 'Não há documentos no período informado.'
        });
        return;
      }

      ErrorToast.fire({
        text: 'Erro ao baixar os arquivos. Por favor, tente novamente!'
      });
    } finally {
      setDownloadXmlsLoading(false);
    }
  };

  const sendXmlsByEmail = async () => {
    if (!validateForm(true)) return;

    try {
      setSendXmlsByEmailLoading(true);

      await SendXmlsByEmail({
        email,
        month,
        year
      });

      SuccessToast.fire({
        text: 'Arquivos enviados por e-mail com sucesso!'
      });
    } catch (error: unknown) {
      if ((error as { response?: { status?: number } })?.response?.status === 422) {
        ErrorToast.fire({
          text: 'Não há documentos no período informado.'
        });
        return;
      }

      ErrorToast.fire({
        text: 'Erro ao enviar arquivos por e-mail. Por favor, tente novamente!'
      });
    } finally {
      setSendXmlsByEmailLoading(false);
    }
  };

  const validateForm = (mustValidateEmail: boolean): boolean => {
    setMonthErrorMessage('');
    setYearErrorMessage('');
    setEmailErrorMessage('');

    let isValidForm = true;

    if (!month) {
      setMonthErrorMessage('Escolha o mês do período buscado');
      isValidForm = false;
    }

    if (!year) {
      setYearErrorMessage('Informe o ano do período buscado');
      isValidForm = false;
    }

    if (mustValidateEmail && !email) {
      setEmailErrorMessage('Informe o e-mail para envio dos arquivos');
      isValidForm = false;
    }

    return isValidForm;
  };

  return (
    <ExportXmlContainer>
      <h3>Exportar XMLs</h3>

      <ExportXmlForm>
        <ExportXmlFormInputs>
          <Select
            value={month}
            label='Mês'
            labelBackgroundColor={'#f5f5f5'}
            onChange={(e) => setMonth(e.target.value)}
            ErrorMessage={monthErrorMessage}
          >
            <option value={''}>Escolha o Mês</option>
            {MonthsList.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </Select>

          <Select
            value={year}
            label='Ano'
            labelBackgroundColor={'#f5f5f5'}
            onChange={(e) => setYear(e.target.value)}
            ErrorMessage={yearErrorMessage}
          >
            <option value={''}>Escolha o Ano</option>
            {Array.from({ length: 30 }).map((_, index) => {
              const year = currentYear - index;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </Select>

          <Input
            InputSize='medium'
            floatingLabel={true}
            labelBackgroundColor={'#f5f5f5'}
            placeholder='E-mail'
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ErrorMessage={emailErrorMessage}
          ></Input>
        </ExportXmlFormInputs>

        <ExportXmlFormButtons>
          <Button
            style={{ marginBottom: '0' }}
            onClick={downloadXmls}
            title='Download'
            width='100%'
            color='white'
            fontSize='16px'
            marginLeft='0'
            type='button'
            backgroundColor='#eecd57'
            backgroundHoverColor='#d9b53d'
          >
            {downloadXmlsLoading ? <ClipLoader color={'#f1f1f1'} loading={downloadXmlsLoading} size={11} /> : 'Baixar arquivos'}
          </Button>

          <Button
            style={{ marginBottom: '0' }}
            onClick={sendXmlsByEmail}
            title='Enviar por E-mail'
            width='100%'
            color='white'
            fontSize='16px'
            marginLeft='0'
            type='button'
          >
            {sendXmlsByEmailLoading ? <ClipLoader color={'#f1f1f1'} loading={sendXmlsByEmailLoading} size={11} /> : 'Enviar por E-mail'}
          </Button>
        </ExportXmlFormButtons>
      </ExportXmlForm>
    </ExportXmlContainer>
  );
};

export default ExportXml;
