import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';

export const PopupContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoIcon = styled(AiOutlineInfoCircle)`
  cursor: pointer;
  color: ${(props) => props.theme.subTitle};
`;

interface PopupProps {
  isOpen?: boolean;
}

export const PopupContent = styled.div<PopupProps>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 4;
  top: 100px;
  background-color: ${(props) => props.theme.secondaryBackground};
  -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 250px;
  height: auto;
  padding: 15px;
  transition: 1s;
  color: ${(props) => props.theme.subTitle};
`;
