import Feature1 from '../../../images/adquirentes/features/lite-01.png';
import Feature2 from '../../../images/adquirentes/features/lite-02.png';
import Feature3 from '../../../images/adquirentes/features/lite-03.png';
import Feature4 from '../../../images/adquirentes/features/lite-04.png';
import Feature5 from '../../../images/adquirentes/features/lite-05.png';
import Feature6 from '../../../images/adquirentes/features/lite-06.png';
import Feature7 from '../../../images/adquirentes/features/lite-07.png';
import Feature8 from '../../../images/adquirentes/features/lite-08.png';
import Banner1 from '../../../images/adquirentes/banners/banner1.png';
import Banner2 from '../../../images/adquirentes/banners/banner2.png';
import Banner3 from '../../../images/adquirentes/banners/banner3.png';
import Mobile1 from '../../../images/adquirentes/banners/mobile1.png';
import Mobile2 from '../../../images/adquirentes/banners/mobile2.png';
import Mobile3 from '../../../images/adquirentes/banners/mobile3.png';

export const BannerImagesWeb = [
  {
    img: Banner1,
    link: 'https://www.instagram.com/p/C6_tvikOCCZ/?hl=pt-br'
  },
  {
    img: Banner2,
    link: 'https://serverinfo.com.br/sua-loja-esta-adequada-a-nova-legislacao'
  },
  {
    img: Banner3,
    link: 'https://serverinfo.com.br/businessshop'
  }
];
export const BannerImagesMobile = [
  {
    img: Mobile1,
    link: 'https://www.instagram.com/p/C6_tvikOCCZ/?hl=pt-br'
  },
  {
    img: Mobile2,
    link: 'https://serverinfo.com.br/sua-loja-esta-adequada-a-nova-legislacao'
  },
  {
    img: Mobile3,
    link: 'https://serverinfo.com.br/businessshop'
  }
];
export const FeaturesConst = [
  {
    title: 'PIX com taxas atrativas',
    img: Feature1
  },
  {
    title: 'Sem limite de emissão de notas',
    img: Feature2
  },
  {
    title: 'Indicadores de Desempenho',
    img: Feature3
  },
  {
    title: 'Um sistema efetivo e barato',
    img: Feature4
  },
  {
    title: 'Controle na palma da mão',
    img: Feature5
  },
  {
    title: 'Diversos meios de pagamento',
    img: Feature6
  },
  {
    title: 'Adequado à legislação',
    img: Feature7
  },
  {
    title: 'Cadastro de produtos com foto por I.A.',
    img: Feature8
  }
];
