import styled from 'styled-components';

export const GridProducts = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 15px;
  justify-content: center;
  justify-items: center;
  padding: 10px;
`;

export const CardProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CardImage = styled.div`
  border-radius: 10px;
  img {
    height: 150px;
    width: 150px;
    object-fit: scale-down;
  }
`;

export const ImgProduct = styled.div`
  background-color: ${(props) => props.theme.greyOpacity};
  border-radius: 10px;
  img {
    height: 150px;
    width: 150px;

    object-fit: scale-down;
  }
`;
export const NoImgProduct = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: dotted;
  color: ${(props) => props.theme.border};
`;

export const ProductName = styled.h1`
  text-decoration: none;
  font-size: 10px;
  font-family: 'Montserrat';
  color: ${(props) => props.theme.title};
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 4px;
  text-overflow: ellipsis;
`;

export const ProductPrice = styled.h1`
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 900;
  text-align: end;
  color: ${(props) => props.theme.title};
  margin-top: 2px;
  margin-bottom: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;

  @media (max-width: 1000px) {
    display: block;
  }
`;

export const ColSearch = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ColHeader = styled.div`
  width: 40%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ButtonCreate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadMoreLoading = styled.div`
  display: block;
  text-align: center;
  animation: rotate 1.5s linear infinite;
  animation-delay: 0.2s;
  background-color: transparent;
  border: 0.3em solid ${(props) => props.theme.inputBorderColor};
  border-radius: 1.5em;
  border-top-color: ${(props) => props.theme.title};
  box-sizing: border-box;
  height: 30px;
  color: transparent;
  padding: 0;
  pointer-events: none;
  width: 30px;
  line-height: 3em;
  margin: 10em auto;
`;

export const Code = styled.div`
  position: relative;
`;

export const CodeTopRight = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
`;

export const ProductCode = styled.div`
  background: ${(props) => props.theme.greyOpacity};
  color: ${(props) => props.theme.title};
  font-size: 13px;
  font-weight: bold;
  border-radius: 1.5em;
  padding: 0px 5px;
  width: 100%;
`;

export const TitleAndOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 0.4rem;
`;

export const LinkPlus = styled.a`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  text-align: center;
  color: ${(props) => props.theme.primaryColorLigth};
  font-size: 13px;
  cursor: pointer;
`;

export const SuggestionCard = styled.div`
  margin-top: 10px;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
`;

export const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const SuggestionItem = styled.li`
  cursor: pointer;
  padding: 4px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const Search = styled.div`
  display: flex;
`;

export const ButtonSearch = styled.button`
  width: 35px;
  height: 35px;
  border: 1px solid #004b90;
  background: #004b90;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;

  :hover {
    opacity: 0.6;
  }
`;
