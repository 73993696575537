import api from '../../../../api/api';
import { aes128encrypt } from '@bshop6/bshop-encrypt';
import { GetCryptoSecret } from '../../../../common/utils/get-crypto-secret';

export const validatePFX = (cert: string, password: string) => {
  const encryptedPassword = aes128encrypt(password, GetCryptoSecret());

  return api.post('/company/validatePFX', {
    cert: cert,
    password: encryptedPassword
  });
};
