import styled from 'styled-components';

export const ContainerDailyReport = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f6f8fa;
  font-family: 'Montserrat-Regular', sans-serif;
`;

export const ContainerContent = styled.div`
  width: 600px;
  height: 180px;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  border-radius: 8px;
  padding: 2rem;
  background-color: #fff;
  display: flex;
  justify-content: start;
`;

export const ContainerContentTitle = styled.h1`
  font-size: 26px;
`;

export const ContainerContentText = styled.span`
  font-size: 16px;
  margin-bottom: 2rem;
`;

export const ContainerContentButtonSpan = styled.span`
  font-weight: bold;
`;
