import api from '../../../api/api';

export const useSearchCEP = (
  cep: string
): Promise<{
  data: {
    data: {
      city: string;
      neighborhood: string;
      state: string;
      street: string;
      codIbgeMun: string;
    };
  };
}> => {
  return api.get('/cep/' + cep).then((resp) => {
    return resp;
  });
};
