import { ProductDataState } from './product-data';

export const InitialProductDataState: ProductDataState = {
  defaultPrice: '',
  description: '',
  ncm: '',
  reference: '',
  unit: '',
  aliquotaIcms: '',
  aliquotaPis: '',
  aliquotaCofins: '',
  aliquotaIpi: '',
  cstIcms: '',
  cstPis: '',
  cstCofins: '',
  origem: '',
  idxReducaoBaseCalculo: '',
  codBeneficioFiscal: '',
  codEspecificadorSubstTribCest: '',
  cfop: '',
  margemValorAgregado: ''
};
