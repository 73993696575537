import { AxiosError } from 'axios';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsImages } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

import { ErrorToast } from '../../../../../../../common/alerts/custom-alert-error';
import { SuccessToast } from '../../../../../../../common/alerts/custom-alert-success';
import { deepCopy } from '../../../../../../../common/utils/deep-copy';
import { ImageType } from '../../../../../dto';
import { NoImgProduct } from '../../../../products/products-styled';
import { UploadProductImage } from '../utils/upload-product-image';
import { ImageDeleteButton } from './product-image-styled';
import { ImageUploadLabel, UploadErrorMessageContainer, UploadErrorMessageSpan } from './upload-image-styled';
import { Color } from '../../../../../../../common/styles/themes';

type UploadImageProps = {
  images: Array<ImageType>;
  setImages?: Dispatch<SetStateAction<Array<ImageType>>>;
  setDefaultImageIndex: Dispatch<SetStateAction<number>>;

  isDefaultImage: boolean;
};

function UploadImage({ images, setImages, setDefaultImageIndex, isDefaultImage }: UploadImageProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasUploadError, setHasUploadError] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const fileToBeUploaded = e.target.files[0];
    setFile(fileToBeUploaded);

    await onUploadImage(fileToBeUploaded);
  };

  const onTryUploadImageAgain = () => {
    if (file) {
      onUploadImage(file);
    }
  };

  const onUploadImage = async (file: File) => {
    try {
      setHasUploadError(false);
      setLoading(true);

      const { id, url } = await UploadProductImage({
        file
      });

      const currImages = deepCopy(images);
      currImages.push({ url, default: isDefaultImage, id: Number(id) });
      setImages?.(currImages);

      if (isDefaultImage) {
        setDefaultImageIndex(0);
      }

      SuccessToast.fire({
        text: 'Upload da imagem realizado com sucesso!'
      });
    } catch (error) {
      setHasUploadError(true);

      if ((error as AxiosError).response?.status == 422) {
        ErrorToast.fire({
          text: 'Este formato de imagem não é suportado pela plataforma. Os formatos permitidos são: PNG e JPEG.'
        });
        return;
      }

      ErrorToast.fire({
        text: 'Erro ao realizar o upload da imagem. Por favor, tente novamente!'
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteFailedUploadImage = () => {
    setHasUploadError(false);
    setFile(null);
  };

  return (
    <NoImgProduct style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
      {hasUploadError ? (
        <UploadErrorMessageContainer onClick={onTryUploadImageAgain}>
          <AiOutlineCloudUpload color={Color.primaryColorLigth} size={24} />
          <UploadErrorMessageSpan>Realizar upload novamente</UploadErrorMessageSpan>

          <ImageDeleteButton
            onClick={(e) => {
              e.stopPropagation();
              deleteFailedUploadImage();
            }}
          >
            <FaTrashAlt size={isDefaultImage ? 20 : 12} color={Color.red} />
          </ImageDeleteButton>
        </UploadErrorMessageContainer>
      ) : loading ? (
        <ClipLoader color={Color.primaryColorLigth} loading={true} size={24} />
      ) : (
        <>
          <ImageUploadLabel htmlFor='productImage'>
            <BsImages size={isDefaultImage ? 80 : 40} />
          </ImageUploadLabel>

          <input
            type='file'
            id='productImage'
            placeholder='productImage'
            style={{ display: 'none' }}
            onChange={(e) => {
              onChangeFile(e);
            }}
            accept='image/*'
          />
        </>
      )}
    </NoImgProduct>
  );
}

export default UploadImage;
