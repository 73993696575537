import { FormType } from '../../../../redux/FormSlice';

export const certValidator = (formData: FormType, IsCertCheck: boolean): { key: Extract<keyof FormType, string>; message: string }[] => {
  const certErrorMessages: { key: Extract<keyof FormType, string>; message: string }[] = [];

  if (formData.certificatePassword.length === 0 && formData.certificate) {
    certErrorMessages.push({ key: 'certificatePassword', message: 'O certificado necessita de uma senha!' });
  }

  if (!IsCertCheck) {
    certErrorMessages.push({ key: 'certificate', message: 'Certificado não verificado!' });
  }

  if (!formData.certificate && formData.certificatePassword.length !== 0) {
    alert('O certificado não foi informado');
    certErrorMessages.push({ key: 'certificate', message: 'Certificado não informado!' });
  }

  if (!formData.regimeTributario && formData.certificate) {
    certErrorMessages.push({ key: 'regimeTributario', message: 'O Regime Tributário precisa ser escolhido' });
  }

  if (formData.certificate && !formData.nfceCSC) {
    certErrorMessages.push({ key: 'nfceCSC', message: 'Campo fiscal obrigatório' });
  }

  if (formData.certificate && !formData.nfceIdCSC) {
    certErrorMessages.push({ key: 'nfceIdCSC', message: 'Campo fiscal obrigatório' });
  }

  if (formData.certificate && !formData.nfeSerie) {
    certErrorMessages.push({ key: 'nfeSerie', message: 'Campo fiscal obrigatório' });
  }

  if (formData.certificate && !formData.nfeLastNumber) {
    certErrorMessages.push({ key: 'nfeLastNumber', message: 'Campo fiscal obrigatório' });
  }

  if (formData.certificate && !formData.nfceSerie) {
    certErrorMessages.push({ key: 'nfceSerie', message: 'Campo fiscal obrigatório' });
  }

  if (formData.certificate && !formData.nfceLastNumber) {
    certErrorMessages.push({ key: 'nfceLastNumber', message: 'Campo fiscal obrigatório' });
  }

  return certErrorMessages;
};
