import styled from 'styled-components';

export const ImageUploadLabel = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const UploadErrorMessageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;

  position: relative;
`;

export const UploadErrorMessageSpan = styled.span`
  font-size: 0.6rem;
  text-align: center;
  color: black;
`;
