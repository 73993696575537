import { FormType } from '../../../redux/FormSlice';
import api from '../../../api/api';
import { AxiosError } from 'axios';
import { CompanyUpdateData } from './company-response';

export const SendData = async (props: FormType) => {
  const updateData = Object.entries(props).reduce((acc, [key, value]) => {
    let newValue;
    if (key == 'nfeLastNumber' || key == 'nfceLastNumber') {
      newValue = +value;
    } else {
      newValue = value;
    }
    return {
      ...acc,
      [key]: newValue !== '' ? newValue : null
    };
  }, {} as CompanyUpdateData);

  const companySettingsObject: {
    regimeTributario?: string;
    nfeSerie?: string;
    nfeLastNumber?: number;
    nfceSerie?: string;
    nfceLastNumber?: number;
    nfceCSC?: string;
    nfceIdCSC?: string;
  } = {
    nfceLastNumber: updateData.nfceLastNumber,
    nfceSerie: updateData.nfceSerie,
    nfeLastNumber: updateData.nfeLastNumber,
    nfeSerie: updateData.nfeSerie,
    regimeTributario: updateData.regimeTributario,
    nfceCSC: updateData.nfceCSC,
    nfceIdCSC: updateData.nfceIdCSC
  };

  delete updateData.regimeTributario;
  delete updateData.nfceLastNumber;
  delete updateData.nfceSerie;
  delete updateData.nfeLastNumber;
  delete updateData.nfeSerie;
  delete updateData.nfceCSC;
  delete updateData.nfceIdCSC;

  return Promise.all([
    api.patch('/company', updateData).catch((err: AxiosError) => {
      throw err.response?.data.cause;
    }),
    api.patch('/company/patch/settings', companySettingsObject).catch((err: AxiosError) => {
      throw err.response?.data.cause;
    })
  ]);
};
