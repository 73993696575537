import api from '../../../api/api';

type CreateTerminalProps = {
  terminalNumber: string;
  terminalId: number;
};

export const CreateTerminal = async ({ terminalId, terminalNumber }: CreateTerminalProps): Promise<{ created: boolean }> => {
  const resp = await api.post<{ data: { created: boolean } }>('/terminal', {
    terminalId: terminalId,
    terminalNumber: terminalNumber
  });
  return resp.data.data;
};
