import styled from 'styled-components';

export const ContainerTelaLogin = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerComponentsLogin = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.background};
  display: flex;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
`;

export const Painel2 = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.theme.background};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 880px) {
    width: 100%;
  }
`;

export const ContainerComponentsPainel2 = styled.div`
  height: 91%;
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 45% 41% 12%;

  @media (max-width: 1600px) {
    width: 90%;
  }
  @media (max-width: 880px) {
    grid-template-rows: auto auto auto;
    width: 80%;
    height: 95%;
  }
`;

export const ConainterLogo = styled.div`
  grid-column-start: 1;
  grid-column-end: 8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  width: 62%;
  height: 62%;
  margin-bottom: 0%;
  min-height: 300px;
`;

export const ContainerInputsLogin = styled.div`
  grid-column-start: 2;
  grid-column-end: 7;

  @media (max-width: 880px) {
    grid-column-start: 1;
    grid-column-end: 8;
  }
`;

export const ContainerPainel = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.theme.primaryColor};
  box-shadow: ${(props) => props.theme.boxShadow};
  z-index: 2;
  display: flex;
  flex-direction: column;

  @media (max-width: 880px) {
    display: none;
  }
`;

export const ContainerConteudo = styled.div`
  flex-grow: 1;
`;

export const ContainerTitlePainel = styled.div`
  height: 50%;
  width: 90%;
  display: block;
  margin-top: 13%;
`;

export const Titulo = styled.div`
  color: #ffffff;
  font-family: 'Montserrat';
  font-size: 220%;
  margin-left: 8%;
`;

export const SubTitulo = styled.div`
  color: #ffffff;
  font-family: 'Montserrat';
  font-size: 295%;
  font-weight: 600;
  margin-left: 8%;
  margin-top: 3%;
`;

export const Footer = styled.footer`
  flex-shrink: 0;
  color: #ffffff;
  font-family: 'Montserrat';
  font-size: 100%;
  @media (max-width: 1150px) {
    font-size: 90%;
  }
  @media (max-width: 950px) {
    font-size: 80%;
  }
  margin-bottom: 13px;
`;

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtons = styled.div`
  grid-column-start: 1;
  grid-column-end: 8;

  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  font-family: 'Montserrat';
  font-size: 14px;
  height: 2%;
  @media (max-height: 800px) {
    margin-top: 40px;
  }
`;

export const LoginButton = styled.div`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1350px) {
    margin-top: 20px;
  }
`;

export const ContainerVerifyCode = styled.div`
  width: 100%;
  height: 100%;
  line-height: 170%;
  font-size: 1.5vh;
  font-family: 'Montserrat';
  @media (max-width: 880px) {
    line-height: 180%;
    font-size: 1.8vh;
  }
`;

export const ContainerCpfCnpjUpdatePassword = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% auto;
`;
export const SeePasswordIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 13px;
  cursor: pointer;
  color: ${(props) => props.theme.inputLabelColor};
`;
