import styled from 'styled-components';
import { Color } from '../../styles/themes';

export interface SwitchButtonProps {
  isOn: boolean;
  onToggle: () => void;
}

export const SwitchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Slider = styled.div<{ isOn: boolean }>`
  width: 40px;
  height: 20px;
  background-color: ${({ isOn }) => (isOn ? Color.secondaryColor : '#ccc')};
  border-radius: 25px;
  position: relative;
  transition: background-color 0.3s;
`;

export const Circle = styled.div<{ isOn: boolean }>`
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: ${({ isOn }) => (isOn ? 'translateX(17px)' : 'translateX(0)')};
  transition: transform 0.3s;
`;
