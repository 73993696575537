import styled from 'styled-components';

export const ImageSelectorContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;

  max-width: 400px;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const DefaultImageCard = styled.div`
  width: 100%;
  height: 380px;
`;

export const SecondaryImageContainer = styled.div`
  width: 100%;
  height: 120px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;
