import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { ErrorToast } from './../../../../../common/alerts/custom-alert-error';
import Button from './../../../../../common/components/button/button';
import { ContainerVerifyCode } from './../../components_login';
import { CodeInputFields } from './code-inputs-fields';
import { Color } from '../../../../../common/styles/themes';

interface VerifyCodeProps {
  userHiddenEmail: string;

  codeExpirationTime: number;
  disableButtonTimer: number;

  generateNewCode: () => Promise<{ hiddenEmail: string; expirationTime: number }>;
  verifyToken: (token: string) => Promise<void>;
  onGoBack: () => void;
}

function VerifyCodeComponent(props: VerifyCodeProps) {
  const [CounterDisabled, setCounterDisabled] = useState(props.disableButtonTimer);
  const [SendingEmail, setSendingEmail] = useState<boolean>(false);

  const [expirationTime, setExpirationTime] = useState(props.codeExpirationTime);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (CounterDisabled >= 0) {
        setCounterDisabled((prev) => prev - 1);
      }
      if (expirationTime > 0) {
        setExpirationTime((prev) => prev - 1);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [expirationTime]);

  async function NewCode() {
    if (SendingEmail) return;

    try {
      setSendingEmail(true);
      const resp = await props.generateNewCode();
      setCounterDisabled(0);

      setExpirationTime(resp.expirationTime);
    } catch (e) {
      ErrorToast.fire({
        text: 'Erro ao enviar um novo código. Por favor, tente novamente!'
      });
    } finally {
      setSendingEmail(false);
    }
  }
  return (
    <ContainerVerifyCode>
      <p style={{ marginBottom: '7%' }}>
        Um e-mail foi enviado para {props.userHiddenEmail} contendo o código de verificação. Digite o código recebido:
      </p>
      <ClipLoader loading={SendingEmail} color={Color.primaryColorLigth} size={24}></ClipLoader>
      <CodeInputFields verifyCode={props.verifyToken} InputsQuantity={6}></CodeInputFields>
      <div style={{ marginTop: '12%' }}>Tempo restante: {expirationTime + 's'}</div>
      <div style={{ marginTop: '12%' }}>
        <Button title='Voltar' width='45%' color='white' fontSize='100%' marginLeft='0px' onClick={props.onGoBack}>
          Voltar
        </Button>
        <Button
          title='Gerar novo código'
          disabled={CounterDisabled > 0}
          width='45%'
          color='white'
          fontSize='100%'
          marginLeft='10%'
          onClick={() => NewCode()}
        >
          {CounterDisabled > 0 ? CounterDisabled : 'Enviar outro Código'}
        </Button>
      </div>
    </ContainerVerifyCode>
  );
}

export default VerifyCodeComponent;
