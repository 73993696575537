import { HTMLAttributes, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { ErrorToast } from '../../../../../../../common/alerts/custom-alert-error';
import { SuccessToast } from '../../../../../../../common/alerts/custom-alert-success';
import { ImageType } from '../../../../../dto';
import { CodeTopRight, ProductCode } from '../../../../products/products-styled';
import { DeleteProductImage } from '../utils/delete-product-image';
import { ImageCard, ImageDeleteButton } from './product-image-styled';

interface ProductImageProps extends HTMLAttributes<HTMLDivElement> {
  image: ImageType;
  isDefaultImage: boolean;
  onDeleteImage: () => void;

  isEditingOrRegistering: boolean;
  productId?: number;
}

function ProductImage({ image, isDefaultImage, onDeleteImage, isEditingOrRegistering, productId, ...rest }: ProductImageProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const deleteImage = async () => {
    try {
      setLoading(true);

      await DeleteProductImage(image.id);
      onDeleteImage();

      SuccessToast.fire({
        text: 'Imagem excluída com sucesso!'
      });
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao excluir imagem. Por favor, tente novamente!'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ImageCard url={image.url} isDefaultImage={isDefaultImage} isEditingOrRegistering={isEditingOrRegistering} {...rest}>
      {isEditingOrRegistering ? (
        <ImageDeleteButton
          onClick={(e) => {
            e.stopPropagation();
            deleteImage();
          }}
        >
          {loading ? (
            <ClipLoader size={isDefaultImage ? 20 : 12} color='red' />
          ) : (
            <FaTrashAlt size={isDefaultImage ? 20 : 12} color='red' />
          )}
        </ImageDeleteButton>
      ) : productId ? (
        <CodeTopRight>
          <ProductCode>Cod: {productId}</ProductCode>
        </CodeTopRight>
      ) : null}
    </ImageCard>
  );
}

export default ProductImage;
