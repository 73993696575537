import api from '../../api/api';
import { md5 } from '@bshop6/bshop-encrypt';

export const SendNewPassword = (email: string, newPassword: string, oldPassword: string) => {
  return api.patch('/auth/password/reset', {
    email,
    newPassword: md5(newPassword),
    oldPassword: md5(oldPassword)
  });
};
