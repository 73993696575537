import styled from 'styled-components';

export const YoutubeEmbedContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  padding: 1rem;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  justify-content: space-between;
  align-items: center;
`;

export const YoutubeEmbedIFrame = styled.iframe`
  height: 100%;
  width: 100%;
`;

export const YoutubeEmbedCentralizedContainer = styled.div`
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);

  position: absolute;

  & > span {
    position: relative;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    transform: translate(-50%, -50%);
  }
`;

export const YoutubeEmbedVideoLinkWarning = styled.span`
  font-size: 0.8rem;
`;
