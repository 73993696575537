import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { ErrorToast } from '../../common/alerts/custom-alert-error';
import { SuccessToast } from '../../common/alerts/custom-alert-success';
import Button from '../../common/components/button/button';
import { Input } from '../../common/components/input/input';
import { ValidatePassword } from '../../common/components/verify-password/utils/validate-password';
import VerifyPasswordInputs from '../../common/components/verify-password/verify-password-inputs';
import { UseLogoutExecute } from '../../logout/use-logout-execute';
import { AuthType } from '../../redux/AuthSlice';
import { FormType } from '../../redux/FormSlice';
import TemplateBshopLite from '../../template/template';
import { SendNewPassword } from './send-new-password';
import {
  FlexCenterAlignContainer,
  Footer,
  FooterText,
  InputsGridContainer,
  UpdatePasswordContainer,
  UpdatePasswordContainerInputs
} from './update-password-styled-components';
function UpdatePassword() {
  const user = useSelector((state: { form: FormType; auth: { value: AuthType } }) => state.auth);
  const [OldPassword, setOldPassword] = useState<string>('');
  const [NewPassword, setNewPassword] = useState<string>('');
  // const [IsChecking, setIsChecking] = useState<boolean>(false);
  // const [ShowPopup, setShowPopup] = useState(false);
  const [PasswordError, setPasswordError] = useState<string>('');
  const [ConfirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [IsSending, setIsSending] = useState(false);

  const navigate = useNavigate();

  async function HandleSendPassword() {
    if (NewPassword === OldPassword && NewPassword.length > 0) {
      setPasswordError('A sua nova senha não pode ser igual a antiga!');
      return;
    }

    if (!ValidatePassword(NewPassword)) {
      setPasswordError('A senha não possui as condições necessárias!');
      return;
    }

    if (NewPassword != ConfirmNewPassword) {
      setPasswordError('As senhas não coincidem!');
      return;
    }

    try {
      setIsSending(true);
      await SendNewPassword(user.value.email, NewPassword, OldPassword);

      SuccessToast.fire({
        text: 'Senha atualizada com sucesso! Por favor, realize o login novamente.'
      });

      await UseLogoutExecute('/auth/logout');
      navigate('/');
    } catch (e) {
      ErrorToast.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar a senha'
      });
    }
    setIsSending(false);
  }

  return (
    <TemplateBshopLite>
      <UpdatePasswordContainer>
        <FlexCenterAlignContainer>
          <UpdatePasswordContainerInputs>
            <InputsGridContainer>
              <Input
                InputSize='medium'
                floatingLabel={true}
                placeholder='Digite sua antiga senha'
                type='password'
                value={OldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                labelBackgroundColor='white'
              ></Input>

              <VerifyPasswordInputs
                InputSize='medium'
                Password1={NewPassword}
                Password2={ConfirmNewPassword}
                setPassword1={setNewPassword}
                setPassword2={setConfirmNewPassword}
                errorPassword1={PasswordError}
                setErrorPassword1={setPasswordError}
              />
              <div style={{ marginTop: '50px' }}>
                <Button onClick={() => HandleSendPassword()} width='100%' color='white' fontSize='16px' marginLeft='0px'>
                  {IsSending ? <ClipLoader color={'#ffffff'} loading={IsSending} size={25}></ClipLoader> : 'Redefinir senha'}
                </Button>
              </div>
            </InputsGridContainer>
          </UpdatePasswordContainerInputs>
        </FlexCenterAlignContainer>
        <Footer>
          <FooterText>© 2024 Server Softwares para Varejo. Todos os direitos reservados.</FooterText>
        </Footer>
      </UpdatePasswordContainer>
    </TemplateBshopLite>
  );
}

export default UpdatePassword;
