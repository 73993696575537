import React, { useEffect, useState } from 'react';
import {
  BouncingDiv,
  Component,
  ComponentContainer,
  ComponentMinimized,
  IconStyle,
  StepContainer,
  StepCount,
  StepStyle,
  StepWrapper,
  StepsLabelContainer
} from './user-first-steps-styled';
import { AiFillUpCircle, AiOutlineClose } from 'react-icons/ai';
import { PopupClosesButton } from '../popup/popup-styled';
import { BiCheck } from 'react-icons/bi';
import Sparkles from '../sparkles/sparkles';
import { GetGoals } from '../../../template/utils';

export type GoalsType = {
  accessApp: boolean;
  completeRegister: boolean;
  createProduct: boolean;
  firstSale: boolean;
};
const steps = [
  {
    label: 'Criar a conta',
    text: 'Crie uma conta no BshopLite',
    name: 'createRegister',
    step: 1
  },
  {
    label: 'Completar cadastro',
    text: 'Complete seu cadastro em "Dados da Conta"',
    name: 'completeRegister',
    step: 2
  },
  {
    label: 'Login pelo app',
    text: 'Baixe o App na loja de aplicativos da própria maquininha, se já baixou, então, faça login no App',
    name: 'accessApp',
    step: 3
  },
  {
    label: 'Criar primeiro produto',
    text: 'Cadastre seu primeiro produto em "Produtos"',
    name: 'createProduct',
    step: 4
  },
  {
    label: 'Primeira venda',
    text: 'Pronto! Você já pode começar a vender usando o BShopLite! 😉',
    name: 'firstSale',
    step: 5
  }
];

const UserFirstSteps = ({ trigger }: { trigger?: boolean }): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(true);
  const [closeMinimizedComponent, setCloseMinimizedComponent] = useState(false);

  const [isStepsComplete, setIsStepsComplete] = useState(true);
  const [goals, setGoals] = useState<GoalsType>({
    accessApp: false,
    completeRegister: false,
    createProduct: false,
    firstSale: false
  });
  const totalSteps = steps.length;
  const width = (100 / (totalSteps - 1)) * Object.values(goals).filter((value) => value === true).length;

  function verifySteps(goals: GoalsType) {
    if (Object.values(goals).filter((value) => value === true).length + 1 === totalSteps) {
      setIsStepsComplete(true);
    } else {
      setIsStepsComplete(false);
    }
  }

  async function getGoals() {
    const popupClosed = localStorage.getItem('popupClosed');
    if (popupClosed === 'false') {
      setIsStepsComplete(true);
    } else {
      const resp = await GetGoals();
      if (resp) {
        const { accessApp, completeRegister, createProduct, firstSale } = resp;
        setGoals({
          accessApp: accessApp ?? goals.accessApp,
          completeRegister: completeRegister ?? goals.completeRegister,
          createProduct: createProduct ?? goals.createProduct,
          firstSale: firstSale ?? goals.firstSale
        });
        verifySteps({
          accessApp: accessApp ?? goals.accessApp,
          completeRegister: completeRegister ?? goals.completeRegister,
          createProduct: createProduct ?? goals.createProduct,
          firstSale: firstSale ?? goals.firstSale
        });
      } else {
        setGoals({
          accessApp: false,
          completeRegister: false,
          createProduct: false,
          firstSale: false
        });
      }
    }
  }
  useEffect(() => {
    getGoals();
  }, [trigger]);

  return (
    <ComponentContainer isStepsComplete={isStepsComplete}>
      <Component
        isOpen={isOpen}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, x: 0 },
          closed: { opacity: 0, y: 0 }
        }}
      >
        <p>Complete a jornada para ter uma experiência completa com o BShopLite</p>
        <StepContainer width={width}>
          {steps.map((step, index) => (
            <StepWrapper key={step.step}>
              <StepStyle step={goals[step.name as keyof GoalsType] || index === 0 ? 'completed' : 'incomplete'}>
                {goals[step.name as keyof GoalsType] || index === 0 ? (
                  <IconStyle
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      ease: 'linear',
                      duration: 0.02
                    }}
                  >
                    <Sparkles>
                      <BiCheck color='#004b90' fontSize={'26px'} />
                    </Sparkles>
                  </IconStyle>
                ) : (
                  <StepCount>{step.step}</StepCount>
                )}
              </StepStyle>
              <StepsLabelContainer>
                <strong>{step.label}:</strong>
                <p>{step.text}</p>
              </StepsLabelContainer>
            </StepWrapper>
          ))}
        </StepContainer>

        <PopupClosesButton onClick={() => setIsOpen(false)} style={{ zIndex: '100', border: '1px solid white' }}>
          <AiOutlineClose size={16} />
        </PopupClosesButton>
      </Component>
      {!closeMinimizedComponent ? (
        <ComponentMinimized
          isOpen={isOpen}
          animate={isOpen ? 'closed' : 'open'}
          variants={{
            open: { opacity: 1, y: 0 },
            closed: { opacity: 0, y: 0 }
          }}
        >
          <div style={{ display: 'flex', gap: '5px', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
            <PopupClosesButton
              onClick={() => {
                setCloseMinimizedComponent(true), localStorage.setItem('popupClosed', 'false');
              }}
              style={{ border: '1px solid ' }}
            >
              <AiOutlineClose size={16} />
            </PopupClosesButton>
            <BouncingDiv onClick={() => setIsOpen(!isOpen)}>
              <AiFillUpCircle size={30} />
            </BouncingDiv>
          </div>

          <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', fontSize: '18px' }}>
            <p>Complete sua jornada!</p>
            <p>
              Progresso {Object.values(goals).filter((value) => value === true).length + 1}/{steps.length}
            </p>
          </div>
        </ComponentMinimized>
      ) : null}
    </ComponentContainer>
  );
};

export default UserFirstSteps;
