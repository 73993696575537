import { ProductSearchEntity } from '../../dto';

export const InitialStateProduct: ProductSearchEntity = {
  defaultPrice: 0,
  name: '',
  description: '',
  ncm: '',
  reference: '',
  tags: [],
  ProductVariation: [],

  companyId: '',
  Images: [],
  id: 0,

  unit: '',
  aliquotaIcms: 0,
  aliquotaPis: 0,
  aliquotaCofins: 0,
  aliquotaIpi: 0,
  cstIcms: '',
  cstPis: '',
  cstCofins: '',
  origem: '',
  idxReducaoBaseCalculo: 0,
  codBeneficioFiscal: '',
  codEspecificadorSubstTribCest: '',
  cfop: '',
  margemValorAgregado: 0
};
