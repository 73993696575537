import { createSlice } from '@reduxjs/toolkit';

export interface FormType {
  cpfCnpj: string;
  email: string;
  logo: string | null;
  certificate: string | null;
  name: string;
  fantasyName: string;
  stateRegistration: string;
  phone: string;
  zipcode: string;
  street: string;
  number: string;
  state: string;
  city: string;
  codIbgeMun: string;
  neighborhood: string;
  complement?: string;
  certificatePassword: string;
  certificateName: string;
  PasswordConfirm?: string;
  regimeTributario: string;
  nfeSerie: string;
  nfeLastNumber: string;
  nfceSerie: string;
  nfceLastNumber: string;
  nfceCSC: string;
  nfceIdCSC: string;
}

export const FormInitialState: FormType = {
  cpfCnpj: '',
  email: '',
  logo: null,
  certificate: null,
  name: '',
  fantasyName: '',
  stateRegistration: '',
  phone: '',
  zipcode: '',
  street: '',
  number: '',
  state: '',
  city: '',
  codIbgeMun: '',
  neighborhood: '',
  complement: '',
  certificatePassword: '',
  certificateName: '',
  PasswordConfirm: '',
  regimeTributario: '',
  nfeSerie: '',
  nfeLastNumber: '',
  nfceSerie: '',
  nfceLastNumber: '',
  nfceCSC: '',
  nfceIdCSC: ''
};

const FormSLice = createSlice({
  name: 'FormSlice',
  initialState: {
    value: FormInitialState
  },
  reducers: {
    AttForm: (state, action: { payload: { value: string | null; key: string } }) => {
      state.value[action.payload.key as keyof typeof state.value] = action.payload.value as string;
    },
    CompleteForm: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { CompleteForm } = FormSLice.actions;
export const { AttForm } = FormSLice.actions;
export default FormSLice.reducer;
