import { NumberStringToDecimalString } from '../../../../common/utils/number-to-decimal';

export const useMoneyMask = (value: string): string => {
  if (!value) return 'R$ 0,00';

  if (value.length > 15) return value.slice(0, 15);
  if (!isNaN(Number(value))) {
    value = NumberStringToDecimalString(value);
  }

  value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

  const result = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(value) / 100);

  return `R$ ${result}`;
};
