import NotFound from './../../../images/404-error-portals.png';
import { useNavigate } from 'react-router';
import { ColPage, ErrorCode, ErrorText, NotFoundPage } from './style';
import Button from '../../../common/components/button/button';

function NotFound404() {
  const navigate = useNavigate();

  function GoBackToExistentPage() {
    const pagePath = '/';
    navigate(pagePath);
    return;
  }

  return (
    <NotFoundPage>
      <ColPage>
        <ErrorCode>
          <ErrorText>Página não encontrada!</ErrorText>

          <Button width={'100px'} color={'#fff'} fontSize={'15px'} marginLeft={'0px'} onClick={GoBackToExistentPage}>
            Voltar
          </Button>
        </ErrorCode>
      </ColPage>

      <ColPage>
        <div>
          <img src={NotFound} style={{ height: '100%', width: '100%', cursor: 'pointer' }}></img>
        </div>
      </ColPage>
    </NotFoundPage>
  );
}

export default NotFound404;
