import styled from 'styled-components';
interface props {
  InputError?: boolean;
}

interface passwordPopupProps {
  hidden: boolean;
}

interface passwordPopupConditionsProps {
  validCondition: boolean;
}

export const InputToken = styled.input<props>`
  box-sizing: border-box;
  width: 100%;
  height: 57px;
  border: none;
  border-bottom: 2px solid #${(props) => (props.InputError ? 'F90505' : '004b90')};
  color: black;
  font-size: 17px;
  font-family: 'Montserrat';
  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;
  outline: none;

  @media (max-height: 900px) {
    height: 50px;
    font-size: 15px;
  }
  :focus {
    border-bottom: 3px solid #${(props) => (props.InputError ? 'F90505' : '004b90')};
  }
`;

export const PasswordPopup = styled.div<passwordPopupProps>`
  width: 75%;
  height: 50%;
  padding: 16px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  position: absolute;
  top: 34%;
  left: 10%;

  border: 1px solid #aaa;
  border-radius: 12px;
  background-color: #fff;

  -webkit-box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);
  box-shadow: 7px 7px 11px -4px rgba(0, 0, 0, 0.42);

  z-index: 10;
  visibility: ${(passwordPopupProps) => (passwordPopupProps.hidden ? 'hidden' : 'visible')};

  :after {
    content: '';
    background-color: #fff;
    border-top: 1px solid #aaa;
    border-right: 1px solid #aaa;
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    position: absolute;
    z-index: -1;
    top: -12px;
    left: calc(50% - 10px);
  }
`;

export const PasswordCondition = styled.div<passwordPopupConditionsProps>`
  width: 94%;
  height: 26px;
  font-weight: 600;
  font-size: clamp(8px, 0.9rem, 14px);
  color: ${(passwordPopupConditionsProps) => (passwordPopupConditionsProps.validCondition ? 'green' : 'red')};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  display: grid;
  align-items: center;
`;

export const PasswordEyes = styled.div`
  position: absolute;
  right: 12px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  height: 65%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  z-index: 5;
`;
