import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { BsImages } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Button from '../../../../common/components/button/button';
import InfoPopup from '../../../../common/components/info-popup';
import { Input } from '../../../../common/components/input/input';
import { ScrollableTargetContext } from '../../../../common/scrollable-target-provider';
import useFixMissingScroll from '../../../../common/utils/hooks/use-fix-missing-scroll-hook';
import TemplateBshopLite from '../../../../template/template';
import debounce from '../../common/debounce';
import { UseMoneyMask } from '../../common/mask/use-money-mask';
import { FindAllProducts } from '../../dto/find-all-products';
import { GetProducts } from '../../utils/get-products';
import {
  ButtonCreate,
  CardProduct,
  Code,
  CodeTopRight,
  ColHeader,
  ColSearch,
  GridProducts,
  Header,
  CardImage,
  LoadMoreLoading,
  NoImgProduct,
  ProductCode,
  ProductName,
  ProductPrice,
  ImgProduct
} from './products-styled';
import { ScrollableTargetComponentId } from '../../../../common/utils/constraints';
import { Color } from '../../../../common/styles/themes';
import { Title } from '../../../../common/styles/global';

function Products() {
  const { ref: bshopliteContentRef } = useContext(ScrollableTargetContext);
  const scrollableTargetComponentId = ScrollableTargetComponentId;

  const isFirstRender = useRef(true);

  const [products, setproducts] = useState<FindAllProducts[]>([]);
  const [noProducts, setNoProducts] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilters] = useState({ filters: '' });
  const [hasMore, setHasMore] = useState(true);
  const take = 36;

  useFixMissingScroll({
    hasMoreItems: hasMore,
    fetchMoreItems: getProducts,
    items: products,
    element: bshopliteContentRef?.current
  });

  async function getProducts(cameFromFiltersChange = true) {
    const queryParams = new URLSearchParams({
      ...filter,
      skip: ((currentPage - 1) * take).toString(),
      take: take.toString()
    }).toString();

    const productsData = await GetProducts(queryParams);

    setCurrentPage((prev) => prev + 1);
    setHasMore(!(productsData.length < take));

    if (cameFromFiltersChange) {
      setNoProducts(productsData.length === 0);
      setproducts(productsData);
    } else {
      setproducts((prev) => [...prev, ...productsData]);
    }
  }

  const handleFilterChange = (e: string): void => {
    setCurrentPage(1);
    setFilters((prev) => ({ ...prev, filters: e }));
  };

  const res = debounce(() => getProducts(true));

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    res();
  }, [filter]);

  return (
    <TemplateBshopLite>
      <div>
        <Header>
          <ColHeader />
          <ColSearch>
            <div style={{ width: '90%' }}>
              <Input
                InputSize='medium'
                value={filter.filters}
                placeholder='Pesquisar produto'
                type='text'
                name='filters'
                onChange={(e): void => handleFilterChange(e.target.value)}
                floatingLabel={true}
              ></Input>
            </div>
            <div style={{ marginLeft: '10px' }}>
              <InfoPopup text='É possível filtrar produto por nome, tag, referencia, código auxiliar, variação ou NCM.' />
            </div>
          </ColSearch>

          <ColHeader>
            <ButtonCreate>
              <Link to={'/register-or-update-product'}>
                <Button style={{ background: Color.secondaryColor }} width={'150px'} color={'#fff'} fontSize={''} marginLeft={''}>
                  Adicionar Produto
                </Button>
              </Link>
            </ButtonCreate>
          </ColHeader>
        </Header>

        <InfiniteScroll
          dataLength={products.length}
          next={() => getProducts(false)}
          hasMore={hasMore}
          loader={<LoadMoreLoading />}
          scrollableTarget={scrollableTargetComponentId}
        >
          <AnimatePresence>
            <GridProducts>
              {products.map((product, index) => {
                const productDefaultImageIndex = product.Images.findIndex((image) => image.default === true);

                return (
                  <motion.div key={index} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
                    <CardProduct>
                      <Link to={`/product-details/${product.id}`}>
                        <CardImage>
                          <Code>
                            <CodeTopRight>
                              <ProductCode>Cod: {product.id}</ProductCode>
                            </CodeTopRight>
                          </Code>
                          {productDefaultImageIndex > -1 ? (
                            <ImgProduct>
                              <img src={product.Images[productDefaultImageIndex].url} />
                            </ImgProduct>
                          ) : (
                            <NoImgProduct>
                              <BsImages size={60} />
                            </NoImgProduct>
                          )}
                        </CardImage>
                      </Link>
                      <div>
                        <ProductName>{product.name ? product.name : ''}</ProductName>
                        <ProductPrice>{product.defaultPrice ? UseMoneyMask(product.defaultPrice.toString()) : 0}</ProductPrice>
                      </div>
                    </CardProduct>
                  </motion.div>
                );
              })}
            </GridProducts>
          </AnimatePresence>
        </InfiniteScroll>

        {noProducts && (
          <Header>
            <Title>Nenhum registro encontrado.</Title>
          </Header>
        )}
      </div>
    </TemplateBshopLite>
  );
}

export default Products;
