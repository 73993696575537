import styled from 'styled-components';
import { Color } from '../../../../../common/styles/themes';

export const ContainerSignupRegisterForm = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;
export const ContainerSignUpTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0px;
  gap: 0.5rem;
`;
export const ContainerTermOfUse = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessageSpan = styled.span`
  font-family: 'Montserrat';
  font-size: 12px;
  margin-left: 3px;
  align-self: flex-start;
  color: ${Color.red};
  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

export const TermOfUse = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;

  & > label {
    font-family: 'Montserrat';
    font-size: 12px;

    & > a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
