import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';

@ValidatorConstraint()
export class CNPJClass implements ValidatorConstraintInterface {
  validate(cpfCnpj: string) {
    if (cpfCnpj.length === 11) {
      return cpfValidator.isValid(cpfCnpj);
    }
    if (cpfCnpj.length === 14) {
      return cnpjValidator.isValid(cpfCnpj);
    }

    return false;
  }
}

export function IsCNPJ(validationOptions?: ValidationOptions) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: CNPJClass
    });
  };
}
