import { createContext, MutableRefObject, useRef } from 'react';

export type ScrollableTargetType = {
  ref?: MutableRefObject<HTMLDivElement | null>;
};

const DefaultValue: ScrollableTargetType = {
  ref: undefined
};

export const ScrollableTargetContext = createContext<ScrollableTargetType>(DefaultValue);

type ScrollableTargetProviderType = {
  children: React.ReactElement;
};

export const ScrollableTargetProvider = ({ children }: ScrollableTargetProviderType) => {
  const ref = useRef(null);

  return <ScrollableTargetContext.Provider value={{ ref }}>{children}</ScrollableTargetContext.Provider>;
};
