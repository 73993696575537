import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { getYouTubeVideoID } from '../../utils/youtube-url-utils';
import {
  YoutubeEmbedCentralizedContainer,
  YoutubeEmbedContainer,
  YoutubeEmbedIFrame,
  YoutubeEmbedVideoLinkWarning
} from './youtube-embed-styled';
import { Color } from '../../styles/themes';

type YoutubeEmbedProps = {
  youtubeUrl: string;
};

const YoutubeEmbed = ({ youtubeUrl }: YoutubeEmbedProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const youtubeVideoId = getYouTubeVideoID(youtubeUrl);

  return (
    <YoutubeEmbedContainer>
      {isLoading && (
        <YoutubeEmbedCentralizedContainer>
          <ClipLoader css={'position: absolute'} color={Color.primaryColorLigth} loading={isLoading} size={40} />
        </YoutubeEmbedCentralizedContainer>
      )}

      <YoutubeEmbedIFrame
        src={`https://www.youtube.com/embed/${youtubeVideoId}`}
        onLoad={() => {
          setIsLoading(false);
        }}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded youtube'
      />

      <YoutubeEmbedVideoLinkWarning>
        <a href={youtubeUrl} rel='noopener noreferrer' target='_blank'>
          Clique aqui
        </a>{' '}
        para acessar o vídeo no Youtube em caso de problemas.
      </YoutubeEmbedVideoLinkWarning>
    </YoutubeEmbedContainer>
  );
};

export default YoutubeEmbed;
