import styled from 'styled-components';
import { screenBreakPoint } from '../../screen-break-points';

export const HeaderStyle = styled.div`
  background-color: ${(props) => props.theme.primaryColor};
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const ContainerHeaderContent = styled.div`
  width: auto;
  height: auto;
  display: flex;
  gap: 34px;
`;

export const LogoContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  width: auto;
  height: 32px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    display: none;
  }
`;

export const ContainerHeaderOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 34px;
  cursor: pointer;
  padding: 16px;
`;

export const ContainerBemVindo = styled.div`
  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    display: none;
  }
`;

interface TextProps {
  fontWeight?: string;
}

export const TextoBemVindo = styled.div<TextProps>`
  color: rgb(211, 211, 211);
  font-size: 13px;
  font-weight: ${(props) => props.fontWeight};
  font-family: 'Montserrat';

  @media (max-width: 1300px) {
    font-size: 12px;
  }
`;

export const ContainerOptions = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = {
  height: '30px',
  width: '30px',
  color: 'white',
  marginLeft: '0px'
};

export const StyledIconMenuOption = {
  height: '21px',
  width: '21px',
  marginLeft: '0px',
  marginRight: '10px'
};

export const ContainerMenuOption = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 6px;
  border-radius: 4px;
  font-family: 'Montserrat';
  display: flex;

  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
    color: #ffffff;
  }
`;

export const ContentMenuOption = styled.div`
  width: 100%;
  margin: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
`;

interface UserProps {
  HasLogo: boolean;
}

export const UserPicContainer = styled.div<UserProps>`
  border-radius: 50%;
  border: ${(props) => (props.HasLogo ? '0px solid #ffffff' : '2px solid #ffffff')};
`;

export const UserPic = styled.img`
  height: 40px;
  width: 46px;
  border-radius: 50%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const MainSideBarContainer = styled.div`
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.11);
  height: 100%;
  flex: 5;
  background-color: ${(props) => props.theme.primaryColor};
  z-index: 40;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    display: none;
  }

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    display: none;
  }
`;

export const MainSideBarPagesContainer = styled.div`
  height: auto;
  width: 100%;
  margin-top: 53px;

  & .pageIcon {
    color: #393a3b;
  }
`;
interface SideBarProps {
  isSideBarOpen: boolean;
}

export const SideBar = styled.div<SideBarProps>`
  position: absolute;
  z-index: 50;
  height: 100vh;
  background-color: ${(props) => props.theme.primaryColor};
  width: ${(props) => (props.isSideBarOpen ? '270px' : '0px')};
  margin-left: -8px;
  margin-top: -6px;
  box-shadow: 1px 0px 6px rgba(171, 204, 253, 0.25);
  transition: 0.2s;

  & #pagesContainer {
    display: ${(props) => (props.isSideBarOpen ? 'flex' : 'none')};
    flex-direction: column;
    gap: 15px;
  }

  & .page {
    height: 60px;
    width: 91%;
    margin-left: 5px;
    display: flex;
    gap: 30px;
    color: #b3b1b1;
    font-size: 17px;
    font-weight: 650;
    font-family: 'Montserrat';
    align-items: center;
    cursor: pointer;
  }

  & .page:hover {
    color: #ffffff;
    border-radius: 8px;
    background-color: #123e6b;
  }

  & .pageIcon {
    margin-left: 10px;
  }
`;

export const OpenMenuButton = styled.div`
  display: none;

  height: 100%;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    display: flex;
  }

  @media (max-height: ${screenBreakPoint.mobileHeight}px) {
    display: flex;
  }
`;

export const MenuOptions = styled.div`
  position: absolute;
  z-index: 4;
  right: 10px;
  top: 48px;
  color: ${(props) => props.theme.title};
  background-color: ${(props) => props.theme.secondaryBackground};
  -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 200px;
  height: auto;
  padding: 7px;
  transition: 1s;
`;
