export const GetResumedValue = (value: number): string => {
  if (value < 1000) {
    return value.toString();
  }
  if (value < 1000000) {
    return `${value % 1000 == 0 ? value / 1000 : (value / 1000).toFixed(1)}m`;
  }

  return `${value % 1000000 == 0 ? value / 1000000 : (value / 1000000).toFixed(1)}Mi`;
};
