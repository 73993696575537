import { useState } from 'react';

import { VariationContainer, VariationRow } from './variation-register.styled';
import Button from '../../../../../../common/components/button/button';
import { Input } from '../../../../../../common/components/input/input';
import { useMoneyFormatter } from '../../../../../../common/utils/formatters/use-money-formatter';
import { useMoneyMask } from '../../../../../tela_cadastro/utils/masks/use-money-mask';
import { VariationsType } from '../../../../dto';
import { InfoTitle } from '../../../product-details/products-details-styled';
import { Color } from '../../../../../../common/styles/themes';

type VariationRegisterProps = {
  isEditing: boolean;
  initialVariationState: VariationsType;
  onCreateVariation?: (variation: VariationsType) => void;
  onUpdateVariation?: (variation: VariationsType, index: number) => void;
  onDeleteVariation?: (index: number) => void;
  variationIndex?: number;
};

export function VariationRegister(props: VariationRegisterProps) {
  const [variation, setVariation] = useState<VariationsType>(props.initialVariationState);

  const [variationDescriptionError, setVariationDescriptionError] = useState<string>('');
  const [variationPriceError, setVariationPriceError] = useState<string>('');
  const [variationGtinError, setVariationGtinError] = useState<string>('');

  const handleCreateVariationInputChange = (nameField: keyof VariationsType, value: string): void => {
    setVariation((prevFormData) => ({
      ...prevFormData,
      [nameField]: nameField == 'price' ? useMoneyMask(value) : value
    }));
  };

  const validateVariation = (): boolean => {
    setVariationDescriptionError('');
    setVariationPriceError('');
    setVariationGtinError('');

    let isValidVariation = true;

    if (!variation.description) {
      setVariationDescriptionError('O campo "Descrição" precisa ser preenchido!');
      isValidVariation = false;
    }

    if (useMoneyFormatter(variation.price) <= 0) {
      setVariationPriceError('Preço inválido!');
      isValidVariation = false;
    }

    if (variation.gtin && !variation.gtin.match(RegExp('^([0-9]{0}|[0-9]{8}|[0-9]{12,14})$'))) {
      setVariationGtinError('Código de barra inválido!');
      isValidVariation = false;
    }

    return isValidVariation;
  };

  const onCreateVariation = () => {
    if (!validateVariation()) return;

    props.onCreateVariation?.(variation);
  };

  const onUpdateVariation = () => {
    if (props.variationIndex === undefined) return;
    if (!validateVariation()) return;

    props.onUpdateVariation?.(variation, props.variationIndex);
  };

  const onDeleteVariation = () => {
    if (props.variationIndex === undefined) return;

    props.onDeleteVariation?.(props.variationIndex);
  };

  return (
    <VariationContainer>
      <VariationRow>
        <InfoTitle>{props.isEditing ? 'Editar Variação' : 'Criar variação'}</InfoTitle>
        <Input
          InputSize='medium'
          value={variation.description}
          placeholder='Descrição:'
          type='text'
          name='description'
          onChange={(e): void => handleCreateVariationInputChange('description', e.target.value)}
          floatingLabel={true}
          ErrorMessage={variationDescriptionError}
        ></Input>
        <Input
          InputSize='medium'
          value={variation.auxCode}
          placeholder='Código auxiliar:'
          type='text'
          name='auxCode'
          onChange={(e): void => handleCreateVariationInputChange('auxCode', e.target.value)}
          floatingLabel={true}
        ></Input>
        <Input
          InputSize='medium'
          value={variation.gtin}
          placeholder='Código de barra'
          type='text'
          name='gtin'
          onChange={(e): void => handleCreateVariationInputChange('gtin', e.target.value)}
          floatingLabel={true}
          ErrorMessage={variationGtinError}
        ></Input>
        <Input
          InputSize='medium'
          value={variation.price}
          placeholder='Preço'
          type='text'
          name='price'
          onChange={(e): void => handleCreateVariationInputChange('price', e.target.value)}
          floatingLabel={true}
          ErrorMessage={variationPriceError}
        ></Input>
      </VariationRow>

      <VariationRow>
        {props.isEditing ? (
          <Button
            onClick={() => onDeleteVariation()}
            style={{ background: '#d66851', marginBottom: '0' }}
            width={'100%'}
            color={'#fff'}
            fontSize={''}
            marginLeft={''}
          >
            Excluir variação
          </Button>
        ) : (
          <></>
        )}

        <Button
          onClick={props.isEditing ? () => onUpdateVariation() : () => onCreateVariation()}
          style={{ background: Color.secondaryColor, marginTop: '0' }}
          width={'100%'}
          color={'#fff'}
          fontSize={''}
          marginLeft={''}
        >
          {props.isEditing ? 'Editar Variação' : 'Adicionar variação'}
        </Button>
      </VariationRow>
    </VariationContainer>
  );
}
