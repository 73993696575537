import { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import api from '../../../api/api';
import Button from '../../../common/components/button/button';
import { ValidateEmail } from '../../../common/utils/validate-email';
import {
  ContainerContent,
  ContainerContentButtonSpan,
  ContainerContentText,
  ContainerContentTitle,
  ContainerDailyReport
} from './styled-componentes_cancel_daily_report';

import { StringUtils } from '@bshop6/bshop-utility';
import { aes128HexDecrypt } from '@bshop6/bshop-encrypt';
import { GetCryptoSecret } from '../../../common/utils/get-crypto-secret';
import { ErrorToast } from '../../../common/alerts/custom-alert-error';

function CancelDailyReport() {
  const [loading, setLoading] = useState(false);
  const [showUnsubscribeContainer, setShowUnsubscribeContainer] = useState(true);

  const [searchParams] = useSearchParams();
  const encryptedEmail = searchParams.get('token');

  let email: string;

  try {
    email = aes128HexDecrypt(encryptedEmail ?? '', GetCryptoSecret());

    if (!email || !ValidateEmail(email)) {
      throw new Error();
    }
  } catch (error) {
    return Navigate({ to: '/' });
  }

  const cancelDailyReportSubscription = async () => {
    setLoading(true);

    try {
      await api.patch(`/company/patch/daily-report/${encryptedEmail}`);

      setShowUnsubscribeContainer(false);
    } catch (error) {
      await ErrorToast.fire({
        title: 'Erro ao desinscrever! Por favor, tente novamente!'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContainerDailyReport>
      <ContainerContent>
        <ContainerContentTitle>Desinscrição</ContainerContentTitle>
        {showUnsubscribeContainer ? (
          <>
            <ContainerContentText>
              Você tem certeza que deseja não receber e-mails com Resumos Diários em {StringUtils.hideEmail(email)} ?
            </ContainerContentText>
            <Button disabled={loading} width='150px' color='white' fontSize='16px' marginLeft='0px' onClick={cancelDailyReportSubscription}>
              {loading ? (
                <span aria-label='loadingContainer'>
                  <ClipLoader color={'#ffffff'} loading={loading} size={20} />
                </span>
              ) : (
                <ContainerContentButtonSpan>Desinscrever</ContainerContentButtonSpan>
              )}
            </Button>
          </>
        ) : (
          <ContainerContentText>Desincrição realizada com sucesso!</ContainerContentText>
        )}
      </ContainerContent>
    </ContainerDailyReport>
  );
}

export default CancelDailyReport;
