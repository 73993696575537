import React from 'react';

import { SessionContainer, SessionTitle, ContainerInputsSession } from './session-styled-components';

interface Props {
  Title: string;
  TitleMarginBottom?: string;
  children: React.ReactNode;
}

const Session: React.FC<Props> = ({ Title, children, TitleMarginBottom }) => (
  <SessionContainer>
    <SessionTitle TitleMarginBottom={TitleMarginBottom}>{Title}</SessionTitle>
    <ContainerInputsSession>{children}</ContainerInputsSession>
  </SessionContainer>
);

export default Session;
