import styled from 'styled-components';
import { Color } from '../../../../common/styles/themes';

export const Grid = styled.div`
  display: flex;
  padding: 20px;

  @media (max-width: 1000px) {
    display: block;
  }
`;

export const Col = styled.div`
  width: 50%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const FixedCol = styled.div`
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 40%;

  @media (max-width: 1000px) {
    width: 100%;
    position: static;
    z-index: 0;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const ScrollableCol = styled.div`
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  flex-direction: row;
  justify-content: center;
`;

export const NoImgProduct = styled.div`
  width: 100%;
  height: 100%;
  padding: 25%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: dotted;
  color: ${(props) => props.theme.border};
`;

export const ProductName = styled.div`
  h3 {
    font-size: 1.3rem;
    font-family: 'Montserrat';
    font-weight: 700;
    color: ${(props) => props.theme.title};
  }
`;

export const InfoTitle = styled.h3`
  padding: 15px 0;
  color: ${(props) => props.theme.subTitle};
  font-size: 13px;

  align-self: flex-start;
`;

export const InfoSubtitle = styled.h3`
  color: ${(props) => props.theme.title};
  font-weight: 600;
  font-size: 14px;
`;

export const ProductPrice = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  color: ${Color.primaryColor};
  margin: 1rem 0;
`;

export const TributaryInfo = styled.div`
  width: 100%;
`;

export const TributaryRow = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;

  &:nth-of-type(odd) {
    background: ${(props) => props.theme.border};
  }
`;

export const TributaryData = styled.div`
  color: ${(props) => props.theme.title};
  font-weight: 600;
  flex: 1 1 20%;
  text-align: center;
`;

export const Tags = styled.div``;
export const TagsRounded = styled.span`
  display: inline-block;
  margin: 3px;
  background-color: ${Color.primaryColor};
  border-radius: 25px;
  padding: 6px 10px;
  color: #fff;
  align-items: center;
  justify-content: center;
`;

export const Variation = styled.div`
  text-align: start;
`;

export const VariationRounded = styled.div`
  display: inline-block;
  margin: 3px;
  border: 1px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.border};
  border-radius: 25px;
  padding: 6px 16px;
  font-weight: 600;
  color: ${(props) => props.theme.title};

  cursor: pointer;
`;

export const Code = styled.div`
  color: #444;
  font-weight: bold;
  position: relative;
`;

export const CodeTopRight = styled.div`
  position: absolute;
  right: 130%;
  padding: 15px;
`;

export const ProductCode = styled.div`
  background: ${(props) => props.theme.greyOpacity};
  color: ${(props) => props.theme.title};
  border-radius: 1.5em;
  padding: 5px 10px;
  width: 100%;
`;

export const CardTags = styled.div`
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border-style: solid;
  border-width: thin;

  color: ${Color.primaryColor};
`;

export const CardNewTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding: 5px;
  border: 1px ${(props) => props.theme.inputBorderColor} solid;
  border-radius: 8px;
`;

export const InputNewTags = styled.input`
  width: 100%;
  min-width: 50%;
  border: transparent;
  border-radius: 5px;
  padding: 10px;
  padding-left: 14px;
  background-color: transparent;
  font-size: 13px;
  color: #444;

  :focus {
    outline: none !important;
    border-color: transparent;
  }
`;

export const CreateTag = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 0;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
  border: 1px solid ${Color.primaryColor};
  border-radius: 15px;
  background-color: ${Color.primaryColor};
  white-space: nowrap;
  color: white;

  span {
    display: flex;
    padding: 4px;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    color: white;
    margin-left: 0.5em;
  }
`;
