import React, { useEffect, useState } from 'react';
import api from '../../../../../api/api';
import ChartContainer from './chart-container';
import { ProducsContainer } from '../../dashboard-styled-components';
import ProductPosition from '../../product-position';
import { FlexCenteredDivFhFw } from '../../../../../common/components/common-styled-components';

interface TopProductsProps {
  period: string;
}

function TopProducts(props: TopProductsProps) {
  const [products, setProducts] = useState<{ id: string; name: string; photo: string | null; totalQtdy: number; totalValue: number }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  function GetData() {
    setIsLoading(true);
    api
      .get('/dashboard/top10products', { params: { period: props.period } })
      .then((resp) => {
        if (resp.data.data.length == 0) {
          setIsEmpty(true);
          setIsError(false);
          return;
        }
        setProducts(resp.data.data);
        setIsError(false);
        setIsEmpty(false);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return;
  }

  useEffect(() => {
    GetData();
  }, [props.period]);
  return (
    <ChartContainer
      title='Top 10 produtos vendidos'
      gridColumnStart={8}
      gridColumnEnd={13}
      gridRowStart={3}
      gridRowEnd={6}
      isScrollable={false}
      isLoading={isLoading}
      isError={isError}
      isEmpty={isEmpty}
      retry={() => GetData()}
    >
      <FlexCenteredDivFhFw>
        <ProducsContainer>
          {products.map((product) => {
            return (
              <ProductPosition
                key={product.id}
                id={product.id}
                name={product.name}
                photo={product.photo}
                totalQtdy={product.totalQtdy}
                totalValue={product.totalValue}
                firstPlaceQty={products[0].totalQtdy}
              ></ProductPosition>
            );
          })}
        </ProducsContainer>
      </FlexCenteredDivFhFw>
    </ChartContainer>
  );
}

export default TopProducts;
