import styled from 'styled-components';

export const ExportXmlContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 8px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
`;

export const ExportXmlForm = styled.form`
  width: 100%;
  height: 100%;
  margin-top: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ExportXmlFormInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 16px;

  & > div {
    width: 100%;
  }
`;

export const ExportXmlFormButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
