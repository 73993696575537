import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { InfoToast } from '../../../../../../common/alerts/custom-alert-info';
import debounce from '../../../../common/debounce';
import { TagsType } from '../../../../dto';
import { GetTabParamName } from '../../../../utils/get-tab-param-name';
import { CardNewTags, CreateTag, InputNewTags } from '../../../product-details/products-details-styled';
import { SuggestionCard, SuggestionItem, SuggestionList } from '../../../products/products-styled';

interface TagInputProps {
  tags: TagsType[];
  setTags: React.Dispatch<React.SetStateAction<TagsType[]>>;
  onTagsChange: (tags: TagsType[]) => void;
}

const TagInput: React.FC<TagInputProps> = ({ tags, setTags, onTagsChange }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [SearchTag, setSearchTag] = useState<TagsType[]>([]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
  };

  async function getTabName() {
    const res = await GetTabParamName(inputValue);
    setSearchTag(res);
  }

  const validadeNewTag = (newTag: TagsType): boolean => {
    if (!tags.some((tag) => tag.name === newTag.name)) {
      return true;
    }
    return false;
  };

  const handleInputKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      const newTag: TagsType = {
        name: inputValue.trim(),
        id: null
      };

      if (validadeNewTag(newTag)) {
        setTags([...tags, newTag]);
        onTagsChange([...tags, newTag]);
        setInputValue('');
      } else {
        InfoToast.fire({
          text: 'Já existe uma tag com este nome!'
        });
      }
    }
  };

  function removeTag(tagToRemove: TagsType, index: number) {
    const currentTag = [...tags];
    currentTag.splice(index, 1);
    setTags(currentTag);
  }

  const handleSuggestionClick = (newTag: TagsType) => {
    if (validadeNewTag(newTag)) {
      setTags([...tags, newTag]);
      onTagsChange([...tags, newTag]);
      setInputValue('');
    } else {
      InfoToast.fire({
        text: 'Já existe uma tag com este nome!'
      });
    }
  };

  const res = debounce(() => getTabName());
  useEffect(() => {
    if (inputValue) {
      res();
    }
  }, [inputValue]);

  return (
    <div>
      <CardNewTags>
        {tags.map((tag, index) => (
          <CreateTag key={index} className='tag'>
            {tag.name}
            <span className='close' onClick={() => removeTag(tag, index)}>
              &times;
            </span>
          </CreateTag>
        ))}
        <InputNewTags
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          placeholder='Adicione tags e pressione Enter'
        />
      </CardNewTags>
      <div>
        {SearchTag.length > 0 && inputValue && (
          <SuggestionCard>
            <SuggestionList>
              {SearchTag.map((suggestion) => (
                <SuggestionItem key={suggestion.id} onClick={() => handleSuggestionClick(suggestion)} className='suggestion'>
                  {suggestion.name}
                </SuggestionItem>
              ))}
            </SuggestionList>
          </SuggestionCard>
        )}
      </div>
    </div>
  );
};

export default TagInput;
