import api from './../../../../api/api';

type SendXmlsByEmailProps = {
  year: string;
  month: string;
  email: string;
};

export const SendXmlsByEmail = async ({ month, year, email }: SendXmlsByEmailProps): Promise<{ success: boolean }> => {
  const resp = await api.post<{ data: { success: boolean } }>(`/documents/xmls/mail?year=${year}&month=${month}`, {
    email
  });

  return resp.data.data;
};
