export const darkTheme = {
  background: '#03101b',
  secondaryBackground: '#0A1623',
  backgroundLoading: '#0A1623',

  title: '#EDF5FD',
  subTitle: '#dbd9de',

  border: '#323d48',
  inputLabelColor: '#68737f',
  inputBorderColor: '#1f2c39',
  greyOpacity: '#323d488c',

  treeBackground: '#323d48',
  treeHoverBackground: '#323d48',

  primaryColor: '#083155',
  primaryColorLigth: '#4282ae',

  secondaryColor: '#527b52',
  secondaryColorLigth: '#6c9d82',
  secondaryColorhover: '#7aa97a',

  boxShadow: 'transparent',

  stepsPopUpTitle: '#008afa',
  stepsPopUpSubtitle: '#ffff',
  stepsPopUpBackground: ' rgb(0, 75, 144, 0.5)',
  stepsPopUpAfter: '#008afa',
  stepsPopUpIcon: '#68737f',
  stepsPopUpBoxShadow: 'rgba(255, 255, 255, 0.05) 0px 2px 4px 0px, rgba(255, 255, 255, 0.15) 0px 2px 16px 0px'
};

export const lightTheme = {
  background: '#f5f5f5',
  secondaryBackground: '#fff',
  backgroundLoading: 'rgba(245, 245, 245, 0.5)',

  title: '#615e67',
  subTitle: '#828f9c',

  border: '#e4e4e5',
  inputLabelColor: '#828f9c',
  inputBorderColor: '#d2deea',
  greyOpacity: '#cec5c57f',

  treeBackground: '#f9f3f4',
  treeHoverBackground: '#f5f3f4',

  primaryColor: '#004b90',
  primaryColorLigth: '#105B8F',

  secondaryColor: 'rgb(55, 132, 55)',
  secondaryColorLigth: '#a4c3b2',
  secondaryColorhover: 'rgb(26, 81, 26)',

  boxShadow: '4px 0px 5px gray',

  stepsPopUpTitle: '#004b90',
  stepsPopUpSubtitle: '#ffff',
  stepsPopUpBackground: ' rgb(0, 75, 144, 0.5)',
  stepsPopUpIcon: '#004b90',
  stepsPopUpAfter: '#008afa',
  stepsPopUpBoxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
};

export const Color = {
  primaryColorLigth: '#008AFA',
  primaryColor: '#004b90',

  secondaryColor: 'rgb(55, 132, 55)',
  secondaryColorhover: 'rgb(26, 81, 26)',

  greyOpacity: '#cec5c5aa',
  red: '#EF726C',
  redOpacity: '#FDD1CF'
};
