/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import api from '../../../../../api/api';
import ChartContainer from './chart-container';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { MonthArray, MonthlySalesCoordinatesProps } from '../../../common/months-array-object';
import { screenBreakPoint } from '../../../../../common/screen-break-points';
import { LinheChartCustomTooltip } from '../line-chart-custom-tooltip';
import { Color } from '../../../../../common/styles/themes';

interface MonthlySalesProps {
  period: string;
  windowWidth: number;
  windowWHeight: number;
}

type MonthlySalesDto = {
  amount: number;
  month: number;
};

export type MonthlySalesResponse = {
  currentPeriodSales: Array<MonthlySalesDto>;
  lastPeriodSales: Array<MonthlySalesDto>;
};

function MonthlySales(props: MonthlySalesProps) {
  const [currentPeriodChartData, setCurrentPeriodChartData] = useState<MonthlySalesCoordinatesProps>({ id: 'Este Ano', data: [] });
  const [lastPeriodChartData, setLastPeriodChartData] = useState<MonthlySalesCoordinatesProps>({ id: 'Ano Passado', data: [] });
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  function GetData() {
    setIsLoading(true);
    api
      .get('/dashboard/sales/month', {
        params: {
          period: props.period
        }
      })
      .then((resp) => {
        const monthlySalesResponse: MonthlySalesResponse = resp.data.data;
        if (monthlySalesResponse.currentPeriodSales.length == 0 && monthlySalesResponse.lastPeriodSales.length == 0) {
          setIsEmpty(true);
          setIsError(false);
          return;
        }

        const currCurrentPeriodChartData = { ...currentPeriodChartData };
        const currLastPeriodChartData = { ...lastPeriodChartData };

        for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
          const monthIdentifier = MonthArray[monthIndex];

          currCurrentPeriodChartData.data.push({
            x: monthIdentifier,
            y: monthlySalesResponse.currentPeriodSales[monthIndex].amount
          });

          currLastPeriodChartData.data.push({
            x: monthIdentifier,
            y: monthlySalesResponse.lastPeriodSales[monthIndex].amount
          });
        }

        setCurrentPeriodChartData({ ...currCurrentPeriodChartData });
        setLastPeriodChartData({ ...currLastPeriodChartData });

        setIsError(false);
        setIsEmpty(false);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    GetData();
  }, []);

  return (
    <ChartContainer
      title='Vendas mensais'
      gridColumnStart={8}
      gridColumnEnd={13}
      gridRowStart={1}
      gridRowEnd={3}
      isScrollable={false}
      isLoading={isLoading}
      isError={isError}
      isEmpty={isEmpty}
      retry={() => GetData()}
    >
      <ResponsiveLine
        defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 }
          ])
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
        tooltip={
          props.windowWidth < screenBreakPoint.mobileWidth || props.windowWHeight < screenBreakPoint.mobileHeight
            ? (data) => {
                return (
                  <div
                    title='custom tooltip'
                    style={{
                      fontSize: '11.5px',
                      position: 'absolute',
                      padding: '3px',
                      borderRadius: '5px',
                      backgroundColor: '#ebebeb',
                      whiteSpace: 'nowrap',
                      bottom: '10px',
                      left: '-30px',
                      fontWeight: '600'
                    }}
                  >
                    <span>{data.point.data.yFormatted}</span>
                  </div>
                );
              }
            : (data) => {
                return <LinheChartCustomTooltip x={data.point.data.xFormatted} y={data.point.data.yFormatted}></LinheChartCustomTooltip>;
              }
        }
        data={[currentPeriodChartData, lastPeriodChartData]}
        enableGridX={false}
        enableGridY={false}
        margin={
          props.windowWidth > screenBreakPoint.mobileWidth
            ? { top: 18, right: 10, bottom: 62, left: 70 }
            : { top: 18, right: 10, bottom: 62, left: 10 }
        }
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        yFormat={(value: number | string | Date) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0'}
        curve='monotoneX'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={
          props.windowWidth > screenBreakPoint.mobileWidth
            ? {
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: 'middle'
              }
            : null
        }
        colors={[Color.primaryColor, '#45b76e']}
        pointSize={4}
        enablePoints={true}
        pointColor={Color.primaryColor}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.2}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 55,
            itemsSpacing: 30,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </ChartContainer>
  );
}

export default MonthlySales;
