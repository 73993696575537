import { md5 } from '@bshop6/bshop-encrypt';
import api from '../../../../../api/api';
import { ClearString } from '../../../../../common/utils/clear-string';
import { CompanyDataState } from '../auxiliares/company-data';

type CreateCompanyInput = {
  companyData: CompanyDataState;
  token: string;
  flavor: string | undefined;
};

type CreateCompanyOutput = {
  username: string;
};

export async function CreateCompany({ companyData, token, flavor }: CreateCompanyInput): Promise<CreateCompanyOutput> {
  const resp = await api.post<{ data: CreateCompanyOutput }>('/company', {
    email: companyData.email,
    name: companyData.name,
    cpfCnpj: ClearString(companyData.cpfCnpj),
    phoneNumber: ClearString(companyData.phoneNumber),
    password: md5(companyData.password1),
    token,
    origem: flavor ?? ''
  });

  return resp.data.data;
}
