import { IsNotEmpty, IsNumberString, IsOptional, Length, Validate } from 'class-validator';
import { ProductDataState } from '../../dto/product-data';
import { CustomDefaultPrice } from './default-price-custom-validator';

export class ProductDataValidator implements ProductDataState {
  constructor(product: ProductDataState) {
    Object.assign(this, product);
  }

  @IsNotEmpty({ message: 'O campo "Preço Padrão" precisa ser preenchido!' })
  @Validate(CustomDefaultPrice, {
    message: 'Preço inválido!'
  })
  defaultPrice!: string;

  @IsNotEmpty({ message: 'O campo "Descrição do produto" precisa ser preenchido!' })
  description!: string;

  @Length(8, 8, { message: 'Ncm deve possuir 8 dígitos!' })
  @IsNumberString({ no_symbols: true }, { message: 'Ncm deve possuir apenas números!' })
  @IsOptional()
  ncm!: string;

  @IsOptional()
  reference!: string;

  @IsOptional()
  unit!: string;

  @IsOptional()
  aliquotaIcms!: string;

  @IsOptional()
  aliquotaPis!: string;

  @IsOptional()
  aliquotaCofins!: string;

  @IsOptional()
  aliquotaIpi!: string;

  @IsOptional()
  cstIcms!: string;

  @IsOptional()
  cstPis!: string;

  @IsOptional()
  cstCofins!: string;

  @IsOptional()
  origem!: string;

  @IsOptional()
  idxReducaoBaseCalculo!: string;

  @IsOptional()
  codBeneficioFiscal!: string;

  @IsOptional()
  codEspecificadorSubstTribCest!: string;

  @IsOptional()
  cfop!: string;

  @IsOptional()
  margemValorAgregado!: string;
}
