import { ChangeEvent } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { ConfirmToast } from '../../../../../common/alerts/custom-alert-confirm';
import { AttForm, FormType } from '../../../../../redux/FormSlice';
import { IconsErrorMessageWrapper, IconSubTitle, InputFileLabel } from '../../styled-components_cadastro';
import imageUploadCertSVGIcon from './../../../../../images/upload-cert.svg';
import { ClipLoader } from 'react-spinners';
import { Color } from '../../../../../common/styles/themes';
import { Theme } from '../../../../../common/theme/theme-context';

interface Props {
  CheckingPFX: boolean;
  setIsCertPasswordValid: (value: boolean) => void;
  handleFiles: (e: ChangeEvent<HTMLInputElement>, key: string, setSubtitulo: (value: string) => void) => void;
  setIsCertExpired: (value: boolean) => void;
  setSubtituloIconPFX: (value: string) => void;
  certificateName: string;
  pfxRef: React.RefObject<HTMLInputElement>;
  ErrorMessageInputs: FormType;
  theme: Theme;
}

function CertificateInput(props: Props) {
  const dispatch = useDispatch();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', marginLeft: '105%' }}>
          <ClipLoader color={Color.primaryColorLigth} loading={props.CheckingPFX} size={45} />
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <InputFileLabel htmlFor='inputPFX'>
          <span>Enviar certificado</span>
          <IconSubTitle style={{ letterSpacing: '1px', fontSize: '11px', fontFamily: 'Montserrat-Regular' }}>
            {props.certificateName?.length > 0 ? (
              <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{props.certificateName}</p>
            ) : (
              'Selecionar PFX...'
            )}
          </IconSubTitle>
          <img src={imageUploadCertSVGIcon} style={{ width: '20px' }} />
        </InputFileLabel>
        <input
          type='file'
          id='inputPFX'
          placeholder='certificado'
          style={{ display: 'none' }}
          ref={props.pfxRef}
          onChange={(e) => {
            props.handleFiles(e, 'certificate', props.setSubtituloIconPFX);
          }}
        ></input>
        {props.ErrorMessageInputs.certificate ? (
          <IconsErrorMessageWrapper>{props.ErrorMessageInputs.certificate}</IconsErrorMessageWrapper>
        ) : null}

        {props.certificateName ? (
          <div
            title='delete cert option'
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => {
              if (!props.certificateName) return;

              ConfirmToast.fire({
                text: 'Você realmente deseja excluir o certificado da sua empresa? Ao excluir o certificado você perderá acesso a diversas funcionalidades do BshopLite',
                confirmButtonText: 'Excluir',
                cancelButtonText: 'Cancelar',
                color: props.theme === 'dark' ? '#EDF5FD' : '#343239',
                background: props.theme === 'dark' ? '#0A1623' : '#fff'
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(AttForm({ key: 'certificateName', value: '' }));
                  dispatch(AttForm({ key: 'certificatePassword', value: '' }));
                  dispatch(AttForm({ key: 'certificate', value: null }));
                  dispatch(AttForm({ key: 'PasswordConfirm', value: '' }));
                  props.setIsCertExpired(false);
                  props.setIsCertPasswordValid(true);

                  if (props.pfxRef.current) {
                    props.pfxRef.current.value = '';
                  }
                }
              });
            }}
          >
            <FaTrashAlt style={{ marginTop: '-15px', width: '15px' }} color={props.theme === 'dark' ? '#dbd9de' : '#828f9c'}></FaTrashAlt>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CertificateInput;
