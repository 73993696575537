import React, { useEffect, useState } from 'react';
import api from '../../../../../api/api';
import ChartContainer from './chart-container';
import { ResponsivePie } from '@nivo/pie';
import { screenBreakPoint } from '../../../../../common/screen-break-points';
import { GetResumedValue } from '../../../../../common/utils/get-resumed-value';

interface SalesByPaymentTypeProps {
  period: string;
  windowWidth: number;
}

const colors = ['#123E6B', '#004B90', '#105895', '#09679A', '#09779D', '#729aaf', '#1086A0', '#1397A0'];

function SalesByPaymentType(props: SalesByPaymentTypeProps) {
  const [chartData, setChartData] = useState<{ id: string; label: string; value: number }[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [showLabel, setShowLabel] = useState<boolean>(false);

  function GetPaymentTypeTranslation(type: string): string {
    switch (type) {
      case 'CREDIT':
        return 'Crédito';
      case 'DEBIT':
        return 'Débito';
      case 'CHECK':
        return 'Cheque';
      case 'CASH':
        return 'Dinheiro';
      case 'PIX':
        return 'Pix';
      case 'BONUS':
        return 'Bônus';
      case 'MANUAL_PIX':
        return 'Pix Manual';
      case 'BSHOPIX':
        return 'BShopPix';

      default:
        return 'Tipo indefinido';
    }
  }

  function GetData() {
    setIsLoading(true);
    api
      .get('/dashboard/sales/payment', {
        params: {
          period: props.period
        }
      })
      .then((resp) => {
        if (resp.data.data.length == 0) {
          setIsEmpty(true);
          setIsError(false);
          return;
        }

        let currType;
        const newArray: { id: string; label: string; value: number }[] = [];
        resp.data.data.map((payment: { payment: string; amount: number }) => {
          currType = GetPaymentTypeTranslation(payment.payment);
          newArray.push({ id: currType, label: currType, value: payment.amount });
        });
        setChartData(newArray);
        setIsEmpty(false);
        setIsError(false);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    GetData();
  }, [props.period]);

  return (
    <ChartContainer
      title='Vendas por forma de pagamento'
      gridColumnStart={1}
      gridColumnEnd={8}
      gridRowStart={3}
      gridRowEnd={6}
      isScrollable={false}
      isError={isError}
      isLoading={isLoading}
      isEmpty={isEmpty}
      retry={() => GetData()}
      showShowLabelOption={true}
      showLabel={showLabel}
      setShowLabel={setShowLabel}
    >
      <ResponsivePie
        valueFormat={(value: number) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0'}
        data={chartData}
        innerRadius={0.55}
        margin={{ top: 10, right: 0, bottom: 110, left: 0 }}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={colors}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        enableArcLinkLabels={props.windowWidth > screenBreakPoint.mobileWidth}
        enableArcLabels={showLabel}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor='#333333'
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabel={(value) => GetResumedValue(value.data.value)}
        arcLabelsTextColor='#ffffff'
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateY: 114,
            itemWidth: 120,
            itemHeight: 22,
            itemTextColor: '#000',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            data: chartData.map((data, index) => ({
              id: data.label,
              label: `${data.label} - ${GetResumedValue(data.value)}`,
              color: colors[index]
            })),
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    </ChartContainer>
  );
}

export default SalesByPaymentType;
