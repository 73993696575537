import { Dispatch, ReactElement, SetStateAction } from 'react';
import { IoReloadSharp } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import { FlexCenteredDivFhFw } from '../../../../../common/components/common-styled-components';
import {
  ChartBaseContainer,
  ChartHeader,
  ChartScrollableContainter,
  ChartShowLabelInput,
  ChartTitle,
  ChartWrapper
} from '../../dashboard-styled-components';
import * as AiIcons from 'react-icons/ai';
import { Color } from '../../../../../common/styles/themes';

interface ChartProps {
  children: ReactElement;
  title: string;
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number;
  isScrollable: boolean;
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  retry: () => void;
  showShowLabelOption?: boolean;
  showLabel?: boolean;
  setShowLabel?: Dispatch<SetStateAction<boolean>>;
}

function ChartContainer(props: ChartProps) {
  return (
    <ChartBaseContainer
      gridColumnStart={props.gridColumnStart}
      gridColumnEnd={props.gridColumnEnd}
      gridRowStart={props.gridRowStart}
      gridRowEnd={props.gridRowEnd}
    >
      <ChartHeader>
        <ChartTitle>{props.title}</ChartTitle>
        {!props.isLoading && !props.isError && !props.isEmpty && props.showShowLabelOption && (
          <ChartShowLabelInput onClick={() => props.setShowLabel?.((prev) => !prev)}>
            {props.showLabel ? <AiIcons.AiFillEyeInvisible fontSize={24} /> : <AiIcons.AiFillEye fontSize={24} />}
          </ChartShowLabelInput>
        )}
      </ChartHeader>
      <ChartWrapper isScrollable={props.isScrollable}>
        {props.isLoading ? (
          <FlexCenteredDivFhFw title='loading icon'>
            <ClipLoader color={Color.primaryColorLigth} loading={props.isLoading} size={30} />
          </FlexCenteredDivFhFw>
        ) : props.isError ? (
          <FlexCenteredDivFhFw>
            <IoReloadSharp
              title='retry icon'
              style={{ color: Color.primaryColor, cursor: 'pointer' }}
              size={60}
              onClick={() => props.retry()}
            ></IoReloadSharp>
          </FlexCenteredDivFhFw>
        ) : props.isEmpty ? (
          <FlexCenteredDivFhFw>
            <div style={{ fontSize: '17px' }}>Nenhum dado foi encontrado</div>
          </FlexCenteredDivFhFw>
        ) : props.isScrollable ? (
          <ChartScrollableContainter title='Gráfico de Vendas por Hora'>{props.children}</ChartScrollableContainter>
        ) : (
          props.children
        )}
      </ChartWrapper>
    </ChartBaseContainer>
  );
}

export default ChartContainer;
