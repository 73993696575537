import { DateUtils } from '@bshop6/bshop-utility';
import api from '../../../../../api/api';

export async function GenerateCodeAndSendEmailForPasswordRecover(cpfCnpj: string): Promise<{
  hiddenEmail: string;
  expirationTime: number;
}> {
  const resp = await api.post<{ data: { email: string; expireTime: string } }>('/auth/token/password', {
    cpfCnpj
  });

  const diffInSeconds = DateUtils.secondsBetween(DateUtils.fromString(resp.data.data.expireTime), DateUtils.now());

  return {
    expirationTime: diffInSeconds,
    hiddenEmail: resp.data.data.email
  };
}
