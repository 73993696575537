import Config from './../../config.json';

export const GetCryptoSecret = (): string => {
  const secret = process.env.REACT_APP_ENV ? process.env.REACT_APP_CRYPTO_SECRET : Config.REACT_APP_CRYPTO_SECRET;

  if (!secret) {
    throw new Error('No secret specified');
  }

  return secret;
};
