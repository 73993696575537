import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

type Sparkle = {
  id: string;
  createdAt: number;
  color: string;
  size: number;
  style: React.CSSProperties;
};

type SparkleInstanceProps = {
  color: string;
  size: number;
  style: React.CSSProperties;
};

type SparklesProps = {
  children: React.ReactNode;
};

const random = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

const COLOR: string[] = [
  '#4da1ff',
  '#ffc24e',
  '#59aa43',
  '#ba53ff',
  '#56acb8',
  '#7764ed',
  '#16d5ff',
  '#00dfb7',
  '#1d297f',
  '#476055',
  '#ee7420',
  '#06c270',
  '#ffb800',
  '#ff3b3b'
];

const randomColor = (): string => COLOR[Math.floor(Math.random() * COLOR.length)];

const DEFAULT_COLOR = '#FFC700';

const generateSparkle = (color: string = DEFAULT_COLOR): Sparkle => {
  return {
    id: String(random(10000, 99999)),
    createdAt: Date.now(),
    color,
    size: random(15, 30),
    style: {
      position: 'absolute',
      top: `${random(0, 100)}%`,
      left: `${random(0, 100)}%`,
      zIndex: 2
    }
  };
};

const range = (start: number, step: number, end?: number): number[] => {
  const output: number[] = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

const useRandomInterval = (callback: () => void, minDelay: number | null, maxDelay: number | null): (() => void) => {
  const timeoutId = React.useRef<number | null>(null);
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const isEnabled = typeof minDelay === 'number' && typeof maxDelay === 'number';

    if (isEnabled) {
      const handleTick = () => {
        const nextTickAt = random(minDelay, maxDelay);
        timeoutId.current = window.setTimeout(() => {
          savedCallback.current();
          handleTick();
        }, nextTickAt);
      };
      handleTick();
    }

    return () => {
      if (timeoutId.current) window.clearTimeout(timeoutId.current);
    };
  }, [minDelay, maxDelay]);

  const cancel = React.useCallback(() => {
    if (timeoutId.current) window.clearTimeout(timeoutId.current);
  }, []);

  return cancel;
};

const SparkleInstance: React.FC<SparkleInstanceProps> = ({ color, size, style }) => {
  return (
    <span className={styles.svgWrap} style={style}>
      <svg width={size} height={size} viewBox='0 0 68 68' fill='none' style={style} className={styles.svg}>
        <path
          d='M26.5 25.5C19.0043 33.3697 0 34 0 34C0 34 19.1013 35.3684 26.5 43.5C33.234 50.901 34 68 34 68C34 68 36.9884 50.7065 44.5 43.5C51.6431 36.647 68 34 68 34C68 34 51.6947 32.0939 44.5 25.5C36.5605 18.2235 34 0 34 0C34 0 33.6591 17.9837 26.5 25.5Z'
          fill={color}
        />
      </svg>
    </span>
  );
};

const Sparkles: React.FC<SparklesProps> = ({ children }) => {
  const [sparkles, setSparkles] = React.useState<Sparkle[]>(() => {
    return range(0, 3).map(() => generateSparkle(randomColor()));
  });
  const [isGenerating, setIsGenerating] = useState(true);
  useRandomInterval(
    () => {
      const now = Date.now();
      const sparkle = generateSparkle(randomColor());
      const nextSparkles = sparkles.filter((sparkle) => {
        const delta = now - sparkle.createdAt;
        return delta < 1000;
      });
      nextSparkles.push(sparkle);
      setSparkles(nextSparkles);
    },
    isGenerating ? 50 : null,
    isGenerating ? 500 : null
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsGenerating(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <span
      style={{
        position: 'relative',
        display: 'inline-block'
      }}
    >
      {sparkles.map((sparkle) => (
        <SparkleInstance key={sparkle.id} color={sparkle.color} size={sparkle.size} style={sparkle.style} />
      ))}
      <strong
        style={{
          position: 'relative',
          zIndex: 1,
          fontWeight: 'bold'
        }}
      >
        {children}
      </strong>
    </span>
  );
};

export default Sparkles;
