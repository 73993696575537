import styled from 'styled-components';
import { screenBreakPoint } from '../../../common/screen-break-points';

export const ContainerTelaCadastro = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerCadastro = styled.div`
  width: 100%;
  margin-left: 35px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    width: 100%;
    margin-left: 0px;
    height: 100%;
  }
`;

export const ContainerCadastroSide = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
`;

export const ContainerUploadFilesSide = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    align-items: center;
  }
`;

export const ContainerIcons = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  height: fit-content;
  justify-content: center;
  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    margin-top: -20px;
    height: 100%;
    margin-bottom: 30px;
  }
`;

export const ContainerInputFile = styled.div`
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15% auto 20%;
`;

export const ContainerButton = styled.div`
  width: 100%;
  margin-right: 20px;
  display: flex;
  margin-top: -15px;
  margin-left: 35px;
  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      font-size: 12px;
      width: 28%;
      margin-bottom: 10px;
    }
  }
`;

export const ContainerReactIcon = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    margin-left: 2px;
  }
`;

export const ContainerImg = styled.div`
  height: 110px;
  width: 110px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-width: 2px;
  border-radius: 6px;
  background: #2778c424;
  border-style: dashed;
  border-color: #1f71bead;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
`;

export const StyledImg = styled.img`
  height: 70px;
  max-height: 70px;
  max-width: 70px;
  width: 70px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 1700px) {
    max-height: 100px;
    max-width: 100px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    max-height: 100px;
    max-width: 100px;
  }
`;

export const StyledImgSelected = styled.img`
  height: 100%;
  max-height: 120px;
  max-width: 120px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    max-height: 130px;
    max-width: 130px;
  }
`;

export const StyledIcon = {
  height: 'auto',
  width: '60%',
  color: '#424F5E',

  minWidth: '10px'
};

export const StyledSmallIcon = {
  height: 'auto',
  width: '40%',
  color: '#424F5E',

  minWidth: '10px'
};

export const IconTitle = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Montserrat';
  width: 100%;
  color: ${(props) => props.theme.title};

  @media (max-width: 1800px) {
    font-size: 17px;
  }

  @media (max-width: 1000px) {
    font-size: 16px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    margin-bottom: 15px;
  }
`;

export const IconSubTitle = styled.p`
  font-size: 14px;
  font-family: 'Montserrat';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: 'nowrap';
  width: 150px;
  color: ${(props) => props.theme.subTitle};

  @media (max-width: 1700px) {
    font-size: 13px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 13px;
    width: 180px;
  }
`;

export const ContainerLoading = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainerButton = styled.div`
  width: 72%;
  height: 72%;

  background-color: rgba(240, 240, 240, 0.3);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    height: 80%;
  }
`;

export const ContainerLoadingPassword = styled.div`
  margin-left: 10%;
`;

export const IconsErrorMessageWrapper = styled.div`
  color: red;
  font-family: 'Montserrat';
  margin: 15px;
  width: auto;
  font-size: 13px;

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    font-size: 12px;
  }
`;

export const ContainterTrashIcon = styled.div`
  cursor: pointer;

  @media (max-width: 1500px) {
    left: 120px;
    bottom: 30px;
  }

  @media (max-width: 1200px) {
    left: 120px;
    bottom: 30px;
  }

  @media (max-width: ${screenBreakPoint.mobileWidth}px) {
    left: -85px;
  }
`;

export const TrashIcon = styled.div`
  color: ${(props) => props.theme.subTitle};
`;
export const InputFileLabel = styled.label`
  display: flex;
  width: 100%;
  height: 30px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.inputBorderColor};
  position: relative;
  & > span {
    pointer-events: none;
    position: absolute;
    left: 10px;
    top: -8px;
    padding-left: 10px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    color: ${(props) => props.theme.title};
    font-size: 11px;
    font-family: 'Montserrat-Regular';
    background-color: ${(props) => props.theme.background};
    letter-spacing: 1px;
  }
`;
