import React, { useState } from 'react';
import {
  ProductImageWrapper,
  ProductNameAndValueWrapper,
  ProductPositionContainer,
  ProductProgressBar,
  ProductProgressBarWrapper
} from './dashboard-styled-components';
import PorductDefaultPicture from './../../../images/cabide.png';
import { ClipLoader } from 'react-spinners';

interface ProductPositionProps {
  id: string;
  name: string;
  photo: string | null;
  totalQtdy: number;
  totalValue: number;
  firstPlaceQty: number;
}

function ProductPosition(props: ProductPositionProps) {
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  return (
    <ProductPositionContainer>
      <ProductImageWrapper>
        <img
          style={props.photo ? (isImageLoading ? { height: '0%', width: '0%' } : { height: '100%', width: '100%' }) : { height: '80%' }}
          src={props.photo ? props.photo : PorductDefaultPicture}
          onLoad={() => {
            setIsImageLoading(false);
          }}
        ></img>
        {isImageLoading ? (
          <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', marginLeft: '7px' }}>
            <ClipLoader color={'#ffffff'} loading={true} size={15} />
          </div>
        ) : null}
      </ProductImageWrapper>
      <div
        style={{
          height: '80%',
          width: '87%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <ProductNameAndValueWrapper>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.name}</div>
          <div>{props.totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 0}</div>
        </ProductNameAndValueWrapper>
        <ProductProgressBarWrapper>
          <span style={{ position: 'absolute', right: '8px', top: '-1px', color: '#070707', fontWeight: '600' }}>{props.totalQtdy}</span>
          <ProductProgressBar
            Progress={props.totalQtdy <= props.firstPlaceQty ? (props.totalQtdy * 100) / props.firstPlaceQty + '%' : 0 + '%'}
          ></ProductProgressBar>
        </ProductProgressBarWrapper>
      </div>
    </ProductPositionContainer>
  );
}

export default ProductPosition;
