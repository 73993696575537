import { useMoneyFormatter } from '../../../../common/utils/formatters/use-money-formatter';
import { usePorcentageFormatter } from '../../../../common/utils/formatters/use-porcentage-formatter';
import { SanitizeData } from '../../../../common/utils/sanitize-data';
import { ImageType, TagsType, VariationsType } from '../../dto';
import { ProductDataState } from '../../dto/product-data';
import { UpdateProductRequestDto } from '../../dto/requests/update-product-request.dto';
import { TagMapper } from './tag.mapper';
import { VariationRequestMapper } from './variation.mapper';

type UpdateProductMapperProps = {
  productData: ProductDataState;
  variations: VariationsType[];
  tags: TagsType[];
  images: ImageType[];

  createdVariations: Array<VariationsType>;
  updatedVariations: Array<VariationsType>;
  deletedVariations: Array<number>;
  deletedTags: Array<number>;

  oldDefaultImageId?: number;
};

export const UpdateProductMapper = ({
  productData,
  variations,
  tags,
  images,
  createdVariations,
  deletedVariations,
  updatedVariations,
  deletedTags,
  oldDefaultImageId
}: UpdateProductMapperProps): UpdateProductRequestDto => {
  return SanitizeData({
    description: productData.description,
    defaultPrice: useMoneyFormatter(productData.defaultPrice),
    ncm: productData.ncm,
    reference: productData.reference,
    images: images.map((image) => image.id),
    tags: tags.map(TagMapper),
    variations: variations.map(VariationRequestMapper),
    defaultImageId: images.find((image) => image.default)?.id,
    unit: productData.unit || null,
    aliquotaIcms: usePorcentageFormatter(productData.aliquotaIcms),
    aliquotaPis: usePorcentageFormatter(productData.aliquotaPis),
    aliquotaCofins: usePorcentageFormatter(productData.aliquotaCofins),
    aliquotaIpi: usePorcentageFormatter(productData.aliquotaIpi),
    cstIcms: productData.cstIcms || null,
    cstPis: productData.cstPis || null,
    cstCofins: productData.cstCofins || null,
    origem: productData.origem || null,
    idxReducaoBaseCalculo: usePorcentageFormatter(productData.idxReducaoBaseCalculo),
    codBeneficioFiscal: productData.codBeneficioFiscal || null,
    codEspecificadorSubstTribCest: productData.codEspecificadorSubstTribCest || null,
    cfop: productData.cfop || null,
    margemValorAgregado: usePorcentageFormatter(productData.margemValorAgregado),

    deletedTags: deletedTags,
    createdVariations: createdVariations.map(VariationRequestMapper),
    deletedVariations,
    updatedVariations: updatedVariations.map(VariationRequestMapper),
    oldDefaultImageId: oldDefaultImageId
  });
};
