const MonthArray = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'] as const;
type MonthType = typeof MonthArray[number];

type MonthSaleCoordinates = {
  x: MonthType;
  y: number;
};

type MonthlySalesCoordinatesProps = {
  id: string;
  data: Array<MonthSaleCoordinates>;
};

export { MonthArray };
export type { MonthType, MonthSaleCoordinates, MonthlySalesCoordinatesProps };
