import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './app-style.css';
import { ScrollableTargetProvider } from './common/scrollable-target-provider';
import SignupRouteMiddleware from './middlewares/signup-route-middleware';
import CancelDailyReport from './pages/cancel_daily_report/view/cancel_daily_report';
import Dashboard from './pages/dashboard/view';
import Login from './pages/login/view/login';
import NotFound404 from './pages/not-found/view';
import ProductDetails from './pages/products/view/product-details/products-details';
import Products from './pages/products/view/products/products';
import RegisterOrUpdateProduct from './pages/products/view/register-or-update/register-or-update-products';
import Cadastro from './pages/tela_cadastro/view/tela_cadastro';
import Adquirentes from './pages/adquirentes/view/adquirentes';
import Terminals from './pages/terminals/view/terminals';
import TermsOfUse from './pages/terms_of_use/view/terms_of_use';
import UpdatePassword from './pages/update_password/update_password';
import ProtectedRoutes from './protected-routes';
import { ThemeContextProvider } from './common/theme/theme-context';
import GlobalStyle from './common/styles/global';

function App() {
  return (
    <ScrollableTargetProvider>
      <BrowserRouter>
        <ThemeContextProvider>
          <GlobalStyle />

          <Routes>
            <Route path='/' element={<Login goToSignUpComponent={false} />} />
            <Route path='/adquirentes' element={<Adquirentes />} />
            <Route path='*' element={<NotFound404 />} />
            <Route element={<SignupRouteMiddleware />}>
              <Route path='/:flavor/cadastre-sua-conta' element={<Login goToSignUpComponent={true} />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path='/cadastro' element={<Cadastro />} />
              <Route path='/update-password' element={<UpdatePassword />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/terminals' element={<Terminals />} />
              <Route path='/products' element={<Products />} />
              <Route path='/product-details/:id' element={<ProductDetails />} />
              <Route path='/register-or-update-product' element={<RegisterOrUpdateProduct />} />
            </Route>
            <Route path='/termos-de-uso' element={<TermsOfUse />} />
            <Route path='/cancelar-incricao' element={<CancelDailyReport />} />
          </Routes>
        </ThemeContextProvider>
      </BrowserRouter>
    </ScrollableTargetProvider>
  );
}

export default App;
