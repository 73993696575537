import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { Color } from '../../../../../../../common/styles/themes';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; /* Posiciona o modal acima de todos os outros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props: { loading: boolean }) => (props.loading ? 'visible' : 'hidden')};
`;

function LoaderModal({ loading }: { loading: boolean }) {
  return (
    <Modal loading={loading}>
      <ClipLoader color={Color.primaryColorLigth} loading={true} size={54} />
    </Modal>
  );
}

export default LoaderModal;
