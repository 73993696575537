import { useEffect, useState } from 'react';
import { AiOutlineInfoCircle, AiOutlinePlus } from 'react-icons/ai';
import { BsCheckCircleFill } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';
import { ClipLoader } from 'react-spinners';
import Popup from '../../../common/components/popup/popup';
import YoutubeEmbed from '../../../common/components/youtube-embed/youtube-embed';
import { getLocalStorageAlreadyShowedTerminalInfo, setLocalStorageAlreadyShowedTerminalInfo } from '../../../common/utils/local-storage';
import TemplateBshopLite from '../../../template/template';
import { TerminalDto } from '../dto/terminal.dto';
import { GetTerminals } from '../utils/get-terminals';
import RegisterTerminal from './register-terminal';
import TerminalInfos from './terminal-infos';
import {
  TerminalHeader,
  TerminalList,
  TerminalListErrorContainer,
  TerminalListLoadingContainer,
  TerminalListNoDataContainer,
  TerminalListRow,
  TerminalListRowCell,
  TerminalListRowHeader,
  TerminalsPage
} from './terminals-styled';
import TerminalInfosSteps from './terminal-infos-steps';
import { Color } from '../../../common/styles/themes';
import { Title } from '../../../common/styles/global';

function Terminals() {
  const [terminals, setTerminals] = useState<TerminalDto[]>([]);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [searchTerminalsLoading, setSearchTerminalsLoading] = useState(false);
  const [searchTerminalsError, setSearchTerminalsError] = useState(false);

  const [showTerminalInfoPopup, setShowTerminalInfoPopup] = useState(false);

  const [youtubeEmbedUrl, setYoutubeEmbedUrl] = useState('rokGy0huYEA');
  const [showYoutubeEmbedPopup, setShowYoutubeEmbedPopup] = useState(false);

  useEffect(() => {
    const alreadyShowedTerminalInfo = getLocalStorageAlreadyShowedTerminalInfo();
    if (!alreadyShowedTerminalInfo) {
      setShowTerminalInfoPopup(true);
      setLocalStorageAlreadyShowedTerminalInfo(true);
    }

    searchAllTerminals();
  }, []);

  async function searchAllTerminals() {
    try {
      setSearchTerminalsError(false);
      setSearchTerminalsLoading(true);
      const resp = await GetTerminals();
      setTerminals(resp);
    } catch (error) {
      setSearchTerminalsError(true);
    } finally {
      setSearchTerminalsLoading(false);
    }
  }

  function onClickNewTerminalButton() {
    setShowRegisterPopup(true);
  }

  return (
    <TemplateBshopLite>
      <TerminalsPage>
        <TerminalHeader>
          <Title>
            <h3>Ativação de Terminais</h3>
            <AiOutlineInfoCircle
              size={20}
              onClick={() => {
                setShowTerminalInfoPopup(true);
              }}
            />
          </Title>
          <button onClick={onClickNewTerminalButton}>
            <AiOutlinePlus color='#fffff' size={16} /> Cadastrar Terminal
          </button>
        </TerminalHeader>
        <TerminalInfosSteps />

        <TerminalList>
          {searchTerminalsLoading ? (
            <TerminalListLoadingContainer>
              <ClipLoader color={Color.primaryColor} size={50} />
            </TerminalListLoadingContainer>
          ) : (
            <div>
              <TerminalListRowHeader>
                <TerminalListRowCell>
                  <h4>Identificador do Terminal</h4>
                </TerminalListRowCell>
                <TerminalListRowCell>
                  <h4>Número do Terminal</h4>
                </TerminalListRowCell>
                <TerminalListRowCell>
                  <h4>Situação</h4>
                </TerminalListRowCell>
              </TerminalListRowHeader>
              {searchTerminalsError ? (
                <TerminalListErrorContainer>Erro ao buscar dados</TerminalListErrorContainer>
              ) : (
                <>
                  {terminals?.length > 0 ? (
                    terminals.map((terminal) => {
                      return (
                        <TerminalListRow key={terminal.id}>
                          <TerminalListRowCell>
                            <h4>{terminal.terminalIdentifier}</h4>
                          </TerminalListRowCell>
                          <TerminalListRowCell>
                            <p>{terminal.terminalNumber}</p>
                          </TerminalListRowCell>
                          <TerminalListRowCell>
                            {terminal.active ? (
                              <>
                                <BsCheckCircleFill color='green' size={22} /> Ativo
                              </>
                            ) : (
                              <>
                                <RiErrorWarningFill color='yellow' size={24} />
                                Em análise/Aguardando Ativação
                              </>
                            )}
                          </TerminalListRowCell>
                        </TerminalListRow>
                      );
                    })
                  ) : (
                    <TerminalListNoDataContainer>Não há terminais cadastrados</TerminalListNoDataContainer>
                  )}
                </>
              )}
            </div>
          )}
        </TerminalList>

        <Popup width={'24%'} height={'30%'} setTrigger={setShowRegisterPopup} trigger={showRegisterPopup}>
          <RegisterTerminal
            terminalList={terminals ?? []}
            onTerminalRegister={() => {
              setShowRegisterPopup(false);
              searchAllTerminals();
            }}
            setYoutubeEmbedUrl={setYoutubeEmbedUrl}
            showYoutubeEmbedPopup={setShowYoutubeEmbedPopup}
          ></RegisterTerminal>
        </Popup>

        <Popup width='960px' height='600px' trigger={showYoutubeEmbedPopup} setTrigger={setShowYoutubeEmbedPopup}>
          <YoutubeEmbed youtubeUrl={youtubeEmbedUrl}></YoutubeEmbed>
        </Popup>

        <Popup width={'50%'} height={'20%'} setTrigger={setShowTerminalInfoPopup} trigger={showTerminalInfoPopup}>
          <TerminalInfos />
        </Popup>
      </TerminalsPage>
    </TemplateBshopLite>
  );
}

export default Terminals;
