import api from '../../../../../../../api/api';
import { UploadProductImageResponse } from '../dto/responses/upload-product-image-response';

type UploadImageProps = {
  file: File;
};

export const UploadProductImage = async ({ file }: UploadImageProps): Promise<UploadProductImageResponse> => {
  const formData = new FormData();
  formData.append('image', file);

  const resp = await api.post<{ data: UploadProductImageResponse }>(`images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return resp.data.data;
};
