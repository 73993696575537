import { FormType } from '../../../redux/FormSlice';

export const InitialStateInputsError: FormType = {
  cpfCnpj: '',
  email: '',
  logo: '',
  certificate: '',
  name: '',
  fantasyName: '',
  stateRegistration: '',
  phone: '',
  zipcode: '',
  street: '',
  number: '',
  state: '',
  city: '',
  codIbgeMun: '',
  neighborhood: '',
  certificatePassword: '',
  certificateName: '',
  PasswordConfirm: '',
  regimeTributario: '',
  complement: '',
  nfceLastNumber: '',
  nfceSerie: '',
  nfeLastNumber: '',
  nfeSerie: '',
  nfceCSC: '',
  nfceIdCSC: ''
};
