import { YoutubeVideo } from '../styled-components_adquirentes';

const VideoPlayer = () => {
  return (
    <YoutubeVideo
      src='https://www.youtube.com/embed/48AHcRq3tBw?si=iG6cFHlJGY_PurqA?&autoplay=1&loop=1&mute=1&rel=0&showinfo=0'
      title='02 BSHOPLITE'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></YoutubeVideo>
  );
};

export default VideoPlayer;
