import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../../../../../common/components/input/input';
import { Select } from '../../../../../../common/components/select/select';
import { AttForm, FormType } from '../../../../../../redux/FormSlice';
import { useCpfCnpjmask } from '../../../../utils/masks/use-cpf-cnpj-mask';
import { usePhoneMask } from '../../../../utils/masks/use-phone-mask';
import { useStateRegisterMask } from '../../../../utils/masks/use-state-register-mask';
import Session from '../session';
import { SelectOption } from '../../../../../dashboard/view/dashboard-styled-components';

function Company(props: { ErrorMessageInputs: FormType }) {
  const dispatch = useDispatch();
  const form = useSelector((state: { form: { value: FormType } }) => state.form);

  function handlePhone(e: ChangeEvent<HTMLInputElement>) {
    const resp = usePhoneMask(e.target.value);
    dispatch(AttForm({ key: 'phone', value: resp }));
  }

  function handleCNPJCPF(e: ChangeEvent<HTMLInputElement>) {
    const resp = useCpfCnpjmask(e.target.value);
    dispatch(AttForm({ key: 'cpfCnpj', value: resp }));
  }

  return (
    <Session Title='Cadastro da empresa'>
      <Input
        InputSize='medium'
        floatingLabel={true}
        value={form.value.name}
        placeholder='Razão social'
        type='text'
        ErrorMessage={props.ErrorMessageInputs.name}
        onChange={(e) => dispatch(AttForm({ key: 'name', value: e.target.value }))}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='CNPJ/CPF'
        type='text'
        value={form.value.cpfCnpj}
        disabled
        ErrorMessage={props.ErrorMessageInputs.cpfCnpj}
        onChange={(e) => handleCNPJCPF(e)}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        value={form.value.fantasyName}
        placeholder='Nome fantasia'
        type='text'
        ErrorMessage={props.ErrorMessageInputs.fantasyName}
        onChange={(e) => dispatch(AttForm({ key: 'fantasyName', value: e.target.value }))}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        value={form.value.stateRegistration}
        placeholder='Inscrição estadual'
        type='text'
        onChange={(e) => dispatch(AttForm({ key: 'stateRegistration', value: useStateRegisterMask(e.target.value) }))}
        ErrorMessage={props.ErrorMessageInputs.stateRegistration}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Telefone'
        type='text'
        value={form.value.phone}
        onChange={(e) => handlePhone(e)}
        ErrorMessage={props.ErrorMessageInputs.phone}
      ></Input>

      <Input InputSize='medium' floatingLabel={true} placeholder='E-mail' type='text' disabled value={form.value.email}></Input>

      <Select
        value={form.value.regimeTributario}
        label='Regime Tributário'
        onChange={(e) => dispatch(AttForm({ key: 'regimeTributario', value: e.target.value }))}
        ErrorMessage={props.ErrorMessageInputs.regimeTributario}
      >
        <SelectOption value={''}></SelectOption>
        <SelectOption value={'1'}>Simples Nacional</SelectOption>
        <SelectOption value={'2'}>Simples Nacional, excesso sublimite de receita bruta</SelectOption>
        <SelectOption value={'3'}>Regime Normal</SelectOption>
      </Select>
    </Session>
  );
}

export default Company;
