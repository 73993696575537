import { useEffect, useState } from 'react';
import { BannerImagesMobile, BannerImagesWeb } from '../../common/adquirentesTexts';
import { CarouselSlide, ContainerCarousel, Dot, Dots, SlideMobile, SlideWeb } from '../styled-components_adquirentes';

export const CarouselBanners = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = BannerImagesWeb.length;
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    }, 5000); // Change image every 5 seconds
    return () => clearInterval(timer);
  }, [currentSlide]);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ContainerCarousel>
        <div style={{ display: 'flex', width: '100%' }}>
          <CarouselSlide currentSlide={currentSlide}>
            <div style={{ display: 'flex' }}>
              {BannerImagesWeb.map((imageWEb, index) => {
                return (
                  <a href={imageWEb.link} key={index} target='_blank'>
                    <SlideWeb>
                      <img src={imageWEb.img} />
                    </SlideWeb>
                  </a>
                );
              })}
            </div>

            <div style={{ display: 'flex' }}>
              {BannerImagesMobile.map((imageMobile, index) => {
                return (
                  <a href={imageMobile.link} key={index} target='_blank'>
                    <SlideMobile>
                      <img src={imageMobile.img} />
                    </SlideMobile>
                  </a>
                );
              })}
            </div>
          </CarouselSlide>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', zIndex: '100' }}>
          <Dots>
            {Array.from({ length: totalSlides }).map((dot, index) => (
              <Dot key={index + 1} isActive={currentSlide === index} onClick={() => setCurrentSlide(index)} />
            ))}
          </Dots>
        </div>
      </ContainerCarousel>
    </div>
  );
};
