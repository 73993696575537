/* eslint-disable @typescript-eslint/no-explicit-any */
import { aes128decrypt } from '@bshop6/bshop-encrypt';
import { GetCryptoSecret } from '../../../common/utils/get-crypto-secret';
import { FormType } from '../../../redux/FormSlice';
import { useCpfCnpjmask } from '../utils/masks/use-cpf-cnpj-mask';
import { usePhoneMask } from '../utils/masks/use-phone-mask';
import { useCEPmask } from '../utils/masks/use-cep-mask';
import { CompanyResponse } from './company-response';

export const getForm = (data: CompanyResponse): FormType => {
  const Form = {} as FormType;

  const certificatePassword = data.certificatePassword ? aes128decrypt(data.certificatePassword, GetCryptoSecret()) : '';

  Form.city = data.city;
  Form.codIbgeMun = data.codIbgeMun;
  Form.certificate = data.certificate;
  Form.fantasyName = data.fantasyName;
  Form.email = data.email;
  Form.cpfCnpj = useCpfCnpjmask(data.cpfCnpj);
  Form.neighborhood = data.neighborhood;
  Form.logo = data.logo;
  Form.number = data.number;
  Form.street = data.street;
  Form.phone = usePhoneMask(data.phone);
  Form.name = data.name;
  Form.complement = data.complement;
  Form.state = data.state;
  Form.zipcode = useCEPmask(data.zipcode);
  Form.regimeTributario = data.CompanySettings ? data.CompanySettings.regimeTributario ?? '' : '';
  Form.nfeSerie = data.CompanySettings ? data.CompanySettings.nfeSerie ?? '' : '';
  Form.nfeLastNumber = data.CompanySettings
    ? data.CompanySettings.nfeLastNumber
      ? data.CompanySettings.nfeLastNumber.toString()
      : ''
    : '';
  Form.nfceSerie = data.CompanySettings ? data.CompanySettings.nfceSerie ?? '' : '';
  Form.nfceLastNumber = data.CompanySettings
    ? data.CompanySettings.nfceLastNumber
      ? data.CompanySettings.nfceLastNumber.toString()
      : ''
    : '';
  Form.nfceCSC = data.CompanySettings ? data.CompanySettings.nfceCSC ?? '' : '';
  Form.nfceIdCSC = data.CompanySettings ? data.CompanySettings.nfceIdCSC ?? '' : '';
  Form.PasswordConfirm = certificatePassword;
  Form.certificatePassword = certificatePassword;
  Form.certificateName = data.certificateName;
  Form.stateRegistration = data.stateRegistration;

  const updatedForm = Object.entries(Form).reduce((acc, [key, value]) => {
    let newValue;

    if (key == 'logo' || key == 'certificate') {
      newValue = value ? value : null;
    } else {
      newValue = value ? value : '';
    }
    return {
      ...acc,
      [key]: newValue
    };
  }, {} as FormType);

  return updatedForm;
};
