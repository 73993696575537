import React from 'react';
import { ContainerCustomTooltip } from '../dashboard-styled-components';

interface Props {
  x: string | number;
  y: string | number;
}

export const LinheChartCustomTooltip = (props: Props) => {
  return (
    <ContainerCustomTooltip>
      {props.x}, Valor: {props.y}
    </ContainerCustomTooltip>
  );
};
