import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { PropsInput } from './input-interface';
import { Container, ErrorMessageDiv, InputWrapper } from './input-style';
import { Color } from '../../styles/themes';

export const Input: React.FC<PropsInput> = ({
  passwordInputElement,
  InputSize,
  floatingLabel,
  ref,
  marginTop,
  ErrorMessage,
  loading,
  placeholder,
  ...rest
}) => {
  return (
    <div>
      <InputWrapper ErrorMessage={ErrorMessage} floatingLabel={floatingLabel} InputSize={InputSize}>
        {loading ? (
          <div style={{ marginTop: '-3%', marginLeft: '99%', position: 'absolute' }}>
            <ClipLoader color={Color.primaryColorLigth} loading={loading} size={18} />
          </div>
        ) : null}
        <Container
          isloading={loading}
          InputSize={InputSize}
          placeholder={placeholder}
          marginTop={marginTop ?? '0px'}
          ref={ref}
          {...rest}
        ></Container>
        {floatingLabel ? <span>{placeholder}</span> : null}
        {passwordInputElement}
      </InputWrapper>
      {ErrorMessage && <ErrorMessageDiv>{ErrorMessage}</ErrorMessageDiv>}
    </div>
  );
};
