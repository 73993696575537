import { createSlice } from '@reduxjs/toolkit';

export interface AuthType {
  nome: string;
  email: string;
  cpfCnpj: string;
  logo: string | null;
}
const Auth = createSlice({
  name: 'AuthSlice',
  initialState: {
    value: { nome: '', email: '', cpfCnpj: '', logo: null }
  },
  reducers: {
    Login: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { Login } = Auth.actions;
export default Auth.reducer;
