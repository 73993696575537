import styled from 'styled-components';

type ImageCardProps = {
  url: string;
  isDefaultImage: boolean;
  isEditingOrRegistering: boolean;
};

export const ImageCard = styled.div<ImageCardProps>`
  cursor: ${(props) => (props.isDefaultImage || !props.isEditingOrRegistering ? 'default' : 'pointer')};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  border-radius: 0.4rem;
  background: ${(props) => props.theme.border};

  background-image: url(${(props) => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  overflow: hidden;

  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  :hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.05);
  }
`;

export const ImageDeleteButton = styled.button`
  position: absolute;
  top: 13px;
  right: 13px;

  background: none;
  outline: none;
  border: none;

  cursor: pointer;
`;
