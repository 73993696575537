import styled from 'styled-components';

export const VariationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
export const VariationRow = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  & > * {
    width: 100%;
  }
`;
