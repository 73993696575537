import { VariationsGridAge, VariationsGridType, VariationsType } from '../dto';

const ClothesGridAgeKids = ['RN', 'P', 'M', 'G', 'GG', '1', '2', '3', '4', '6', '8', '10', '12', '14', '16'];
const ClothesGridAgeAdult = ['PP', 'P', 'M', 'G', 'GG', 'XG', 'XGG', 'EG', 'EGG'];

const generateClothesVariationGridAge = (gridAge: VariationsGridAge, priceDefault: string): VariationsType[] => {
  const variations: VariationsType[] = [];
  switch (gridAge) {
    case VariationsGridAge.kids: {
      ClothesGridAgeKids.forEach((element) => {
        variations.push({
          id: null,
          description: element,
          gtin: '',
          price: priceDefault,
          auxCode: ''
        });
      });

      break;
    }
    case VariationsGridAge.adult: {
      ClothesGridAgeAdult.forEach((element) => {
        variations.push({
          id: null,
          description: element,
          gtin: '',
          price: priceDefault,
          auxCode: ''
        });
      });
      break;
    }
  }
  return variations;
};

const generateShoesVariationGridAge = (gridAge: VariationsGridAge, priceDefault: string): VariationsType[] => {
  const variations: VariationsType[] = [];
  switch (gridAge) {
    case VariationsGridAge.kids: {
      for (let i = 13; i <= 36; i++) {
        variations.push({
          id: null,
          description: i.toString(),
          gtin: '',
          price: priceDefault,
          auxCode: ''
        });
      }
      break;
    }
    case VariationsGridAge.adult: {
      for (let i = 33; i <= 47; i++) {
        variations.push({
          id: null,
          description: i.toString(),
          gtin: '',
          price: priceDefault,
          auxCode: ''
        });
      }
      break;
    }
  }
  return variations;
};

const generateVariationGrid = (gridType: VariationsGridType, gridAge: VariationsGridAge, gridPrice: string): VariationsType[] => {
  switch (gridType) {
    case VariationsGridType.clothes: {
      return generateClothesVariationGridAge(gridAge, gridPrice);
    }
    case VariationsGridType.shoes: {
      return generateShoesVariationGridAge(gridAge, gridPrice);
    }
  }
};

export default generateVariationGrid;
