import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { BsBoxArrowInRight } from 'react-icons/bs';
import { FiKey, FiUser } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseLogoutExecute } from '../../../logout/use-logout-execute';
import { AuthType } from '../../../redux/AuthSlice';
import { FormType } from '../../../redux/FormSlice';
import { ErrorToast } from '../../alerts/custom-alert-error';
import { FlexCenteredDivFhFwRelative } from '../common-styled-components';
import Logo from './../../../images/logo-bshop-lite-header-branco.svg';
import {
  ContainerBemVindo,
  ContainerHeaderContent,
  ContainerHeaderOptions,
  ContainerOptions,
  HeaderStyle,
  LogoContainer,
  MenuOptions,
  OpenMenuButton,
  SideBar,
  StyledIconMenuOption,
  TextoBemVindo,
  UserPic,
  UserPicContainer
} from './header-styled';
import MenuOption from './menu-option-component';
import Pages from './pages-icons';
import SwitchButton from '../switch-button-theme/switch-theme';
import { useTheme } from '../../theme/theme-context';
import { IoSunny } from 'react-icons/io5';
import { FaMoon } from 'react-icons/fa';

function Header() {
  const { theme, toggleTheme } = useTheme();
  const user = useSelector((state: { form: FormType; auth: { value: AuthType } }) => state.auth);
  const [ShowOptions, setShowOptions] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const OptionsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleMouseDownEvent = (e: MouseEvent) => {
      if (!OptionsRef.current?.contains(e.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDownEvent);

    return () => {
      document.removeEventListener('mousedown', handleMouseDownEvent);
    };
  }, []);

  async function sair() {
    try {
      await UseLogoutExecute('/auth/logout');
      navigate('/');
    } catch (err) {
      ErrorToast.fire({
        text: 'Erro ao deslogar! Por favor, tente novamente.'
      });
    }
  }
  return (
    <HeaderStyle>
      <ContainerHeaderContent>
        <SideBar isSideBarOpen={isSideBarOpen}>
          <div style={{ height: '100px', display: isSideBarOpen ? '' : 'none' }}>
            <AiOutlineClose
              onClick={() => setIsSideBarOpen(false)}
              title='Fechar Menu'
              style={{ marginTop: '15px', position: 'relative', left: '80%', cursor: 'pointer' }}
              color={'#ffffff'}
              size={30}
            ></AiOutlineClose>
          </div>
          <div>
            <Pages checkCurrentPage={false}></Pages>
          </div>
        </SideBar>
        <OpenMenuButton title='Abrir Menu' onClick={() => setIsSideBarOpen(true)}>
          <AiOutlineMenu title='menu button' size={30} color={'#ffffff'}></AiOutlineMenu>
        </OpenMenuButton>

        <LogoContainer onClick={() => navigate('/dashboard')}>
          <img src={Logo} style={{ height: '100%', cursor: 'pointer' }}></img>
        </LogoContainer>
      </ContainerHeaderContent>

      <div style={{ display: 'flex' }}>
        <ContainerHeaderOptions title='show options popup' ref={OptionsRef} onClick={() => setShowOptions(!ShowOptions)}>
          <UserPicContainer HasLogo={user.value.logo != null}>
            <FlexCenteredDivFhFwRelative>
              {user.value.logo ? (
                <UserPic src={'data:image/*;base64,' + user.value.logo}></UserPic>
              ) : (
                <FiUser style={{ color: '#ffffff' }} size={27}></FiUser>
              )}
            </FlexCenteredDivFhFwRelative>
          </UserPicContainer>

          <ContainerBemVindo>
            <TextoBemVindo fontWeight='600'>Bem vindo,</TextoBemVindo>
            <TextoBemVindo>{user.value.nome}</TextoBemVindo>
          </ContainerBemVindo>
          <ContainerOptions>
            {ShowOptions ? (
              <>
                <IoIosArrowUp title='arrow up close menu' style={{ height: '20px', width: '20px', color: '#ffffff' }}></IoIosArrowUp>
                <MenuOptions>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <MenuOption
                      title='logout button'
                      Icon={theme === 'light' ? <IoSunny style={StyledIconMenuOption} /> : <FaMoon style={StyledIconMenuOption} />}
                    >
                      Tema
                    </MenuOption>

                    <SwitchButton isOn={theme === 'dark'} onToggle={toggleTheme} />
                  </div>
                  <MenuOption onClick={() => navigate('/update-password')} Icon={<FiKey style={StyledIconMenuOption}></FiKey>}>
                    Alterar senha
                  </MenuOption>

                  <MenuOption
                    title='logout button'
                    onClick={() => sair()}
                    Icon={<BsBoxArrowInRight style={StyledIconMenuOption}></BsBoxArrowInRight>}
                  >
                    Sair
                  </MenuOption>
                </MenuOptions>
              </>
            ) : (
              <IoIosArrowDown style={{ height: '20px', width: '20px', color: '#ffffff' }}></IoIosArrowDown>
            )}
          </ContainerOptions>
        </ContainerHeaderOptions>
      </div>
    </HeaderStyle>
  );
}

export default Header;
