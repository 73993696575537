import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { ErrorToast } from '../../../../../common/alerts/custom-alert-error';
import Button from '../../../../../common/components/button/button';
import { Input } from '../../../../../common/components/input/input';
import { useCpfCnpjmask } from '../../../../tela_cadastro/utils/masks/use-cpf-cnpj-mask';
import { VerifyCode } from '../../components/verify-code/utils/verify-code';
import VerifyCodeComponent from '../../components/verify-code/verify-code-component';
import { ContainerCpfCnpjUpdatePassword } from '../../components_login';
import { ForgotPasswordSteps } from '../auxiliares/forgot-password-steps';
import { GenerateCodeAndSendEmailForPasswordRecover } from '../utils/generate-code-and-send-email-for-password-recover';
import NewPasswordInputsComponent from './new-password-inputs-component';
interface Props {
  onForgotPasswordOperationFinished: () => void;
}

function ForgotPasswordComponent(props: Props) {
  const [newToken, setNewToken] = useState('');

  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [HiddenEmail, setHiddenEmail] = useState<string>('');
  const [expireTime, setExpireTime] = useState<number>(0);
  const [SendingEmail, setSendingEmail] = useState<boolean>(false);

  const [forgotPasswordStep, setForgotPasswordStep] = useState<ForgotPasswordSteps>(ForgotPasswordSteps.informCpfCnpj);

  async function Send() {
    setSendingEmail(true);

    try {
      const { hiddenEmail, expirationTime } = await GenerateCodeAndSendEmailForPasswordRecover(
        cpfCnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
      );

      setHiddenEmail(hiddenEmail);
      setExpireTime(expirationTime);

      setForgotPasswordStep(ForgotPasswordSteps.verifyEmail);
    } catch (e) {
      ErrorToast.fire({
        text: 'Erro ao enviar e-mail! Por favor, tente novamente.'
      });
    } finally {
      setSendingEmail(false);
    }
  }

  async function verifyCode(code: string) {
    try {
      const resp = await VerifyCode({ code, cpfCnpj: cpfCnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '') });
      const { token: newToken } = resp.data.data;

      setNewToken(newToken);
      setForgotPasswordStep(ForgotPasswordSteps.informNewPassword);
    } catch (e) {
      ErrorToast.fire({
        text: 'Erro ao verificar código. Por favor, tente novamente!'
      });
    }
  }

  function renderInformCpfCnpj(): JSX.Element {
    return (
      <ContainerCpfCnpjUpdatePassword>
        <Input
          InputSize='medium'
          value={cpfCnpj}
          placeholder='CPF/CNPJ'
          type='text'
          name='username'
          onChange={(e) => setCpfCnpj(useCpfCnpjmask(e.target.value))}
        ></Input>
        <div style={{ marginTop: '10%' }}>
          <Button
            title='Voltar'
            width='45%'
            color='white'
            fontSize='16px'
            marginLeft='0px'
            onClick={props.onForgotPasswordOperationFinished}
          >
            Voltar
          </Button>
          <Button
            title='send email button'
            width='45%'
            color='white'
            fontSize='16px'
            marginLeft='10%'
            onClick={() => Send()}
            disabled={SendingEmail}
          >
            {SendingEmail ? <ClipLoader color={'#f1f1f1'} loading={SendingEmail} size={11} /> : 'Enviar e-mail'}
          </Button>
        </div>
      </ContainerCpfCnpjUpdatePassword>
    );
  }

  function renderVerifyCode(): JSX.Element {
    return (
      <VerifyCodeComponent
        codeExpirationTime={expireTime}
        userHiddenEmail={HiddenEmail}
        disableButtonTimer={120}
        generateNewCode={() =>
          GenerateCodeAndSendEmailForPasswordRecover(cpfCnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''))
        }
        verifyToken={verifyCode}
        onGoBack={() => {
          setForgotPasswordStep(ForgotPasswordSteps.informCpfCnpj);
        }}
      ></VerifyCodeComponent>
    );
  }

  function renderInformNewPassword(): JSX.Element {
    return (
      <NewPasswordInputsComponent
        NewToken={newToken}
        cpfCnpj={cpfCnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')}
        onPasswordUpdated={props.onForgotPasswordOperationFinished}
      ></NewPasswordInputsComponent>
    );
  }

  function renderForgotPasswordStep() {
    switch (forgotPasswordStep) {
      case ForgotPasswordSteps.informCpfCnpj:
        return renderInformCpfCnpj();
      case ForgotPasswordSteps.verifyEmail:
        return renderVerifyCode();
      case ForgotPasswordSteps.informNewPassword:
        return renderInformNewPassword();
      default:
        return renderInformCpfCnpj();
    }
  }

  return renderForgotPasswordStep();
}

export default ForgotPasswordComponent;
