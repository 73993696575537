import {
  AdvantageItem,
  AdvantagesCol,
  AdvantagesList,
  ButtonToAdd,
  VideoCol,
  TextCol,
  WrapperSection
} from '../styled-components_adquirentes';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { SvgList } from './svgs/SvgsList';
export const VideoSection = () => {
  return (
    <WrapperSection>
      <TextCol>
        <h1> Bem-vindo ao BshopLite! </h1>
        <p style={{ width: '90%', alignSelf: 'self-start', lineHeight: '2rem' }}>
          Conheça um novo e revolucionário produto, destinado a lojistas de pequeno porte. Chegou a hora de sua loja contar com um dos
          melhores PDVs/sistemas para varejo do mercado!
        </p>
        <AdvantagesList>
          {SvgList.slice(0, 2).map((svg, index) => (
            <AdvantagesCol key={`col_${index}`}>
              {SvgList.slice(index * 3, index * 3 + 3).map((item, innerIndex) => (
                <AdvantageItem key={`item_${index}_${innerIndex}`}>
                  {item.component}
                  {item.name}
                </AdvantageItem>
              ))}
            </AdvantagesCol>
          ))}
        </AdvantagesList>
        <a href={'#adquirentes'} style={{ alignSelf: 'flex-start', cursor: 'pointer' }}>
          <ButtonToAdd type='button' title='Começar agora'>
            Começar agora
          </ButtonToAdd>
        </a>
      </TextCol>
      <VideoCol>
        <VideoPlayer />
      </VideoCol>
    </WrapperSection>
  );
};
