import { md5 } from '@bshop6/bshop-encrypt';
import { browserName, fullBrowserVersion, osName, osVersion } from 'react-device-detect';
import api from '../../../api/api';
import { ILoginExecuteDto } from '../dto/login-execute.dto';

const deviceType = 'browser';

export function useLoginExecute(email: string, Password: string, URL: string) {
  const deviceIdentifier = md5(`${email}-${deviceType}-${osVersion}-${browserName}-${fullBrowserVersion}-${osName}`);

  const obj: ILoginExecuteDto = {
    username: email,
    password: md5(Password),
    device: {
      flavor: deviceType,
      model: `${browserName}_${fullBrowserVersion}`, //Chrome_109
      platform: osName, // Linux
      android: osVersion, // x86
      versionName: '',
      version: '',
      deviceId: '',
      deviceIndentifier: deviceIdentifier,
      logicNumber: '',
      merchantId: '',
      versionCode: ''
    }
  };

  return api.post(URL, obj).then((resp) => {
    return { statusCode: resp.data.statusCode, message: resp.data.message, validCertificate: resp.data.data.validCertificate };
  });
}
