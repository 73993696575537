import styled from 'styled-components';
import { Color } from '../../../../common/styles/themes';

interface TabLabelProps {
  isActive: boolean;
  isError?: boolean;
}

export const TabsLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`;

export const TabLabel = styled.div<TabLabelProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  color: ${(props) => (props.isError ? Color.red : `${props.theme.primaryColorLigth}`)};

  font-size: 1.1em;
  font-weight: 300;
  line-height: 1em;
  padding: 10px 0;

  border-bottom: ${(props) => (props.isActive ? `3px solid ${props.theme.primaryColorLigth}` : `2px solid ${props.theme.border}`)};
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom: ${(props) =>
      props.isActive ? `3px solid ${props.theme.primaryColorLigth}` : `1px solid ${props.theme.primaryColorLigth}`};
  }
`;

export const TabsContentContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  & > * {
    width: 100%;
  }
`;

export const FormInputTwoColumnsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  & > div {
    flex: 1;
  }
`;

export const TreeContainer = styled.div`
  padding: 5px;
`;

export const TreeCard = styled.div`
  border: 1px solid ${(props) => props.theme.treeBackground};
  border-radius: 5px;
  cursor: pointer;
`;

export const TreeIdentifier = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  background: ${(props) => (props.isExpanded ? `${props.theme.treeBackground}` : 'transparent')};

  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.treeHoverBackground};
  }
`;

export const NCMIdentifier = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  padding: 5px;

  cursor: pointer;
`;

export const NCMIcon = styled.div`
  font-size: 12px;
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
  background-color: ${(props) => props.theme.secondaryColorLigth};
  color: #fff;
  margin-left: 5px;
`;

export const NCMName = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${(props) => props.theme.subTitle};
  padding: 5px;
`;

export const ArrowIcon = styled.div`
  margin-right: 5px;
  color: ${(props) => props.theme.subTitle};
  cursor: pointer;
`;

export const WrapperGenerateAIButton = styled.div`
  display: flex;
  justify-content: center;
`;
