import { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Button from '../../../../../common/components/button/button';
import { Input } from '../../../../../common/components/input/input';
import VerifyPasswordInputs from '../../../../../common/components/verify-password/verify-password-inputs';
import { useCpfCnpjmask } from '../../../../tela_cadastro/utils/masks/use-cpf-cnpj-mask';
import { CompanyDataActions, CompanyDataState } from '../auxiliares/company-data';
import {
  ContainerSignUpTitle,
  ContainerSignupRegisterForm,
  ContainerTermOfUse,
  ErrorMessageSpan,
  TermOfUse
} from './signup-form-styled-component';
import { adquirentesImages } from '../../../../adquirentes/common/adquirentesImages';
import { usePhoneMask } from '../../../../tela_cadastro/utils/masks/use-phone-mask';

interface Props {
  flavor: string | undefined;

  onSignUpButtonPressed: (params: { hiddenEmail: string; expirationTime: number }) => void;
  onGoBack: () => void;

  handleSignup: (termsOfUseAccepted: boolean) => Promise<void>;
  handleSignupLoading: boolean;

  companyData: CompanyDataState;
  setCompanyData: Dispatch<CompanyDataActions>;

  companyDataErrors: CompanyDataState;
  setCompanyDataErrors: Dispatch<SetStateAction<CompanyDataState>>;
}

function SignUpFormComponent(props: Props) {
  const [termOfUseAccepted, setTermOfUseAccepted] = useState<boolean>(false);
  const [showTermOfUseErrorMessage, setShowTermOfUseErrorMessage] = useState<boolean>(false);

  async function handleSignupButtonPressed() {
    await props.handleSignup(termOfUseAccepted);

    setShowTermOfUseErrorMessage(!termOfUseAccepted);
  }
  const adquirente = adquirentesImages.find((item) => item.nome === props.flavor);
  const imagem = adquirente?.imagem;

  return (
    <ContainerSignupRegisterForm>
      <ContainerSignUpTitle>
        <h2>Cadastro</h2>
        <img src={imagem} style={{ height: '25px', margin: '0px 0px 3px 0px ' }}></img>
      </ContainerSignUpTitle>
      <div style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
        <Input
          InputSize='medium'
          value={props.companyData.cpfCnpj}
          placeholder='CPF/CNPJ'
          type='text'
          name='username'
          onChange={(e) => props.setCompanyData({ type: 'cpfCnpj', payload: useCpfCnpjmask(e.target.value) })}
          floatingLabel={true}
          ErrorMessage={props.companyDataErrors.cpfCnpj}
          labelBackgroundColor={'white'}
        ></Input>
        <Input
          InputSize='medium'
          value={props.companyData.email}
          placeholder='E-mail'
          type='text'
          name='email'
          onChange={(e) => props.setCompanyData({ type: 'email', payload: e.target.value.toLowerCase() })}
          floatingLabel={true}
          ErrorMessage={props.companyDataErrors.email}
          labelBackgroundColor={'white'}
        ></Input>
        <Input
          InputSize='medium'
          value={props.companyData.phoneNumber}
          placeholder='Telefone'
          type='text'
          name='phone'
          onChange={(e) => props.setCompanyData({ type: 'phoneNumber', payload: usePhoneMask(e.target.value) })}
          floatingLabel={true}
          ErrorMessage={props.companyDataErrors.phoneNumber}
          labelBackgroundColor={'white'}
        ></Input>
        <Input
          InputSize='medium'
          value={props.companyData.name}
          placeholder='Nome/Razão Social'
          type='text'
          name='companyName'
          onChange={(e) => props.setCompanyData({ type: 'name', payload: e.target.value })}
          floatingLabel={true}
          ErrorMessage={props.companyDataErrors.name}
          labelBackgroundColor={'white'}
        ></Input>
        <VerifyPasswordInputs
          InputSize='medium'
          Password1={props.companyData.password1}
          Password2={props.companyData.password2}
          setPassword1={(value) => props.setCompanyData({ type: 'password1', payload: value })}
          setPassword2={(value) => props.setCompanyData({ type: 'password2', payload: value })}
          errorPassword1={props.companyDataErrors.password1}
          errorPassword2={props.companyDataErrors.password2}
          setErrorPassword1={(value) => props.setCompanyDataErrors((prev) => ({ ...prev, password1: value }))}
        />
      </div>

      <ContainerTermOfUse>
        <TermOfUse>
          <input
            checked={termOfUseAccepted}
            onChange={() => setTermOfUseAccepted((prev) => !prev)}
            type='checkbox'
            name='term-of-use'
            id='term-of-use'
          />
          <label htmlFor='term-of-use'>
            Li e estou de acordo com os{' '}
            <Link target='_blank' rel='noopener noreferrer' to={'/termos-de-uso?identifier=BSHOPLITE'}>
              Termos e Condições de Uso
            </Link>
          </label>
        </TermOfUse>
        {showTermOfUseErrorMessage && <ErrorMessageSpan>Você precisa aceitar os termos de uso para cadastrar uma conta.</ErrorMessageSpan>}
      </ContainerTermOfUse>

      <div style={{ marginTop: '1.5rem' }}>
        <Button
          title='send email button'
          width='100%'
          color='white'
          fontSize='16px'
          marginLeft='0'
          onClick={handleSignupButtonPressed}
          disabled={props.handleSignupLoading}
        >
          {props.handleSignupLoading ? <ClipLoader color={'#f1f1f1'} loading={props.handleSignupLoading} size={11} /> : 'Cadastrar'}
        </Button>
      </div>
    </ContainerSignupRegisterForm>
  );
}

export default SignUpFormComponent;
