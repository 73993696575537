import React, { ButtonHTMLAttributes } from 'react';
import { ButtonComponent } from './button-outline-style';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  width: string;
  fontSize: string;
  marginLeft: string;
}

const ButtonOutline: React.FC<Props> = ({ width, fontSize, marginLeft, children, ...rest }) => (
  <ButtonComponent width={width} fontSize={fontSize} marginLeft={marginLeft} {...rest}>
    {children}
  </ButtonComponent>
);

export default ButtonOutline;
