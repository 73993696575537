import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
  }
`;

export default GlobalStyle;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  color: ${(props) => props.theme.title};
  font-weight: 600;

  & > svg {
    cursor: pointer;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  color: ${(props) => props.theme.subTitle};

  & > svg {
    cursor: pointer;
  }
`;
