import { IsBase64, IsEmail, IsNotEmpty, IsOptional, Length } from 'class-validator';
import { IsCNPJ } from './is-cnpj';
export class ValidatorCadastro {
  @IsEmail({
    message: 'Este campo aceita apenas emails válidos!'
  })
  @IsNotEmpty()
  email!: string;

  @IsBase64({ message: 'Ocorreu um erro durante a leitura do logo da empresa!' })
  @IsOptional()
  logo!: string | null;

  @IsBase64({ message: 'Ocorreu um erro durante a leitura do arquivo do certificado!' })
  @IsOptional()
  certificate!: string | null;

  @IsNotEmpty({ message: 'O campo "razão social" precisa ser preenchido!' })
  @Length(4, undefined, { message: 'Escreva mais de 3 caracteres!' })
  name!: string;

  @Length(4, undefined, { message: 'Escreva mais de 3 caracteres!' })
  @IsOptional()
  fantasyName!: string | null;

  @Length(10, 12, { message: 'O campo "Telefone" não foi totalmente digitado!' })
  @IsNotEmpty({ message: 'O campo "Telefone" precisa ser preenchido!' })
  phone!: string;

  @Length(2, 14, { message: 'O campo "Inscrição estadual" deve possuir entre 2 e 14 dígitos!' })
  @IsOptional()
  stateRegistration?: string | null;

  @IsNotEmpty({ message: 'O campo "Rua" precisa ser preenchido!' })
  street!: string;

  @IsNotEmpty({ message: 'O campo "Número" precisa ser preenchido!' })
  @IsOptional()
  number!: string;

  @IsNotEmpty({ message: 'O campo "Estado" precisa ser preenchido!' })
  state!: string;

  @IsNotEmpty({ message: 'O campo "Cidade" precisa ser preenchido!' })
  city!: string;

  @Length(7, 7, { message: 'O campo "Código IBGE" deve possuir 7 dígitos!' })
  @IsNotEmpty({ message: 'O campo "Código IBGE" precisa ser preenchido!' })
  codIbgeMun!: string;

  @IsNotEmpty({ message: 'O campo "Bairro" precisa ser preenchido!' })
  neighborhood!: string;

  @Length(8, 8, { message: 'CEP não informado corretamente!' })
  zipcode!: string;

  @IsCNPJ({ message: 'CNPJ inválido' })
  @Length(11, 14, { message: 'O CNPJ não foi totalmente digitado!' })
  @IsNotEmpty({ message: 'O campo "CNPJ" precisa ser preenchido!' })
  @IsOptional()
  cpfCnpj!: string;
}
