import styled from 'styled-components';

export const TerminalInfosContainer = styled.form`
  width: 100%;
  height: 100%;

  padding: 1.4rem 0.4rem;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  color: ${(props) => props.theme.title};

  & p,
  h2,
  h3,
  li {
    text-align: justify;
  }

  & > ol {
    margin-left: 32px;

    & > li {
      font-weight: 600;
    }
  }
`;
