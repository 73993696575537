export const NumberStringToDecimalString = (value: string): string => {
  if (!value.includes('.')) {
    value = `${value}.00`;
  } else {
    const decimalLayer = value.split('.').at(-1);

    if (decimalLayer?.length === 1) {
      value = `${value}0`;
    }
  }
  return value;
};
