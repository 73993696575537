import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { InputToken } from './../../forgot-password/components/forgot-password-styled-components';
import { Color } from '../../../../../common/styles/themes';

interface Props {
  verifyCode: (code: string) => Promise<void>;
  InputsQuantity: number;
}
export const CodeInputFields = (props: Props) => {
  const [InputsValues, setInputsValues] = useState([...new Array(props.InputsQuantity)].map(() => ''));
  const [InputCodeError, setInputCodeError] = useState(false);
  const vet = [...new Array(props.InputsQuantity)].map(() => useRef<HTMLInputElement>(null));

  function handleInputsStates(value: string, pos: number) {
    InputsValues[pos] = value;
    setInputsValues([...InputsValues]);
  }

  async function verifyToken() {
    let code = '';
    InputsValues.map((value) => {
      code += value;
    });
    try {
      await props.verifyCode(code);
      setInputCodeError(false);
    } catch (e) {
      setInputCodeError(true);
    }
  }

  function handleChanges(pos: number, e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value === '') {
      vet[pos].current?.focus();
    } else {
      if (pos < vet.length - 1) {
        vet[pos + 1].current?.focus();
      } else {
        verifyToken();
      }
    }
  }

  function handlekeydown(e: KeyboardEvent, pos: number) {
    if (e.key === 'Backspace') {
      if (pos > 0) {
        if (vet[pos].current?.value === '') {
          vet[pos - 1].current?.focus();
        }
      }
    }
  }

  function handleOnPaste(value: string, currPos: number) {
    let valuePos = 0;
    for (let x = currPos; valuePos < value.length && x < InputsValues.length; x++) {
      InputsValues[x] = value[valuePos];
      valuePos++;
      if (x === InputsValues.length - 1) {
        setInputsValues([...InputsValues]);
        verifyToken();
        break;
      }
      vet[x + 1].current?.focus();
    }
    setInputsValues([...InputsValues]);
  }

  useEffect(() => {
    vet[0].current?.focus();
  }, []);

  return (
    <React.Fragment>
      <div style={{ height: '20%', width: '100%', display: 'flex', columnGap: '10px' }}>
        {[...new Array(props.InputsQuantity)].map((_, index) => {
          return (
            <InputToken
              title='token field'
              type='text'
              key={index}
              ref={vet[index]}
              onPaste={(e) => {
                handleOnPaste(e.clipboardData.getData('Text'), index);
                e?.preventDefault();
              }}
              onKeyDown={(e) => handlekeydown(e, index)}
              value={InputsValues[index]}
              onChange={(e) => {
                handleInputsStates(e.target.value, index);
                handleChanges(index, e);
              }}
              InputError={InputCodeError}
              maxLength={1}
              onBlur={() => setInputCodeError(false)}
            ></InputToken>
          );
        })}
      </div>
      {InputCodeError ? <p style={{ color: Color.red }}>Código inválido!</p> : null}
    </React.Fragment>
  );
};
