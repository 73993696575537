import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { ErrorToast } from '../../../common/alerts/custom-alert-error';
import Button from '../../../common/components/button/button';
import { Input } from '../../../common/components/input/input';
import { Select } from '../../../common/components/select/select';
import { isYouTubeURL } from '../../../common/utils/youtube-url-utils';
import { TerminalDto } from '../dto/terminal.dto';
import { TerminalsIdentifiersDto } from '../dto/terminals-identifiers.dto';
import { CreateTerminal } from '../utils/create-terminal';
import { GetTerminalsIdentifiers } from '../utils/get-terminals-identifiers';
import {
  RegisterTerminalForm,
  RegisterTerminalFormButtonsContainer,
  RegisterTerminalFormInputsContainer
} from './register-terminal-styled';
import { SelectOption } from '../../dashboard/view/dashboard-styled-components';
import { Color } from '../../../common/styles/themes';

type RegisterTerminalProps = {
  terminalList: TerminalDto[];
  onTerminalRegister: () => void;

  showYoutubeEmbedPopup: Dispatch<SetStateAction<boolean>>;
  setYoutubeEmbedUrl: Dispatch<SetStateAction<string>>;
};

function RegisterTerminal(props: RegisterTerminalProps) {
  const [terminalIdentifiers, setTerminalIdentifiers] = useState<TerminalsIdentifiersDto[]>([]);

  const [terminalNumber, setTerminalNumber] = useState('');
  const [terminalId, setTerminalId] = useState('');
  const [terminalNumberError, setTerminalNumberError] = useState('');

  const [registerTerminalLoading, setRegisterTerminalLoading] = useState(false);
  const [searchTerminalsIdentifiersLoading, setSearchTerminalsIdentifiersLoading] = useState(false);

  useEffect(() => {
    getTerminalsIdentifiers();
  }, []);

  async function getTerminalsIdentifiers() {
    try {
      setSearchTerminalsIdentifiersLoading(true);

      const terminalsIdentifiers = await GetTerminalsIdentifiers();
      setTerminalIdentifiers(terminalsIdentifiers);
      setTerminalId(terminalsIdentifiers[0].id.toString());
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao buscar terminais. Por favor, tente novamente!'
      });
    } finally {
      setSearchTerminalsIdentifiersLoading(false);
    }
  }

  async function registerTerminal(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const isDataValid = validateRegisterTerminalData();

    if (!isDataValid) {
      return;
    }

    try {
      setRegisterTerminalLoading(true);

      await CreateTerminal({
        terminalId: Number(terminalId),
        terminalNumber: terminalNumber
      });

      setRegisterTerminalLoading(false);
      props.onTerminalRegister();
    } catch (error) {
      ErrorToast.fire({
        text: 'Erro ao cadastrar terminal. Por favor, tente novamente!'
      });
      setRegisterTerminalLoading(false);
    }
  }

  function validateRegisterTerminalData(): boolean {
    if (!terminalNumber) {
      setTerminalNumberError('Informe o Número do Terminal');
    } else {
      setTerminalNumberError('');
    }

    const alreadyExistsTerminal = props.terminalList.find(
      (terminal) => terminal.terminalNumber === terminalNumber && terminal.terminalId === Number(terminalId)
    );

    if (alreadyExistsTerminal) {
      ErrorToast.fire({
        text: 'Este terminal já está cadastrado!'
      });
      return false;
    }

    return Boolean(terminalNumber);
  }

  function openInstructions() {
    const currentTerminal = terminalIdentifiers.find((terminal) => terminal.id === Number(terminalId));
    if (currentTerminal && currentTerminal.instructionsUrl) {
      if (isYouTubeURL(currentTerminal.instructionsUrl)) {
        props.showYoutubeEmbedPopup(true);
        props.setYoutubeEmbedUrl(currentTerminal.instructionsUrl);
        return;
      }

      window.open(currentTerminal.instructionsUrl, '_blank');
    }
  }

  if (searchTerminalsIdentifiersLoading) {
    return <ClipLoader color={Color.primaryColorLigth} loading={searchTerminalsIdentifiersLoading} size={40} />;
  }

  return (
    <RegisterTerminalForm action='' onSubmit={registerTerminal}>
      <RegisterTerminalFormInputsContainer>
        <Select
          value={terminalId}
          label='Identificador do Terminal'
          labelBackgroundColor={'#f5f5f5'}
          onChange={(e) => setTerminalId(e.target.value)}
        >
          {terminalIdentifiers.map((terminalIdentifier) => (
            <SelectOption key={terminalIdentifier.id} value={terminalIdentifier.id}>
              {terminalIdentifier.identifier}
            </SelectOption>
          ))}
        </Select>

        <Input
          InputSize='medium'
          value={terminalNumber}
          placeholder='Número do Terminal'
          type='text'
          name='companyName'
          onChange={(e) => setTerminalNumber(e.target.value)}
          floatingLabel={true}
          ErrorMessage={terminalNumberError}
        ></Input>
      </RegisterTerminalFormInputsContainer>

      <RegisterTerminalFormButtonsContainer>
        <Button
          style={{ marginBottom: '0' }}
          onClick={openInstructions}
          title='Ver instruções'
          width='100%'
          color='white'
          fontSize='16px'
          marginLeft='0'
          type='button'
          backgroundColor='#eecd57'
          backgroundHoverColor='#d9b53d'
        >
          Ver Instruções
        </Button>
        <Button
          style={{ marginBottom: '0' }}
          title='Registrar Terminal'
          width='100%'
          color='white'
          fontSize='16px'
          marginLeft='0'
          type='submit'
        >
          {registerTerminalLoading ? <ClipLoader color={'#f1f1f1'} loading={registerTerminalLoading} size={11} /> : 'Cadastrar'}
        </Button>
      </RegisterTerminalFormButtonsContainer>
    </RegisterTerminalForm>
  );
}

export default RegisterTerminal;
