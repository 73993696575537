import { TerminalInfosContainer } from './terminal-infos-styled';

function TerminalInfosSteps() {
  return (
    <TerminalInfosContainer>
      <p>Para ativar um novo terminal, siga o passo a passo abaixo:</p>
      <br />
      <ol>
        <li>Clique em 'Cadastrar Terminal' no canto direito superior da tela;</li>
        <li>Informe o identificador do terminal nas opções;</li>
        <li>
          Informe o número do seu terminal. Haverá uma opção 'Ver Instruções' com um link para um PDF preparado pela nossa equipe para
          facilitar o processo de ativação e busca pelo respectivo número;
        </li>
        <li>Salve as informações.</li>
      </ol>
      <br />
      <p>Você poderá acompanhar a situação/status de ativação na listagem de terminais</p>
    </TerminalInfosContainer>
  );
}

export default TerminalInfosSteps;
