import styled from 'styled-components';

export const PopUpContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 18vh;
  & > p {
    text-align: center;
    font-size: large;
  }
  @media (max-width: 600px) {
    height: 28vh;
  }
`;
export const Link = styled.a`
  text-decoration: none;
  min-width: 115px;
  padding: 15px 25px;
  border-radius: 30px;
  text-align: center;
  align-self: center;
  color: white;
  background-color: #004b90;
  font-weight: 600;
  &:hover {
    background-color: #012b5e;
  }
`;
