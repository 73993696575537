/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
var userUnauthorized = false;
export const setUserUnauthorized = (value: boolean) => {
  userUnauthorized = value;
};

export const getUserUnauthorized = (): boolean => {
  return userUnauthorized;
};
