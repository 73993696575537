import { ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast } from '../../../../../common/alerts/custom-alert-error';
import { AttForm, FormType } from '../../../../../redux/FormSlice';
import { FileToBase64 } from '../../../utils/file-to-base64';
import { useTheme } from '../../../../../common/theme/theme-context';
import LogoIconInput from './logo-icon-input';
import CertificateInput from './certificate-input';

interface Props {
  ErrorMessageInputs: FormType;
  handlePFXValidation: (e: ChangeEvent<HTMLInputElement>, cert?: string | unknown) => void;
  setCheckingPFX: (value: boolean) => void;
  CheckingPFX: boolean;
  setIsCertExpired: (value: boolean) => void;
  setIsCertPasswordValid: (value: boolean) => void;
  name: string;
}

function FileIconsInputs(props: Props) {
  const dispatch = useDispatch();
  const form = useSelector((state: { form: { value: FormType } }) => state.form);
  const [subtituloIconLogo, setSubtituloIconLogo] = useState<string>('Selecionar Logo...');
  const [TrySVG, setTrySVG] = useState<boolean>(false);
  const [Attempts, setAttempts] = useState(0);
  const pfxRef = useRef<HTMLInputElement>(null);
  const { theme } = useTheme();

  function setSubtituloIconPFX(value: string) {
    dispatch(AttForm({ key: 'certificateName', value }));
  }

  function handleFiles(e: ChangeEvent<HTMLInputElement>, key: string, setSubtitulo: (value: string) => void) {
    if (!e.target.files) return;
    if (e.target.files[0].size > 5242880) {
      ErrorToast.fire({
        text: 'O arquivo escolhido é muito grande, o tamanho máximo suportado é de 5MB.'
      });
      return;
    }
    FileToBase64(e.target.files[0])
      .then((data) => {
        if (!e.target.files || typeof data != 'string') return;
        data = data.replace('data:', '').replace(/^.+,/, '');
        dispatch(AttForm({ key, value: data as string }));
        setSubtitulo(e.target.files[0].name);
        e.target.id === 'inputPFX' ? props.handlePFXValidation(e, data) : null;
        return;
      })
      .catch(() => {
        ErrorToast.fire({
          text: 'Erro ao realizar upload deste arquivo. Por favor, tente novamente!'
        });
        return;
      });
  }

  return (
    <>
      {props.name === 'cert input' ? (
        <CertificateInput
          CheckingPFX={props.CheckingPFX}
          setIsCertPasswordValid={props.setIsCertPasswordValid}
          handleFiles={handleFiles}
          setIsCertExpired={props.setIsCertExpired}
          setSubtituloIconPFX={setSubtituloIconPFX}
          certificateName={form.value.certificateName}
          pfxRef={pfxRef}
          ErrorMessageInputs={props.ErrorMessageInputs}
          theme={theme}
        />
      ) : (
        <LogoIconInput
          Attempts={Attempts}
          setAttempts={setAttempts}
          TrySVG={TrySVG}
          setTrySVG={setTrySVG}
          handleFiles={handleFiles}
          theme={theme}
          setSubtituloIconLogo={setSubtituloIconLogo}
          subtituloIconLogo={subtituloIconLogo}
          logo={form.value.logo}
          ErrorMessageInputs={props.ErrorMessageInputs}
        />
      )}
    </>
  );
}

export default FileIconsInputs;
